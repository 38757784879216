<div *ngIf="isLoading" class="full-screen center-and-middle">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!isLoading"  style="overflow: auto;">
  <form [formGroup]="recommendations" (ngSubmit)="onSubmit()">
    <div class="container">
      <nb-card>
        <nb-card-header>
          Død
        </nb-card-header>
        <nb-card-body>
          <table>
            <tr>
              <td>Min. </td>
              <input
                nbTooltip={{percentageTooltip}} nbTooltipPlacement="top" nbTooltipStatus="info"
                [status]="setStatusRecommendationsGroup('deathPercentMin')"
                class="input-length" type="number" shape="semi-round"
                nbInput formControlName="deathPercentMin">
              <td>% af årslønnen</td>
            </tr>
          </table>
        </nb-card-body>
      </nb-card>

      <nb-card>
        <nb-card-header>
          Kritisk sygdom
        </nb-card-header>
        <nb-card-body>
          <table>
            <tr>
              <td>Min. </td>
              <input
                nbTooltip={{minimumTooltip}} nbTooltipPlacement="top" nbTooltipStatus="info"
                [status]="setStatusRecommendationsGroup('criticalIllnessMin')"
                style="max-width: 100px;" type="number" shape="semi-round"
                nbInput formControlName="criticalIllnessMin">
              <td>kr</td>
            </tr>
          </table>
        </nb-card-body>
      </nb-card>

      <nb-card formGroupName="lossOfEarningCapacity">
        <nb-card-header>
          Tab af ervhervsevne
        </nb-card-header>
        <nb-card-body>
          <table formArrayName="intervals">
            <tr>
              <th>Årlig indkomst</th>
              <th>% af Løn</th>
            </tr>
            <tr *ngFor="let interval of intervals.controls; let i = index" [formArrayName]="i">
              <td>{{getIntervalValue(interval)}}</td>
              <input
                nbTooltip={{percentageTooltip}} nbTooltipPlacement="right" nbTooltipStatus="info"
                [status]="setStatusIntervals(i)" class="input-length" type="number"
                shape="semi-round" nbInput formControlName="valueMin">
            </tr>
          </table>

        </nb-card-body>
      </nb-card>

    <nb-card formGroupName="aumAtRetirementAge">
      <nb-card-header>
        Opsparing ved pensionsalder
      </nb-card-header>
      <nb-card-body>
        <table>
          <tr>
            <td>Min. </td>
            <input
              nbTooltip={{minimumTooltip}} nbTooltipPlacement="top" nbTooltipStatus="info"
              [status]="setStatusAumAtRetirementAge('retirementAumYearlySalaryMinFactor')"
              class="input-length" type="number" shape="semi-round" nbInput
              formControlName="retirementAumYearlySalaryMinFactor">
            <td>gange årslønnen,</td>
          </tr>
          <tr>
            <td colspan="3">under følgende forudsætninger:</td>
          </tr>
          <tr>
            <td>Pensionsalder</td>
            <input
              nbTooltip={{percentageTooltip}} nbTooltipPlacement="top" nbTooltipStatus="info"
              [status]="setStatusAumAtRetirementAge('typicalRetirementAge')"
              class="input-length" type="number" shape="semi-round"
              nbInput formControlName="typicalRetirementAge">
            <td>år</td>
          </tr>
          <tr>
            <td>Rente</td>
            <input
              nbTooltip={{interestTooltip}} nbTooltipPlacement="top" nbTooltipStatus="info"
              [status]="setStatusAumAtRetirementAge('averageInterest')"
              class="input-length" type="number" shape="semi-round"
              nbInput formControlName="averageInterest">
            <td>%</td>
          </tr>
          <tr>
            <td>Afkastskat</td>
            <input
              nbTooltip={{percentageTooltip}} nbTooltipPlacement="top" nbTooltipStatus="info"
              [status]="setStatusAumAtRetirementAge('averageYieldTax')"
              class="input-length" type="number" shape="semi-round" nbInput formControlName="averageYieldTax">
            <td>%</td>
          </tr>
        </table>
      </nb-card-body>
    </nb-card>
  </div>
  <button
    type="submit" [disabled]="!recommendations.valid" nbButton
    status="primary" size="giant"
    [nbSpinner]="saving" nbSpinnerSize="giant" nbSpinnerStatus="control">
    Gem
  </button>
</form>
