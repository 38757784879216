<div *ngIf="isLoading" class="full-screen center-and-middle">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!displayErrorMessage && !isLoading" class="grid-wrapper">
  <button [disabled]="headersForms.length < 2" nbButton size="medium" shape="round" status="info" (click)="deleteLastColumn()" style="margin-right: 20px; margin-bottom: 20px;">
    <nb-icon icon="minus-outline"></nb-icon>
  </button>
  <button nbButton size="medium" shape="round" status="info" (click)="addColumn()" style="margin-bottom: 20px;">
    <nb-icon icon="plus-outline"></nb-icon>
  </button>

  <nb-card class="fit-content">
    <div class="flex-row">
      <nb-card-body style="margin-top: 45px; min-width: 300px; max-width: 300px;">
        <app-investment-expenses-labels></app-investment-expenses-labels>
      </nb-card-body>
      <nb-card-body class="flex-row">
        <div [formGroup]="group" class="flex-row">
          <div formArrayName="headers" *ngFor="let header of headersForms.controls; let i = index" style="margin-right: 20px;">
            <input
              [status]="setStatusHeader(i)"
              type="text" nbInput shape="semi-round"
              placeholder="Profilnavn" style="margin-bottom: 10px;"
              [formControlName]="i">
            <app-investment-expenses-fields (yieldYearsFetched)="setUpData()" ></app-investment-expenses-fields>
          </div>
        </div>
      </nb-card-body>
    </div>
  </nb-card>

  <button
    [disabled]="!isColumnsValid || isSaving" nbButton (click)="saveData()"
    status="primary" type="submit" size="giant"
    [nbSpinner]="isSaving" nbSpinnerSize="giant" nbSpinnerStatus="control">
    Gem
  </button>
</div>

<p class="error-message" *ngIf="displayErrorMessage">{{ errorMessage }}</p>

