import { Component } from "@angular/core";
import { MsalService } from "@azure/msal-angular";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
})
export class LogoutComponent {
  constructor(msal: MsalService) {
    msal.logout();
  }
}
