import { RecommendationsStepData } from "./recommendationsStepData";

export class RecommendationsData {
  deathPercentMin: number | null;
  criticalIllnessMin: number | null;
  retirementAumYearlySalaryMinFactor: number | null;
  typicalRetirementAge: number | null;
  averageInterest: number | null;
  averageYieldTax: number | null;
  lossOfEarningCapacity: RecommendationsStepData[] | null;

  constructor(
    deathPercentMin: number | null,
    criticalIllnessMin: number | null,
    retirementAumYearlySalaryMinFactor: number | null,
    typicalRetirementAge: number | null,
    averageInterest: number | null,
    averageYieldTax: number | null,
    lossOfEarningCapacity: RecommendationsStepData[] | null
  ) {
    this.deathPercentMin = deathPercentMin;
    this.criticalIllnessMin = criticalIllnessMin;
    this.retirementAumYearlySalaryMinFactor = retirementAumYearlySalaryMinFactor;
    this.typicalRetirementAge = typicalRetirementAge;
    this.averageInterest = averageInterest;
    this.averageYieldTax = averageYieldTax;
    this.lossOfEarningCapacity = lossOfEarningCapacity;
  }
}
