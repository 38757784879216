import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";

import { AppConfigService } from "../../app-config.service";
import { IdObject } from "../../interfaces/id-object";
import { Step2Column } from "../../interfaces/step-2-column";

export interface Step3Value {
  yearsInAnalysis: number;
  coverages: {
    lossOfEarningCapacity: boolean;
    disabilityLumpSum: boolean;
    criticalIllness: boolean;
    death: boolean;
    orphanPension: boolean;
    healthInsurance: boolean;
  };
  companiesInAnalysis: { [companyId: string]: boolean };
  compareConditions: boolean;
  showCompanies: boolean;
  fileFormat: {
    word: boolean;
    pdf: boolean;
  };
  saveAnalysis: boolean;
  analysisSaveName: string;
}

@Component({
  selector: "app-step-3",
  template: "Not implemented",
})
export abstract class Step3Component implements OnInit, OnChanges {
  reportTypes: IdObject[] = [
    { label: "PDF-fil", id: "pdf" },
    { label: "Word-fil", id: "word" },
  ];

  checked = false;
  companies: IdObject[] = [];

  companiesGroup = new UntypedFormGroup({});
  coveragesGroup = new UntypedFormGroup({});
  fileFormatGroup = new UntypedFormGroup({});
  step3Form: UntypedFormGroup;
  get step3Value(): Step3Value {
    return this.step3Form.value as Step3Value;
  }

  @Input() pensionCompanies!: UntypedFormArray;
  @Input() coverages!: CoverageInAnalysis;
  @Input() yearCount!: number;
  @Input() saveAnalysisName: string | undefined;

  constructor(private appConfigService: AppConfigService, private formBuilder: UntypedFormBuilder) {
    this.setupFormControls(this.coveragesGroup, this.coverTypes);
    this.setupFormControls(this.fileFormatGroup, this.reportTypes);
    this.fileFormatGroup.patchValue({ word: false });

    this.step3Form = this.formBuilder.group({
      yearsInAnalysis: [null, Validators.required],
      coverages: this.coveragesGroup,
      companiesInAnalysis: this.companiesGroup,
      compareConditions: [false, Validators.required],
      showCompanies: [true, Validators.required],
      fileFormat: this.fileFormatGroup,
      saveAnalysis: [true, Validators.required],
      analysisSaveName: [null, Validators.required],
    });

    this.saveAnalysisControl.valueChanges.subscribe((doSaveAnalysis) =>
      doSaveAnalysis
        ? this.analysisSaveNameControl.enable()
        : this.analysisSaveNameControl.disable()
    );
  }

  coverTypes: IdObject[] = [
    { label: "Tab af erhvervsevne", id: "lossOfEarningCapacity" },
    { label: "Invalidesum", id: "disabilityLumpSum" },
    { label: "Kritisk sygdom", id: "criticalIllness" },
    { label: "Død", id: "death" },
    { label: "Børnepension", id: "orphanPension" },
    { label: "Sundhedsforsikring", id: "healthInsurance" },
  ];

  coveragesInAnalysis: IdObject[] = [];

  checkCoverages(): void {
    if (this.coverages) {
      this.coveragesInAnalysis = [];
      this.coveragesGroup.reset();

      const coveragesInAnalysis = this.coveragesInAnalysis;
      const coverages = this.coverages.employerData;
      if (
        coverages.lossOfEarningCapacityTotal !== null &&
        coverages.lossOfEarningCapacityTotal !== 0
      ) {
        coveragesInAnalysis.push({ label: "Tab af erhvervsevne", id: "lossOfEarningCapacity" });
      }

      if (coverages.disabilityLumpSumTotal !== null && coverages.disabilityLumpSumTotal !== 0) {
        coveragesInAnalysis.push({ label: "Invalidesum", id: "disabilityLumpSum" });
      }

      if (coverages.criticalIllnessTotal !== null && coverages.criticalIllnessTotal !== 0) {
        coveragesInAnalysis.push({ label: "Kritisk sygdom", id: "criticalIllness" });
      }

      if (coverages.deathTotal !== null && coverages.deathTotal !== 0) {
        coveragesInAnalysis.push({ label: "Død", id: "death" });
      }

      if (coverages.orphansPensionTotal !== null && coverages.orphansPensionTotal !== 0) {
        coveragesInAnalysis.push({ label: "Børnepension", id: "orphanPension" });
      }

      if (coverages.isHealthInsurancePresent) {
        coveragesInAnalysis.push({ label: "Sundhedsforsikring", id: "healthInsurance" });
      }

      this.setupFormControls(this.coveragesGroup, coveragesInAnalysis);
    }
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  ngOnChanges(changes: SimpleChanges): void {
    const pensionCompanies = changes.pensionCompanies;
    if (pensionCompanies) {
      const currentValue = pensionCompanies.currentValue as Step2Column[];
      const previousValue = pensionCompanies.previousValue as Step2Column[];

      if (currentValue && previousValue) {
        const currentPensionCompanyId = currentValue[currentValue.length - 1].pensionCompanyId;
        const prePensionCompanyId = previousValue[previousValue.length - 1].pensionCompanyId;

        if (currentPensionCompanyId !== null && currentPensionCompanyId !== prePensionCompanyId) {
          this.setCompanyOptions(currentValue.map((c) => c.pensionCompanyId));
        }
      }
    }

    const coverages = changes.coverages;
    if (coverages) {
      this.checkCoverages();
    }

    const yearCountChanges = changes.yearCount;
    if (yearCountChanges) {
      const newYearCount = yearCountChanges.currentValue as number;
      if (0 < newYearCount) {
        const selectedYears = this.step3Form.get("yearsInAnalysis")?.value as number | null;
        if (selectedYears === null || selectedYears > newYearCount) {
          this.step3Form.patchValue({ yearsInAnalysis: newYearCount });
        }
      } else {
        this.step3Form.patchValue({ yearsInAnalysis: null });
      }
    }

    const saveAnalysisName = changes.saveAnalysisName?.currentValue;
    if (saveAnalysisName) {
      this.analysisSaveNameControl.setValue(saveAnalysisName);
    }
  }

  setCompanyOptions(pensionCompanyIds: string[]): void {
    this.companies = [];

    pensionCompanyIds.forEach((id) => {
      this.findSelectedCompanies(id);
    });

    this.setupFormControls(this.companiesGroup, this.companies);
  }

  findSelectedCompanies(pensionCompanyId: string): void {
    const pensionCompanies = this.appConfigService.getConfig().common.pensionCompanies;

    pensionCompanies.forEach((pensionCompany) => {
      if (pensionCompany.id === pensionCompanyId) {
        this.companies.push(pensionCompany);
      }
    });
  }

  setupFormControls(formGroup: UntypedFormGroup, objectList: IdObject[]): void {
    objectList.forEach((object) => {
      formGroup.addControl(object.id, this.formBuilder.control(false));
      formGroup.patchValue({ [object.id]: true });
    });

    formGroup.setValidators(this.checkBoxValidator(formGroup));
  }

  toggle(checked: boolean): void {
    this.checked = checked;
  }

  get saveAnalysisControl(): UntypedFormControl {
    return this.step3Form.get("saveAnalysis") as UntypedFormControl;
  }

  get analysisSaveNameControl(): UntypedFormControl {
    return this.step3Form.get("analysisSaveName") as UntypedFormControl;
  }

  private checkBoxValidator(formGroup: UntypedFormGroup): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      let selected = 0;
      Object.keys(formGroup.controls).forEach((key) => {
        const t = formGroup.controls[key];
        if (t.value === true) {
          selected++;
        }
      });

      if (selected === 0) {
        return { number: true };
      } else {
        return null;
      }
    };
  }

  naturalNumbers(n: number): any[] {
    return Array.from({ length: n }, (_, i) => i + 1);
  }
}

interface CoverageInAnalysis {
  employerData: {
    lossOfEarningCapacityTotal: number;
    disabilityLumpSumTotal: number;
    criticalIllnessTotal: number;
    deathTotal: number;
    orphansPensionTotal: number;
    isHealthInsurancePresent: boolean;
  };
}
