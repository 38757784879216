import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { NbMenuItem, NbMenuService, NbThemeService } from "@nebular/theme";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { filter } from "rxjs/operators";
import { AppMenuService } from "./app-menu.service";
import { AuthService, Company } from "./services/auth/auth.service";
import { AppConfigService } from "./app-config.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-root",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  profile = [{ title: "Logout" }];
  title = "desktopgui";
  items: NbMenuItem[] = [];
  userName: string | undefined;
  associatedCompany: Company;

  private userIdleThresholdSec = 20 * 60;
  isUserTimeoutCountingDown = false;
  secondsLeft?: number;
  userIdleTimeoutSec = 10 * 60;
  warningThresholdSec = 7.5 * 60;
  dangerThresholdSec = 5 * 60;
  doDisplayHonestusLogo: boolean;

  isIframe = false;

  navigateToHome(): void {
    void this.router.navigate([""]);
  }

  constructor(
    private appConfig: AppConfigService,
    private appMenuService: AppMenuService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private idle: Idle,
    private nbMenuService: NbMenuService,
    private router: Router,
    private themeService: NbThemeService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.associatedCompany = this.authService.getAssociatedCompany().name; // should be decided by the URL and if it contains Honestus or not
    this.doDisplayHonestusLogo = this.associatedCompany === Company.Honestus;
    this.themeService.changeTheme(this.associatedCompany);
  }

  private setUpIdle() {
    const idle = this.idle;
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.setIdle(this.userIdleThresholdSec);
    idle.setTimeout(this.userIdleTimeoutSec);
    idle.onTimeoutWarning.asObservable().subscribe((seconds) => {
      if (this.secondsLeft === seconds && this.isUserTimeoutCountingDown) {
        return;
      }
      this.isUserTimeoutCountingDown = true;
      this.secondsLeft = seconds;
      this.changeDetector.detectChanges();
    });
    idle.onInterrupt.subscribe(() => {
      if (!this.isUserTimeoutCountingDown) {
        return;
      }
      this.isUserTimeoutCountingDown = false;
      this.secondsLeft = undefined;
      this.changeDetector.detectChanges();
    });
    idle.onTimeout.subscribe(() => {
      this.secondsLeft = 0;
      this.logoutSession();
    });
    idle.watch();
  }

  ngOnInit(): void {
    this.authService.getUserName().then((username) => {
      this.userName = username;
      this.changeDetector.markForCheck();
    });
    void this.appConfig.loadAppConfig().then((appConfig) => {
      if (appConfig.client.userIdleThresholdSec) {
        this.userIdleThresholdSec = appConfig.client.userIdleThresholdSec;
      }
      if (appConfig.client.userIdleTimeoutSec) {
        this.userIdleTimeoutSec = appConfig.client.userIdleTimeoutSec;
      }
      if (appConfig.client.warningThresholdSec) {
        this.warningThresholdSec = appConfig.client.warningThresholdSec;
      }
      if (appConfig.client.dangerThresholdSec) {
        this.dangerThresholdSec = appConfig.client.dangerThresholdSec;
      }
      this.setUpIdle();
    });
    if (this.associatedCompany === Company.Honestus) {
      this.items = this.appMenuService.menuItemsHonestus;
    } else {
      this.items = this.appMenuService.menuItemsAnalyzer;
    }
    this.nbMenuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === "my-profile-menu"))
      .subscribe(() => this.authService.logout());
      this.isIframe = this.document.defaultView !== this.document.defaultView?.parent && !this.document.defaultView?.opener;
  }

  ngAfterViewInit(): void {
    const titles = document.querySelectorAll("nb-sidebar li.menu-item > a");
    titles.forEach((t) => t.removeAttribute("title"));
  }

  logoutSession(): void {
    this.authService.logout();
  }
}
