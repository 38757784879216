import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NbComponentStatus, NbToastrService } from "@nebular/theme";

import { InvestmentExpensesData } from "../../../interfaces/investment-expenses-data";
import { InvestmentExpensesService } from "../../../services/investment-expenses/investment-expenses.service";
import { MonitoringService } from "../../../services/monitoring/monitoring.service";
import { YieldYearsService } from "../../../services/yield-years/yield-years.service";
import { TableDataEditor, TypeOfUserMessage } from "../table-data-editor";
import { InvestmentExpensesFieldsComponent } from "../../../templates/investment-expenses-fields/investment-expenses-fields.component";
import { InvestmentExpensesLabelsComponent } from "../../../templates/investment-expenses-labels/investment-expenses-labels.component";

@Component({
  selector: "app-investment-expenses",
  templateUrl: "./investment-expenses.component.html",
  styleUrls: ["./investment-expenses.component.scss"],
})
export class InvestmentExpensesComponent extends TableDataEditor implements OnInit {
  @ViewChild(InvestmentExpensesLabelsComponent)
  investmentExspensesLabels?: InvestmentExpensesLabelsComponent;
  @ViewChildren(InvestmentExpensesFieldsComponent)
  investmentExspensesFields?: QueryList<InvestmentExpensesFieldsComponent>;

  data: InvestmentExpensesData[] = [];
  years: number[] = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public ref: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    public investmentExpensesService: InvestmentExpensesService,
    public monitoringService: MonitoringService,
    public toastService: NbToastrService,
    private yearsService: YieldYearsService
  ) {
    super(
      activatedRoute,
      investmentExpensesService,
      ref,
      formBuilder,
      monitoringService,
      toastService
    );
  }

  async ngOnInit(): Promise<void> {
    this.years = await this.yearsService.getYieldYears();
  }

  async setUpData(): Promise<void> {
    this.data.forEach((data, i) => {
      this.headersForms.at(i)?.setValue(data.investmentProfileName);
      this.getFieldsArray(i)?.setExpenseField(data);
      this.setUpYields(i);
      this.ref.detectChanges();
    });
  }

  setUpYields(columnIndex: number): void {
    const yieldColumn = this.getFieldsArray(columnIndex);
    const yields = this.data[columnIndex];

    yieldColumn?.setUpYields(yields);
  }

  async saveData(): Promise<void> {
    this.isSaving = true;
    try {
      await this.investmentExpensesService.saveInvestmentExpenses(
        this.pensionCompanyId,
        this.mutationData
      );

      this.setUserMessage(TypeOfUserMessage.ConfirmSave);
    } catch (Error) {
      this.setUserMessage(TypeOfUserMessage.SaveError);
    } finally {
      this.isSaving = false;
    }
  }

  get mutationData(): InvestmentExpensesData[] {
    const mutationData: InvestmentExpensesData[] = [];
    let i = 0;
    this.investmentExspensesFields?.forEach((field) => {
      const yieldObjects = this.getNotNullYields(field);

      const data: InvestmentExpensesData = {
        investmentProfileId: this.data[i] ? this.data[i].investmentProfileId : null,
        investmentProfileName: this.headersForms.at(i).value,
        priority: i,
        investmentExpenses: field.investmentExpenses.value.expense,
        yields: yieldObjects,
      };

      mutationData.push(data);
      i++;
    });
    return mutationData;
  }

  private getNotNullYields(field: InvestmentExpensesFieldsComponent) {
    const yieldObjects: { year: number; yield: number }[] = [];

    field.yieldsForms.controls.forEach((control, i) => {
      yieldObjects.push({ year: this.years[i], yield: control.value });
    });
    return yieldObjects;
  }

  getFieldsArray(i: number): InvestmentExpensesFieldsComponent {
    return this.investmentExspensesFields?.toArray()[i] as InvestmentExpensesFieldsComponent;
  }

  get numberOfYields(): number | undefined {
    return this.getFieldsArray(0)?.yieldsForms.length;
  }

  get isColumnsValid(): boolean {
    const fieldsArray = this.investmentExspensesFields?.toArray();
    if (fieldsArray !== undefined) {
      const allFieldsAreValid = fieldsArray.every((expense) => expense.column.valid);
      return allFieldsAreValid && this.headersForms.valid;
    } else {
      return false;
    }
  }

  setStatusHeader(i: number): NbComponentStatus {
    return this.headersForms.at(i).valid ? "info" : "danger";
  }
}
