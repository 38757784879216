import { Component } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";

import { AppConfigService } from "../../../../app-config.service";
import { Step3Component } from "../../step3.component";

@Component({
  selector: "app-step-3-analyzer",
  templateUrl: "./step-3-analyzer.component.html",
  styleUrls: ["./step-3-analyzer.component.scss"],
})
export class Step3AnalyzerComponent extends Step3Component {
  constructor(appConfigService: AppConfigService, formBuilder: UntypedFormBuilder) {
    super(appConfigService, formBuilder);
  }
}
