import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { NbComponentStatus } from "@nebular/theme";

import { CostGroupData } from "../../interfaces/cost-group-data";
import { CostGroupsService } from "../../services/cost-groups/cost-groups.service";

export interface CostGroupColumnValue {
  riskCoverage: {
    lossOfEarningCapacity: number;
    resourceCourseHandoutDkk?: number;
    resourceCourseHandoutPercent?: number;
    disabilityLumpSum: number;
    waiverOfPremium: number;
    criticalIllness: number;
    death: number;
    orphanPension: number;
  };
  costs: {
    administrationFee: number;
    brokerFee: number;
    ongoingCompensation?: number;
    brokerFeeOfAuM: number;
    establishmentFee: number;
    establishmentFeeDiscount: number;
    stairCasePremium: { lowerLimit: number; percentage: number }[];
    stairCaseAuM: { lowerLimit: number; percentage: number }[];
  };
}

enum StaircaseType {
  Premium = 0,
  AuM = 1,
}

enum GroupType {
  RiskCoverage = 0,
  Costs = 1,
}

@Component({
  selector: "app-cost-group-fields",
  templateUrl: "./cost-group-fields.component.html",
  styleUrls: ["./cost-group-fields.component.scss"],
})
export class CostGroupFieldsComponent implements OnInit {
  column: UntypedFormGroup;
  riskCoverage: UntypedFormGroup;
  costs: UntypedFormGroup;

  riskCoverageFormControls = [
    { formControlName: "lossOfEarningCapacity" },
    { formControlName: "resourceCourseHandoutDkk" },
    { formControlName: "resourceCourseHandoutPercent" },
    { formControlName: "disabilityLumpSum" },
    { formControlName: "waiverOfPremium" },
    { formControlName: "criticalIllness" },
    { formControlName: "death" },
    { formControlName: "orphanPension" },
  ];

  costsFormControls = [
    { formControlName: "brokerFee" },
    { formControlName: "ongoingCompensation" },
    { formControlName: "brokerFeeOfAuM" },
    { formControlName: "establishmentFee" },
    { formControlName: "establishmentFeeDiscount" },
    { formControlName: "administrationFee" },
  ];

  // In order to access the enum from the HTML
  StaircaseType = StaircaseType;
  GroupType = GroupType;

  required = [Validators.required];
  percentageValidation = [Validators.required, Validators.min(0), Validators.max(100)];

  lowerLimitTooltip = "Tallet skal være større end på forrige trin";
  percentageTooltip = "Tallet skal være en positiv procentsats";

  stairCasePremium = this.formBuilder.array([
    this.formBuilder.group({
      lowerLimit: this.formBuilder.control(0),
      percentage: [null, this.percentageValidation],
    }),
  ]);

  stairCaseAuM = this.formBuilder.array([
    this.formBuilder.group({
      lowerLimit: this.formBuilder.control(0),
      percentage: [null, this.percentageValidation],
    }),
  ]);

  constructor(
    private costGroupsService: CostGroupsService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.riskCoverage = this.formBuilder.group({
      lossOfEarningCapacity: [null, this.required],
      resourceCourseHandoutDkk: [null],
      resourceCourseHandoutPercent: [null],
      disabilityLumpSum: [null, this.required],
      waiverOfPremium: [null, this.required],
      criticalIllness: [null, this.required],
      death: [null, this.required],
      orphanPension: [null, this.required],
    });

    this.costs = this.formBuilder.group({
      administrationFee: [null, this.required],
      brokerFee: [null, this.required],
      ongoingCompensation: [null],
      brokerFeeOfAuM: [null, this.required],
      establishmentFee: [null, this.required],
      establishmentFeeDiscount: [null, this.required],
      stairCasePremium: this.stairCasePremium,
      stairCaseAuM: this.stairCaseAuM,
    });

    this.column = this.formBuilder.group({
      riskCoverage: this.riskCoverage,
      costs: this.costs,
    });

    this.addExtraSteps(this.costGroupsService.exstraStepsInStaircase);
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  addExtraSteps(amount: number): void {
    for (let i = 0; i < amount; i++) {
      this.addStep(i + 1);
    }
  }

  addStep(stepIndex: number): void {
    const stepPremium = this.formBuilder.group({
      lowerLimit: [null, this.staircaseLowerLimitValidation(stepIndex, StaircaseType.Premium)],
      percentage: [
        null,
        [
          this.staircasePercentageValidation(stepIndex, StaircaseType.Premium),
          Validators.min(0),
          Validators.max(100),
        ],
      ],
    });

    this.stairCasePremium.push(stepPremium);

    const stepAuM = this.formBuilder.group({
      lowerLimit: [null, this.staircaseLowerLimitValidation(stepIndex, StaircaseType.AuM)],
      percentage: [
        null,
        [
          this.staircasePercentageValidation(stepIndex, StaircaseType.AuM),
          Validators.min(0),
          Validators.max(100),
        ],
      ],
    });

    this.stairCaseAuM.push(stepAuM);
  }

  setRiskCoverageData(data: CostGroupData): void {
    this.riskCoverage.patchValue({
      lossOfEarningCapacity: data.lossOfEarningCapacity,
      resourceCourseHandoutDkk: data.resourceCourseHandoutDkk,
      resourceCourseHandoutPercent: data.resourceCourseHandoutPercent,
      disabilityLumpSum: data.disabilityLumpSum,
      waiverOfPremium: data.waiverOfPremium,
      criticalIllness: data.criticalIllness,
      death: data.death,
      orphanPension: data.orphanPension,
    });
  }

  setCostData(data: CostGroupData): void {
    this.costs.patchValue({
      administrationFee: data.administrationFee,
      brokerFee: data.brokerFee,
      ongoingCompensation: data.ongoingCompensation,
      brokerFeeOfAuM: data.brokerFeeOfAuM,
      establishmentFee: data.establishmentFee,
      establishmentFeeDiscount: data.establishmentFeeDiscount,
    });
  }

  setStaircaseData(data: CostGroupData): void {
    this.stairCasePremium.at(0).patchValue({
      lowerLimit: 0,
      percentage: data.administrationFeeOfPremium1,
    });
    this.stairCasePremium.at(1).patchValue({
      lowerLimit: data.administrationFeeLimitOfPremium2,
      percentage: data.administrationFeeOfPremium2,
    });
    this.stairCasePremium.at(2).patchValue({
      lowerLimit: data.administrationFeeLimitOfPremium3,
      percentage: data.administrationFeeOfPremium3,
    });

    this.stairCaseAuM.at(0).patchValue({
      lowerLimit: 0,
      percentage: data.administrationFeeOfAuM1,
    });
    this.stairCaseAuM.at(1).patchValue({
      lowerLimit: data.administrationFeeLimitOfAuM2,
      percentage: data.administrationFeeOfAuM2,
    });
    this.stairCaseAuM.at(2).patchValue({
      lowerLimit: data.administrationFeeLimitOfAuM3,
      percentage: data.administrationFeeOfAuM3,
    });
  }

  resetColumn(): void {
    this.column.reset();
    this.stairCaseAuM.at(0).patchValue({ lowerLimit: 0 });
    this.stairCasePremium.at(0).patchValue({ lowerLimit: 0 });
  }

  get riskCoverageForms(): UntypedFormGroup {
    return this.column.get("riskCoverage") as UntypedFormGroup;
  }

  get costForms(): UntypedFormGroup {
    return this.column.get("costs") as UntypedFormGroup;
  }

  setStatus(formControlName: string, groupType: GroupType): NbComponentStatus {
    const group = this.getGroupFromType(groupType);

    const isControlValid = group.get(formControlName)?.valid;
    return isControlValid ? "info" : "danger";
  }

  setStatusStaircase(
    i: number,
    formControlName: string,
    staircaseType: StaircaseType
  ): NbComponentStatus {
    const staircase = this.getStaircase(staircaseType);

    const staircaseValue = staircase.at(i).value;
    const isStepValid = staircase.at(i).get(formControlName)?.valid;

    // Triggers the validation of the next step if it exists.
    // The validation needs to be triggered since changes can happen in a step, that can effect the validity of the next step.
    const nextStep = staircase.at(i + 1);
    if (nextStep) {
      nextStep.get(formControlName)?.updateValueAndValidity();
    }

    if (i === 0 || staircaseValue.lowerLimit !== null || staircaseValue.percentage !== null) {
      return isStepValid ? "info" : "danger";
    } else {
      return "basic";
    }
  }

  /**
   * Custom validator function.
   *
   * @checks if the value is greater than or equal to the previous step.
   * @checks if the value of the percentage field on the same step is entered.
   * @param stepIndex
   * @param staircaseType
   * @checks if the previous step is null.
   * @param stepIndex, is used to determine the step of the control.
   * @param staircaseType, is used to determine which staircase to check the values of.
   * @returns null, if the input field is valid.
   * @returns key value pair, where the value is true, then the input field is invalid.
   */
  private staircaseLowerLimitValidation(
    stepIndex: number,
    staircaseType: StaircaseType
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const staircase = this.getStaircase(staircaseType);
      const previous = staircase.at(stepIndex - 1) as UntypedFormGroup;
      const isCurrentValueLessThanPrevious = control.value <= previous.getRawValue().lowerLimit;
      const isPercentageDirtyOut = staircase.at(stepIndex)?.value.percentage !== null;

      const isControlNotValid =
        (isPercentageDirtyOut && (isCurrentValueLessThanPrevious || control.value === null)) ||
        (previous.getRawValue().lowerLimit === null && control.value !== null);

      if (isControlNotValid) {
        return { stepsIncreasing: true };
      } else {
        return null;
      }
    };
  }

  /**
   * Custom validator function.
   *
   * @checks if the value of the lowerLimit field on the same step is entered.
   * @checks if the previous step is null.
   * @param stepIndex
   * @param staircaseType
   * @param stepIndex, is used to determine which step the control is on, in order to also check the validity based on the lowerLimit field.
   * @param staircaseType, is used to determine which staircase to check the values of.
   * @returns null, if the input field is valid.
   * @returns key value pair, where the value is true, then the input field is invalid.
   */
  private staircasePercentageValidation(
    stepIndex: number,
    staircaseType: StaircaseType
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const staircase = this.getStaircase(staircaseType);
      const isLowerLimitDirty = staircase.at(stepIndex)?.value.lowerLimit !== null;
      const previous = staircase.at(stepIndex - 1) as UntypedFormGroup;

      const isControlNotValid =
        (isLowerLimitDirty && control.value === null) ||
        (previous.value.percentage === null && control.value !== null);

      if (isControlNotValid) {
        return { stepsIncreasing: true };
      } else {
        return null;
      }
    };
  }

  /**
   * Determines which staircase is associated with a given StaircaseType.
   *
   * @param staircaseType, the type of staircase.
   * @param staircaseType
   * @returns the assoiated staircase with the given type.
   */
  private getStaircase(staircaseType: StaircaseType): UntypedFormArray {
    switch (staircaseType) {
      case StaircaseType.Premium:
        return this.stairCasePremium;
      case StaircaseType.AuM:
        return this.stairCaseAuM;
      default:
        throw new Error(`A staircase was not mapped to type: ${staircaseType}`);
    }
  }

  /**
   * Determines which staircase is associated with a given GroupType.
   *
   * @param groupType, the type of group.
   * @param groupType
   * @returns the assoiated group with the given type.
   */
  private getGroupFromType(groupType: GroupType): UntypedFormGroup {
    switch (groupType) {
      case GroupType.RiskCoverage:
        return this.riskCoverage;
      case GroupType.Costs:
        return this.costs;
      default:
        throw new Error(`A staircase was not mapped to type: ${groupType}`);
    }
  }

  /**
   * Disables an input field if there is data in the other one.
   * Enables both input fields if they are empty.
   *
   */

  disableResourceCourseInput(): boolean {
    const dkkControl = this.riskCoverage.get('resourceCourseHandoutDkk');
    const percentControl = this.riskCoverage.get('resourceCourseHandoutPercent');
  
    if (percentControl?.value !== null && percentControl?.value !== '') {
      dkkControl?.disable();
      return true;
    } else if (dkkControl?.value !== null && dkkControl?.value !== '') {
      percentControl?.disable();
      return true;
    }
    dkkControl?.enable();
    percentControl?.enable();
    return false;
  }
}
