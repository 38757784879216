import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NbComponentStatus, NbToastrService } from "@nebular/theme";

import { IdObject } from "../../interfaces/id-object";
import { HealthInsuranceCostGroupData } from "src/app/interfaces/health-insurance-cost-group-data";
import { HealthInsuranceCostGroupsService } from "src/app/services/health-insurance-cost-groups/health-insurance-cost-groups.service";
import { TypeOfUserMessage } from "src/app/pages/company-data/table-data-editor";

@Component({
  selector: "app-health-insurance-cost",
  templateUrl: "./health-insurance-cost.component.html",
  styleUrls: ["./health-insurance-cost.component.scss"],
})
export class HealthInsuranceCostComponent implements OnInit {
  @Input() company!: IdObject;

  showCosts = false;
  dataFetched = false;
  fetchedCostGroups: HealthInsuranceCostGroupData[] = [];

  costGroups = this.formBuilder.array([]);

  companyData = this.formBuilder.group({
    costGroups: this.costGroups,
  });

  isLoading = true;
  isSaving = false;
  displayErrorMessage = false;
  errorMessage = "";

  costTooltip = "Prisen skal være positiv eller nul";

  constructor(
    private ref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private healthInsuranceCostGroupsService: HealthInsuranceCostGroupsService,
    private toastService: NbToastrService
  ) {}

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  async headerClicked(): Promise<void> {
    this.showCosts = !this.showCosts;
    if (!this.dataFetched) {
      await this.fetchCostGroups();
      this.dataFetched = true;
      this.setInitialCostGroupControls();
      this.isLoading = false;
    }
  }

  async fetchCostGroups(): Promise<void> {
    try {
      this.fetchedCostGroups = await this.healthInsuranceCostGroupsService.getCostGroups(
        this.company.id
      );
    } catch (e) {
      this.displayErrorMessage = true;
      this.setUserMessage(TypeOfUserMessage.GetDataError);
    }
  }

  setInitialCostGroupControls(): void {
    const data = this.fetchedCostGroups;
    if (data.length !== 0) {
      data.forEach((c, i) => {
        const formGroup = this.createCostGroup(c);
        this.costGroups.setControl(i, formGroup);
      });
    } else {
      const group = this.createCostGroup({ priority: 0, costGroupId: null, name: "", cost: 0 });
      this.costGroups.setControl(0, group);
    }
    this.isLoading = false;
    this.ref.detectChanges();
  }

  private createCostGroup(data?: HealthInsuranceCostGroupData): UntypedFormGroup {
    const costGroup = this.formBuilder.group({
      priority: data?.priority ?? this.costGroups.length,
      costGroupId: data?.costGroupId,
      name: [data?.name ?? "", Validators.required],
      cost: [data?.cost ?? 0, [Validators.required, Validators.min(0)]],
    });

    return costGroup;
  }

  addCostGroup(data?: HealthInsuranceCostGroupData): void {
    this.costGroups.push(this.createCostGroup(data));
  }

  deleteLastCostGroup(): void {
    const last = this.costGroups.length - 1;
    this.costGroups.removeAt(last);
  }

  async saveData(): Promise<void> {
    this.isSaving = true;
    try {
      await this.healthInsuranceCostGroupsService.saveCostGroups(
        this.company.id,
        this.costGroups.value
      );
      this.setUserMessage(TypeOfUserMessage.ConfirmSave);
    } catch (e) {
      this.setUserMessage(TypeOfUserMessage.SaveError);
    } finally {
      this.isSaving = false;
    }
  }

  private setUserMessage(type: TypeOfUserMessage): void {
    switch (type) {
      case TypeOfUserMessage.ConfirmSave:
        this.toastService.success("Data er gemt.");
        break;
      case TypeOfUserMessage.GetDataError:
        this.errorMessage = "Kunne ikke hente data, prøv igen eller kontakt Mancofi.";
        break;
      case TypeOfUserMessage.SaveError:
        this.toastService.danger("Forsøg på at gemme data fejlede.");
        break;
    }
  }

  getControlAtIndex(i: number): UntypedFormGroup {
    return this.costGroups.controls[i] as UntypedFormGroup;
  }

  setStatus(i: number, formControlName: string): NbComponentStatus {
    const isControlValid = this.getControlAtIndex(i).get(formControlName)?.valid;
    return isControlValid ? "info" : "danger";
  }
}
