<form [formGroup]="column">
  <div formGroupName="riskCoverage" class="columns" *ngFor="let riskCoverageFormControl of riskCoverageFormControls">
    <input
      *ngIf="riskCoverageFormControl.formControlName === 'resourceCourseHandoutDkk' || riskCoverageFormControl.formControlName === 'resourceCourseHandoutPercent'"
      [status]="setStatus(riskCoverageFormControl.formControlName, GroupType.RiskCoverage)" 
      type="number" nbInput shape="semi-round" 
      class="box" 
      [formControlName]="riskCoverageFormControl.formControlName"
      [disabled]="disableResourceCourseInput()">
    <input
      *ngIf="riskCoverageFormControl.formControlName !== 'resourceCourseHandoutDkk' && riskCoverageFormControl.formControlName !== 'resourceCourseHandoutPercent'"
      [status]="setStatus(riskCoverageFormControl.formControlName, GroupType.RiskCoverage)" 
      type="number" nbInput
      shape="semi-round" 
      class="box" 
      [formControlName]="riskCoverageFormControl.formControlName">
  </div>

  <div formGroupName="costs" class="columns" *ngFor="let costsFormControl of costsFormControls" >
    <input
      [status]="setStatus(costsFormControl.formControlName, GroupType.Costs)"
      type="number" nbInput shape="semi-round"
      class="box"
      [formControlName]="costsFormControl.formControlName">
  </div>

  <div formGroupName="costs">
    <div formArrayName="stairCasePremium">
      <div [formArrayName]="i" *ngFor="let _ of stairCasePremium.controls; let i = index" class="row-flex" style="margin-bottom: 10px; min-width: max-content;">
        <input
          nbTooltip={{lowerLimitTooltip}} nbTooltipPlacement="right" nbTooltipStatus="info"
          [status]="setStatusStaircase(i, 'lowerLimit', StaircaseType.Premium)"
          type="number" nbInput shape="semi-round"
          style="width: 100px; margin-right: 15px;"
          placeholder="Grænse" formControlName="lowerLimit"
          [readOnly]="i < 1">
        <input
          nbTooltip={{percentageTooltip}} nbTooltipPlacement="right" nbTooltipStatus="info"
          [status]="setStatusStaircase(i, 'percentage', StaircaseType.Premium)"
          type="number" nbInput shape="semi-round"
          style="width: 100px;"
          placeholder="Sats" formControlName="percentage">
      </div>
    </div>
    <div formArrayName="stairCaseAuM">
      <div [formArrayName]="i" *ngFor="let _ of stairCaseAuM.controls; let i = index" class="row-flex" style="margin-bottom: 10px; min-width: max-content;">
        <input
          nbTooltip={{lowerLimitTooltip}} nbTooltipPlacement="right" nbTooltipStatus="info"
          [status]="setStatusStaircase(i, 'lowerLimit', StaircaseType.AuM)"
          type="number" nbInput shape="semi-round"
          style="width: 100px; margin-right: 15px;"
          placeholder="Grænse" formControlName="lowerLimit"
          [readOnly]="i < 1">
        <input
          nbTooltip={{percentageTooltip}} nbTooltipPlacement="right" nbTooltipStatus="info"
          [status]="setStatusStaircase(i, 'percentage', StaircaseType.AuM)"
          type="number" nbInput shape="semi-round"
          style="width: 100px;"
          placeholder="Sats" formControlName="percentage">
      </div>
    </div>
  </div>
</form>
