import { Component, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NbToastrService } from "@nebular/theme";
import { FileSaverService } from "ngx-filesaver";

import { AnalysisComponent } from "../analysis.component";
import { AnalysisService } from "../../../services/analysis/analysis.service";
import { AuthService } from "./../../../services/auth/auth.service";
import { CustomerService } from "../../../services/customer/customer.service";
import { CostGroupsService } from "../../../services/cost-groups/cost-groups.service";
import { InvestmentExpensesService } from "../../../services/investment-expenses/investment-expenses.service";
import { YieldYearsService } from "../../../services/yield-years/yield-years.service";
import { Step1AnalyzerComponent } from "./step-1-analyzer/step-1-analyzer.component";
import { Step2AnalyzerComponent } from "./step-2-analyzer/step-2-analyzer.component";
import { Step3AnalyzerComponent } from "./step-3-analyzer/step-3-analyzer.component";

@Component({
  selector: "app-analysis-analyzer",
  templateUrl: "./analysis-analyzer.component.html",
  styleUrls: ["./analysis-analyzer.component.scss"],
})
export class AnalysisAnalyzerComponent extends AnalysisComponent {
  @ViewChild(Step1AnalyzerComponent)
  step1Component!: Step1AnalyzerComponent;
  @ViewChild(Step2AnalyzerComponent)
  step2Component!: Step2AnalyzerComponent;
  @ViewChild(Step3AnalyzerComponent)
  step3Component!: Step3AnalyzerComponent;

  constructor(
    analysis: AnalysisService,
    authService: AuthService,
    costGroupsService: CostGroupsService,
    customerService: CustomerService,
    fileSaver: FileSaverService,
    builder: UntypedFormBuilder,
    investmentExpensesService: InvestmentExpensesService,
    router: Router,
    toastrService: NbToastrService,
    yieldYearsService: YieldYearsService
  ) {
    super(
      analysis,
      authService,
      costGroupsService,
      customerService,
      fileSaver,
      builder,
      investmentExpensesService,
      router,
      toastrService,
      yieldYearsService
    );
  }
}
