import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DotnetLogLevels } from "./dotnet-log-levels";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  private appConfigFileOrder = ["/assets/data/appconfig.json", "/assets/data/run/env.json"];
  private appConfig: AppConfig;
  private appConfigPromise?: Promise<AppConfig>;

  constructor(private http: HttpClient) {
    this.appConfig = {} as AppConfig;
  }

  async loadAppConfig(): Promise<AppConfig> {
    if (this.appConfigPromise) {
      return this.appConfigPromise;
    }
    this.appConfigPromise = this.createAppConfigPromise();
    return this.appConfigPromise;
  }

  async createAppConfigPromise(): Promise<AppConfig> {
    const appConfigFiles: Promise<any>[] = this.appConfigFileOrder.map((filename) =>
      this.http
        .get(filename)
        .toPromise()
        .catch((_) => {
          // Ignore error
        })
    );
    const appConfigs = await Promise.all(appConfigFiles);
    const mergedConfig = appConfigs.reduce(
      (accumulator, config) => Object.assign(accumulator, config),
      {}
    );
    this.appConfig = (mergedConfig || {}) as AppConfig;
    const overrideDisableTelemetry =
      this.appConfig.common.azure.applicationInsights.disableTelemetry;
    this.appConfig.doDisableTelemetry =
      overrideDisableTelemetry === undefined
        ? this.appConfig.environmentName === "Development"
        : !!overrideDisableTelemetry;
    return this.appConfig;
  }

  getConfig(): AppConfig {
    return this.appConfig;
  }
}

export interface AppConfig {
  applicationInsights: {
    roleName: string;
  };
  doDisableTelemetry: boolean;
  environmentName: string;
  client: {
    logLevel: DotnetLogLevels;
    userIdleThresholdSec?: number;
    userIdleTimeoutSec?: number;
    warningThresholdSec?: number;
    dangerThresholdSec?: number;
  };
  common: {
    pensionCompanies: { label: string; id: string }[];
    healthInsuranceCompanies: { label: string; id: string }[];
    azure: {
      appIdUri: string;
      applicationInsights: {
        instrumentationKey: string;
        disableTelemetry?: boolean;
      };
      clientId: string; // Azure AD B2C Application ID
      knownAuthorities: string;
      domain: string; // Azure AD B2C Domain name
      instance: string;
      scopes: [string];
      signUpSignInPolicyId: string; // Azure AD B2C User flow (policy) name
      tenantName: string;
    };
    offline?: {
      enabled: boolean;
    };
  };
}
