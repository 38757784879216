<div class="grid-wrapper">

  <div class="incrementDecrementButtons">
    <button [disabled]="this.companiesToCompareForms.length < 2"  nbButton (click)="deleteLastCompany()" size="medium" shape="round" status="info" style="margin-right: 20px">
      <nb-icon icon="minus-outline"></nb-icon>
    </button>
    <button [disabled]="this.companiesToCompareForms.length >= this.companies.length" nbButton (click)="addCompanyToCompare()" size="medium" shape="round" status="info">
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
  </div>

  <nb-card class="row" style="max-width: fit-content; overflow: auto;">
    <nb-card-body style="display: flex; flex-direction: column; max-width: fit-content; overflow: auto;">

      <div class="row" [formGroup]="step2Form">
        <div class="single-label label-width margin-right">Prisgruppe</div>

        <div formArrayName="companiesToCompare" class="column-flex">
          <div class="margin-right" *ngFor="let companyToCompare of companiesToCompareArray; let i = index; trackBy: trackByFn" [formGroupName]="i">
            <div>
              <nb-select
                (selectedChange)="setDropdownOptions($event, i)"
                placeholder="Selskab" style="width: 215px; margin-bottom: 30px;"
                shape="semi-round" formControlName="pensionCompanyId"
                [status]="setStatus(ControlType.PensionCompanyId, i)">
                <nb-option *ngFor="let company of companies" value={{company.id}}>{{company.label}}</nb-option>
              </nb-select>
            </div>
            <div>
              <nb-select placeholder="Vælg gruppe" formControlName="costGroupId" (selectedChange)="setCostGroupData($event, i)" #costGroupsSelector class="margin-bottom" style="width: 215px;" shape="semi-round">
                <nb-option *ngFor="let option of costGroupsHeaders[i]" [value]="option.id">{{ option.label }}</nb-option>
              </nb-select>
            </div>
          </div>
        </div>
      </div>

      <div class="row" [formGroup]="step2Form">
        <app-cost-group-labels class="label-width margin-right"></app-cost-group-labels>

        <div class="column-flex">
          <app-cost-group-fields class="margin-right" *ngFor="let companyToCompare of companiesToCompareArray; let i = index"></app-cost-group-fields>
        </div>
      </div>

      <div class="row" [formGroup]="step2Form">
        <div>
          <div *ngFor="let name of selectedProfileNames" class="margin label-width single-label-height margin-right">
            {{ name }}
          </div>
        </div>

        <div class="column-flex">
          <div formArrayName="companiesToCompare" class="margin-right" *ngFor="let companyToCompare of companiesToCompareArray; let i = index">
            <div [formGroupName]="i">
              <div *ngFor="let item of getInvestmentProfileInfos(i).controls; let j = index" formArrayName="investmentProfileInfos">
                <div [formGroupName]="j">
                  <nb-select [status]="setStatus(ControlType.InvestmentProfileInfos, i, j)" formControlName="investmentProfileId" placeholder="Vælg profil" (selectedChange)="setInvestmentExpensesData($event, i, j)" #investmentProfileSelector class="margin-bottom" style="width: 215px;" shape="semi-round">
                    <nb-option *ngFor="let option of investmentExpensesHeaders[i]" value={{option.id}} >{{ option.label }}</nb-option>
                  </nb-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" [formGroup]="step2Form">
        <app-health-group-labels class="label-width margin-right"></app-health-group-labels>

        <div class="column-flex">
          <app-health-insurance-fields class="margin-right" [isHealthInsuranceDisabled]="!isHealthInsurancePresent" *ngFor="let companyToCompare of companiesToCompareArray; let i = index"></app-health-insurance-fields>
        </div>
      </div>
      
      <div class="row move-and-delete-buttons">
        <div style="min-width: 290px; max-width: 290px; margin-right: 20px;"></div>
        <div class="column-flex">
          <ng-container *ngFor="let companyToCompare of companiesToCompareArray; let i = index" style="display: flex; flex: 1;">
            <div style="width: 215px; text-align-last: center; margin-right: 20px; height: 60px;">
              <div class="button-container" (mouseenter)="showButtons($event)" (mouseleave)="hideButtons($event)" #buttonContainer>
                <button nbButton size="medium" shape="round" status="info" style="margin-right: 1%; display: none;" (click)="moveCompany(i, 'left')">
                  <nb-icon icon="arrow-circle-left-outline"></nb-icon>
                </button>
                <button nbButton size="medium" shape="round" status="info" style="margin-right: 1%; display: none;" (click)="moveCompany(i, 'right')">
                  <nb-icon icon="arrow-circle-right-outline"></nb-icon>
                </button>
                <button nbButton size="medium" shape="round" status="info" style="margin-right: 1%; display: none;" (click)="deleteCompany(i)" [disabled]="companiesToCompareForms.length <= 1">
                  <nb-icon icon="trash-2-outline"></nb-icon>
                </button>                
                <nb-icon style="font-size: 24px; color: #357775;" *ngIf="buttonContainer && buttonContainer.querySelectorAll('button[style*=\'display: none\']').length === 3" icon="settings-2-outline"></nb-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      
    </nb-card-body>
  </nb-card>
</div>
