import { Injectable } from "@angular/core";

import { AnalysisInput } from "./analysis-input";
import { FileTransfererService } from "../file-transferrer/file-transferer.service";
import { FileMetadata } from "./file-metadata";

@Injectable({
  providedIn: "root",
})
/**
 * Provides functionality for requesting the server to compute the analysis.
 */
export class AnalysisService {
  constructor(private fileTransferer: FileTransfererService) {}

  /**
   * Computes the analysis, returning the analysis report(s).
   *
   * @param analysisArguments  All the input arguments to the analysis.
   */
  getAnalysisReports(
    analysisInputType: string,
    analysisArguments: AnalysisInput
  ): Promise<Promise<{ file: Blob | null; metadata: FileMetadata }>[]> {
    return this.fileTransferer.startFileDownloads(
      "getAnalysisReports",
      { analysisInput: `${analysisInputType}!` },
      { analysisInput: analysisArguments },
      "AnalysisReports",
      "reports",
      "AnalysisError"
    );
  }
}
