<nb-card status="danger">
  <nb-card-header>
    <nb-icon style="float: right; cursor: pointer;" icon="close" class="close" (click)="close(false)"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div style="font-size: large;">Vil du slette denne analyse og tilhørende effektuering?</div>
  </nb-card-body>
  <nb-card-footer style="display: flex; justify-content: space-between;">
    <button nbButton status="danger" (click)="close(true)">Slet</button>
    <button nbButton status="info" (click)="close(false)">Tilbage</button>
  </nb-card-footer>
</nb-card>
