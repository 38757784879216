import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-health-group-labels",
  templateUrl: "./health-group-labels.component.html",
  styleUrls: ["./health-group-labels.component.scss"],
})
export class HealthGroupLabelsComponent implements OnInit {
  healthInsuranceLabels = [
    { label: "Sundheds­forsikrings­selskab" },
    { label: "Sundheds­prisgruppe" },
    { label: "Sundhedsforsikring" },
  ];

  constructor() {
    // Nothing to do on construction yet
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }
}
