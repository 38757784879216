import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NbComponentStatus, NbSelectComponent } from "@nebular/theme";

import { InvestmentProfileMapping } from "./analysis.component";
import { AppConfigService } from "../../app-config.service";
import { CostGroupData } from "../../interfaces/cost-group-data";
import { IdObject } from "../../interfaces/id-object";
import { InvestmentExpensesData } from "../../interfaces/investment-expenses-data";
import { LabelObject } from "../../interfaces/label-object";
import { YearCounter } from "../../interfaces/year.counter";
import { YieldData } from "../../services/analysis/yield-data";
import { InvestmentGroup } from "../../services/company-file-parser/investmentGroup";
import { CostGroupsService } from "../../services/cost-groups/cost-groups.service";
import { InvestmentExpensesService } from "../../services/investment-expenses/investment-expenses.service";
import {
  CostGroupColumnValue,
  CostGroupFieldsComponent,
} from "../../templates/cost-group-fields/cost-group-fields.component";
import {
  HealthInsuranceFieldsComponent,
  HealthInsuranceValue,
} from "../../templates/health-insurance-fields/health-insurance-fields.component";
import { isProfileValid } from "../../utils/investment-profile-utils";

export interface Company {
  pensionCompanyId: string;
  costGroupId: string;
  basicCosts: CostGroupColumnValue;
  healthInsurance: HealthInsuranceValue;
}

export interface CompanyToCompare extends Company {
  investmentProfileInfos: InvestmentProfileInfo[];
}

export interface CompanyToCompareStore extends Company {
  investmentProfileMappings: InvestmentProfileMapping;
}

export interface Step2Value {
  companiesToCompare: [CompanyToCompare];
}

export interface Step2StoreValue {
  companiesToCompare: [CompanyToCompareStore];
}

@Component({
  selector: "app-step-2",
  template: "Not Implemented",
})
export abstract class Step2Component implements OnInit, AfterViewInit, YearCounter, OnChanges {
  @ViewChildren(CostGroupFieldsComponent) costGroupFields!: QueryList<CostGroupFieldsComponent>;
  @ViewChildren("costGroupsSelector") costGroupsDropdown!: QueryList<NbSelectComponent>;
  @ViewChildren("investmentProfileSelector")
  investmentProfileDropdown!: QueryList<NbSelectComponent>;
  @ViewChildren(HealthInsuranceFieldsComponent)
  healthInsuranceFields!: QueryList<HealthInsuranceFieldsComponent>;

  @Output() yearCountChanged: EventEmitter<void> = new EventEmitter<void>();
  private yearCount: number | null = null;

  costGroupData: CostGroupData[][] = [];
  companies: IdObject[];
  costGroupsHeaders: IdObject[][] = [];
  investmentExpensesHeaders: IdObject[][] = [];
  investmentExpenseData: InvestmentExpensesData[][] = [];

  requiredValidator = [Validators.required];
  ControlType = ControlType;

  // We have to create a separate array to instantiate the view before we can use the forms in the components.
  // If we use companiesToCompareForms.controls to instantiate   the view, the controls from the other components
  // won't be bound correctly with the view.
  companiesToCompareArray: string[] = [``];

  step2Form = this.formBuilder.group({
    companiesToCompare: this.formBuilder.array([
      this.formBuilder.group({
        pensionCompanyId: [null, this.requiredValidator],
        costGroupId: null,
        basicCosts: null,
        investmentProfileInfos: this.formBuilder.array([
          this.formBuilder.group({ investmentProfileId: [null, this.requiredValidator] }),
        ]),
        healthInsurance: null,
      }),
    ]),
  });

  get step2Value(): Step2Value {
    return this.step2Form.value as Step2Value;
  }

  @Input() isHealthInsurancePresent!: boolean;
  @Input() step1Value?: MappedProfileInfo[];
  @Input() years?: number[];

  currentAmoutOfChooseProfiles = this.getInvestmentProfileInfos(0).length;
  selectedProfileNames: (string | undefined)[] = [];

  constructor(
    public appConfigService: AppConfigService,
    public costGroupsService: CostGroupsService,
    public formBuilder: UntypedFormBuilder,
    public investmentExpensesService: InvestmentExpensesService
  ) {
    this.companies = this.appConfigService.getConfig().common.pensionCompanies;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentProfileInfo = changes.step1Value?.currentValue as MappedProfileInfo[] | undefined;
    this.addOrRemoveProfileControls(currentProfileInfo);

    const years = changes.years?.currentValue as number[] | undefined;
    if (years) {
      this.years = years;
    }
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const firstCompany = this.companiesToCompareForms.at(0);
      const companyData = this.formBuilder.group({
        pensionCompanyId: firstCompany.get("pensionCompanyId"),
        costGroupId: firstCompany.get("costGroupId"),
        basicCosts: this.costGroupFields?.last.column,
        investmentProfileInfos: firstCompany.get("investmentProfileInfos"),
        healthInsurance: this.healthInsuranceFields.last.healthInsurance,
      });

      this.companiesToCompareForms.setControl(0, companyData);
      this.getInvestmentProfileInfos(0).valueChanges.subscribe(() => this.childYearCountChanged());
    });
  }

  setHealthInsurance(c: HealthInsuranceValue, i: number): void {
    const fields = this.healthInsuranceFields?.get(i);
    fields?.setHealthInsurance(c);
  }

  setInvestmentProfiles(profiles: [InvestmentGroup], columns: InvestmentProfileMapping[]): void {
    columns.forEach((column, columnIndex) => {
      profiles.forEach((profile, profileIndex) => {
        const mappedId = column[profile.investmentProfile];
        setTimeout(() => this.setInvestmentExpensesData(mappedId, columnIndex, profileIndex));
      });
    });
  }

  addCompanyToCompare(company?: CompanyToCompareStore, i?: number): void {
    // The companyDropdown and the healthInsurance dropdown depend on that there exists a FormControl with the specified formControlName.
    // Because of this we need to create a formGroup were we instantiate pensionCompanyId and healthInsurance.

    const infos = [];
    for (let i = 0; i < this.currentAmoutOfChooseProfiles; i++) {
      infos.push(this.formBuilder.group({ investmentProfileId: [null, this.requiredValidator] }));
    }

    const companyData = this.formBuilder.group({
      pensionCompanyId: [company?.pensionCompanyId, this.requiredValidator],
      costGroupId: company?.costGroupId,
      basicCosts: company?.basicCosts,
      investmentProfileInfos: this.formBuilder.array(infos),
      healthInsurance: company?.healthInsurance,
    });

    // We now need to update the view to instantiate the costGroupFields and investmentExpenses components.
    this.companiesToCompareForms.push(companyData);
    // The companies are assigned a unique ID, which is needed for angular to be able to dynamically track and update the HTML
    this.companiesToCompareArray.push(this.getGeneratedCompanyID());
    this.investmentExpenseData.push([]);
    this.costGroupData.push([]);

    // To make sure that the view is updated we use the setTimeout.
    setTimeout(() => {
      this.addAllFieldsToStep2Form(company, i);
      const last = this.companiesToCompareArray.length - 1;
      this.getInvestmentProfileInfos(last).valueChanges.subscribe(() =>
        this.childYearCountChanged()
      );
    });
  }

  private getGeneratedCompanyID() {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    const desiredLengthOfID = 10;
    let counter = 0;
    while (counter < desiredLengthOfID) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  private addAllFieldsToStep2Form(company?: CompanyToCompareStore, i?: number): void {
    if (company && i) {
      const fields = this.costGroupFields?.get(i);
      fields?.column.patchValue(company.basicCosts);
      this.setHealthInsurance(company.healthInsurance, i);
    }

    // Because the pensionCompanyId and healthInsurance were already instantiated we have to use these instances
    // in order to use the same input fields in the view.
    // Now that the costGroupFields and investmentExpenses components are instantiated we can grab the last in the queryLists
    // and assign it to the respective FormControl.
    const lastCompany = this.companiesToCompareForms.at(!i ? this.lastCompanyIndex : Number(i));
    const companyData = this.formBuilder.group({
      pensionCompanyId: lastCompany.get("pensionCompanyId"),
      costGroupId: lastCompany.get("costGroupId"),
      basicCosts: !i
        ? this.costGroupFields?.last.column
        : this.costGroupFields?.get(Number(i))?.column,
      investmentProfileInfos: lastCompany.get("investmentProfileInfos"),

      healthInsurance: !i
        ? this.healthInsuranceFields?.last.healthInsurance
        : this.healthInsuranceFields.get(Number(i))?.healthInsurance,
    });

    // Since we had to push a FormGroup before the components were instantiated we have to replace the formGroup
    // the last index in the formArray, with the newly created formGroup.
    this.companiesToCompareForms.setControl(!i ? this.lastCompanyIndex : Number(i), companyData);
  }

  deleteLastCompany(): void {
    const index = this.lastCompanyIndex;
    this.companiesToCompareArray.splice(index, 1);
    this.costGroupData.splice(index, 1);
    this.investmentExpenseData.splice(index, 1);
    this.companiesToCompareForms.removeAt(index);
  }

  trackByFn(index: number, element: any) {
    return element;
  }

  deleteCompany(index: number): void {
    this.companiesToCompareForms.removeAt(index);
    this.companiesToCompareArray.splice(index, 1);
    this.costGroupData.splice(index, 1);
    this.investmentExpenseData.splice(index, 1);
  }

  moveCompany(index: number, direction: string): void {
    const lastIndex = this.companiesToCompareArray.length - 1;
    if (direction === "right" && index < lastIndex) {
      this.swapCompanies(index, index + 1);
    } else if (direction === "left" && index > 0) {
      this.swapCompanies(index, index - 1);
    }
  }

  private swapCompanies(indexA: number, indexB: number): void {
    this.swapArrayElements(this.companiesToCompareArray, indexA, indexB);
    this.swapArrayElements(this.costGroupData, indexA, indexB);
    this.swapArrayElements(this.investmentExpenseData, indexA, indexB);
    this.swapFormArrayElements(this.companiesToCompareForms, indexA, indexB);
    this.swapArrayElements(this.costGroupsHeaders, indexA, indexB);
    this.swapArrayElements(this.investmentExpensesHeaders, indexA, indexB);
  }

  private swapArrayElements<T>(array: T[], indexA: number, indexB: number): void {
    [array[indexA], array[indexB]] = [array[indexB], array[indexA]];
  }

  private swapFormArrayElements(formArray: FormArray, indexA: number, indexB: number): void {
    const formGroupA = formArray.at(indexA) as FormGroup;
    const formGroupB = formArray.at(indexB) as FormGroup;

    formArray.setControl(indexA, formGroupB);
    formArray.setControl(indexB, formGroupA);
  }

  async setDropdownOptions(pensionCompanyId: string, i: number): Promise<void> {
    this.clearDropdownsAndColumn(i);
    await this.setDropdownOptionsNoClear(pensionCompanyId, i);
  }

  async setDropdownOptionsNoClear(pensionCompanyId: string, i: number): Promise<void> {
    this.costGroupData[i] = await this.costGroupsService.getCostGroups(pensionCompanyId);
    this.setOptions(
      this.costGroupsHeaders,
      this.costGroupData,
      "costGroupName",
      "costGroupId",
      "disable",
      i
    );

    this.investmentExpenseData[i] = await this.investmentExpensesService.getInvestmentExpenses(
      pensionCompanyId
    );
    this.setOptions(
      this.investmentExpensesHeaders,
      this.investmentExpenseData,
      "investmentProfileName",
      "investmentProfileId",
      "disable",
      i
    );
  }

  setOptions(
    options: LabelObject[][],
    result: CostGroupData[][] | InvestmentExpensesData[][],
    labelKey: string,
    idKey: string,
    disableKey: string,
    i: number
  ): void {
    const array: { label: string; id: string; disabled: boolean }[] = [];

    result[i].forEach((data: { [key: string]: any }) => {
      array.push({ label: data[labelKey], id: data[idKey], disabled: data[disableKey] ?? false });
    });

    options[i] = array;
  }

  setCostGroupData(costGroupId: string, columnIndex: number): void {
    this.costGroupData[columnIndex].forEach((data) => {
      if (data.costGroupId === costGroupId) {
        const fields = this.getCostGroupFieldsElementByIndex(this.costGroupFields, columnIndex);
        fields?.setRiskCoverageData(data);
        fields?.setCostData(data);
        fields?.setStaircaseData(data);
      }
    });
  }

  setInvestmentExpensesData(
    investmentProfileId: string,
    columnIndex: number,
    profileIndex: number
  ): void {
    this.investmentExpenseData[columnIndex].forEach((data) => {
      if (data.investmentProfileId === investmentProfileId) {
        const control = this.formBuilder.group({
          investmentExpenses: data.investmentExpenses,
          investmentProfileId: data.investmentProfileId,
          investmentProfileName: data.investmentProfileName,
          yields: this.formBuilder.array(data.yields),
          match: this.selectedProfileNames[profileIndex],
        });
        control.setValidators(this.requiredValidator);
        setTimeout(() => {
          this.getInvestmentProfileInfos(columnIndex).setControl(profileIndex, control);
          this.yearCountChanged.emit();
        });
      }
    });
  }

  clearDropdownsAndColumn(i: number): void {
    this.getCostGroupFieldsElementByIndex(this.costGroupFields, i)?.resetColumn();
    this.costGroupsDropdown.toArray()[i].selected = null;

    this.getInvestmentProfileInfos(i).controls.forEach((c) => {
      c.reset();
      // Workaround, since the reset method is buggy and doesn't reset validators,
      // even reset to new control with a new validator.
      c.setErrors(this.requiredValidator);
    });
    this.yearCountChanged.emit();
  }

  getBasicCostsForms(i: number): UntypedFormGroup {
    return this.companiesToCompareForms.at(i).get("basicCosts") as UntypedFormGroup;
  }

  getCostGroupFieldsElementByIndex(
    queryList: QueryList<CostGroupFieldsComponent>,
    index: number
  ): CostGroupFieldsComponent | undefined {
    return queryList.find((_: any, i: number) => i === index);
  }

  setStatus(controlType: ControlType, i?: number, j?: number): NbComponentStatus {
    const control = this.getControlFromType(controlType, i, j);
    return control.valid ? "info" : "danger";
  }

  private addOrRemoveProfileControls(current: MappedProfileInfo[] | undefined): void {
    const old = this.currentAmoutOfChooseProfiles;
    const selectedProfiles = current?.filter(isProfileValid);
    if (selectedProfiles && selectedProfiles.length !== old) {
      this.selectedProfileNames = selectedProfiles.map((s) => {
        if (s.header) return s.header;
        return s.investmentProfileName;
      });

      this.currentAmoutOfChooseProfiles = selectedProfiles.length;
      const difference: number | undefined = this.currentAmoutOfChooseProfiles - old;
      if (difference < 0) {
        for (let i = old; i >= this.currentAmoutOfChooseProfiles; i--) {
          this.companiesToCompareArray.forEach((_, j) =>
            this.getInvestmentProfileInfos(j).removeAt(i)
          );
        }
      } else {
        for (let i = old; i < this.currentAmoutOfChooseProfiles; i++) {
          this.companiesToCompareArray.forEach((_, j) =>
            this.getInvestmentProfileInfos(j).insert(
              i,
              this.formBuilder.group({ investmentProfileId: [null, this.requiredValidator] })
            )
          );
        }
      }
      this.companiesToCompareArray.forEach((_, j) => {
        this.getInvestmentProfileInfos(j).reset();
      });
    }
  }

  private getControlFromType(controlType: ControlType, i?: number, j?: number): AbstractControl {
    switch (controlType) {
      case ControlType.PensionCompanyId:
        if (i !== undefined) {
          return this.companiesToCompareForms.at(i).get("pensionCompanyId") as UntypedFormControl;
        }
        throw new Error("Index is not defined");
      case ControlType.InvestmentProfileInfos:
        if (i !== undefined && j !== undefined) {
          return this.getInvestmentProfileInfos(i).at(j);
        }
        throw new Error("Both indices are not defined");
      default:
        throw new Error(`No such Control type defined for type: ${controlType}`);
    }
  }

  get companiesToCompareForms(): UntypedFormArray {
    return this.step2Form.get("companiesToCompare") as UntypedFormArray;
  }

  get lastCompanyIndex(): number {
    return this.companiesToCompareForms.length - 1;
  }

  getYearCount(): number | null {
    return this.yearCount;
  }

  getInvestmentProfileInfos(i: number): UntypedFormArray {
    return this.companiesToCompareForms.at(i).get("investmentProfileInfos") as UntypedFormArray;
  }

  childYearCountChanged(): void {
    const oldYearCount = this.yearCount;
    const newYearCount = this.computeYearCount();
    if (newYearCount !== oldYearCount) {
      this.yearCount = newYearCount;
      this.yearCountChanged.emit();
    }
  }

  private computeYearCount(): number | null {
    const yearCounts = this.companiesToCompareForms.controls.map((c) => {
      const formArray = c.get("investmentProfileInfos");
      const profileInfo = formArray?.value as InvestmentProfileInfo[] | undefined;
      const companyYears = profileInfo?.map((info) => info?.yields?.map((y) => y?.year));

      const companyYearCounts = companyYears?.map((years) => {
        const currentYearCount = years?.reduce((count, y, i) => {
          if (y && this.years && y === this.years[i]) return count + 1;
          return count;
        }, 0);
        return currentYearCount;
      });

      return companyYearCounts && companyYearCounts.length > 0 ? Math.min(...companyYearCounts) : 0;
    });

    return yearCounts && yearCounts.length > 0 ? Math.min(...yearCounts) : null;
  }

  showButtons(event: MouseEvent) {
    const buttons = (event.currentTarget as HTMLElement).querySelectorAll("button");
    buttons.forEach((button) => {
      button.style.display = "inline-block";
    });
  }

  hideButtons(event: MouseEvent) {
    const buttons = (event.currentTarget as HTMLElement).querySelectorAll("button");
    buttons.forEach((button) => {
      button.style.display = "none";
    });
  }
}

enum ControlType {
  PensionCompanyId = 0,
  InvestmentProfileInfos = 1,
}

interface MappedProfileInfo {
  annualPremium?: number;
  aum?: number;
  chooseProfile?: string;
  employeeCount?: number;
  header?: string;
  investmentProfileName?: string;
  percentOfDepository?: number;
  yearCount?: number;
}

interface InvestmentProfileInfo {
  investmentExpenses: number;
  investmentProfileId: string;
  investmentProfileName: string;
  yields: YieldData[];
  match: string;
}
