import { Component, ChangeDetectorRef, OnInit } from "@angular/core";

import {
  UntypedFormBuilder,
  AbstractControl,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { NbComponentStatus, NbToastrService } from "@nebular/theme";
import { RecommendationsService } from "src/app/services/recommendations/recommendations.service";
import { RecommendationsData } from "src/app/services/recommendations/recommendationsData";

@Component({
  selector: "app-recommendations",
  templateUrl: "./recommendations.component.html",
  styleUrls: ["./recommendations.component.scss"],
})
export class RecommendationsComponent implements OnInit {
  percentageValidators = [Validators.required, Validators.min(0), Validators.max(100)];
  interestValidators = [Validators.required, Validators.min(-100), Validators.max(100)];
  minimumValidators = [Validators.required, Validators.min(0)];

  percentageTooltip = "Tallet skal være en positiv procentsats";
  interestTooltip = "Forventet rente efter ÅOP er fratrukket";
  minimumTooltip = "Tallet skal være positivt";

  isLoading = true;
  saving = false;
  intervals = this.formBuilder.array([
    this.formBuilder.group({
      label: "< 300.000 kr",
      intervalStart: 0,
      valueMin: [null, this.percentageValidators],
    }),
    this.formBuilder.group({
      label: "300.000 - 400.000 kr",
      intervalStart: 300000,
      valueMin: [null, this.percentageValidators],
    }),
    this.formBuilder.group({
      label: "400.000 - 500.000 kr",
      intervalStart: 400000,
      valueMin: [null, this.percentageValidators],
    }),
    this.formBuilder.group({
      label: "> 500.000 kr",
      intervalStart: 500000,
      valueMin: [null, this.percentageValidators],
    }),
  ]);

  lossOfEarningCapacity = this.formBuilder.group({
    intervals: this.intervals,
  });

  aumAtRetirementAge = this.formBuilder.group({
    retirementAumYearlySalaryMinFactor: [null, this.minimumValidators],
    typicalRetirementAge: [null, this.percentageValidators],
    averageInterest: [null, this.interestValidators],
    averageYieldTax: [null, this.percentageValidators],
  });

  recommendations = this.formBuilder.group({
    criticalIllnessMin: [null, this.minimumValidators],
    deathPercentMin: [null, this.minimumValidators],
    aumAtRetirementAge: this.aumAtRetirementAge,
    lossOfEarningCapacity: this.lossOfEarningCapacity,
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private insuranceRecommendationsService: RecommendationsService,
    private changeDetector: ChangeDetectorRef,
    private toastrService: NbToastrService
  ) {
    this.insuranceRecommendationsService.getData().then((result) => {
      this.setUpRecommendations(result);
    });
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  setUpRecommendations(data: RecommendationsData): void {
    data?.lossOfEarningCapacity?.forEach((element) => {
      const val = this.intervals.value as UntypedFormGroup[];
      val.forEach((_, i) => {
        const intervalStart = this.intervals.at(i).get("intervalStart")?.value;
        if (element.intervalStart === intervalStart) {
          const valueMin = this.intervals.at(i).get("valueMin");
          valueMin?.setValue(element.valueMin);
        }
      });
    });

    this.aumAtRetirementAge.patchValue({
      retirementAumYearlySalaryMinFactor: data.retirementAumYearlySalaryMinFactor,
      typicalRetirementAge: data.typicalRetirementAge,
      averageInterest: data.averageInterest,
      averageYieldTax: data.averageYieldTax,
    });

    this.recommendations.patchValue({
      criticalIllnessMin: data.criticalIllnessMin,
      deathPercentMin: data.deathPercentMin,
      aumAtRetirementAge: this.aumAtRetirementAge,
      lossOfEarningCapacity: this.lossOfEarningCapacity,
    });
    this.isLoading = false;
    this.changeDetector.markForCheck();
  }

  getIntervalValue(interval: AbstractControl): string {
    if (interval.value.label) {
      return interval.value.label;
    }

    return "";
  }

  async onSubmit(): Promise<void> {
    this.saving = true;
    try {
      await this.insuranceRecommendationsService.setData(this.getRecommendationsData());
      this.toastrService.success("Data er gemt.");
    } catch (e) {
      this.toastrService.danger("Forsøg på at gemme data fejlede.");
      throw e;
    } finally {
      this.saving = false;
      this.changeDetector.markForCheck();
    }
  }

  getRecommendationsData(): RecommendationsData {
    return new RecommendationsData(
      this.recommendations.value.deathPercentMin,
      this.recommendations.value.criticalIllnessMin,
      this.recommendations.value.aumAtRetirementAge.retirementAumYearlySalaryMinFactor,
      this.recommendations.value.aumAtRetirementAge.typicalRetirementAge,
      this.recommendations.value.aumAtRetirementAge.averageInterest,
      this.recommendations.value.aumAtRetirementAge.averageYieldTax,
      this.intervals.value
    );
  }

  getIntervalsControl(i: number): UntypedFormControl {
    return this.intervals.at(i).get("valueMin") as UntypedFormControl;
  }

  setStatusIntervals(i: number): NbComponentStatus {
    const isControlValid = this.getIntervalsControl(i).valid;
    return isControlValid ? "info" : "danger";
  }

  setStatusRecommendationsGroup(formControlName: string): NbComponentStatus {
    const isControlValid = this.recommendations.get(formControlName)?.valid;
    return isControlValid ? "info" : "danger";
  }

  setStatusAumAtRetirementAge(formControlName: string): NbComponentStatus {
    const isControlValid = this.recommendations
      .get("aumAtRetirementAge")
      ?.get(formControlName)?.valid;
    return isControlValid ? "info" : "danger";
  }
}
