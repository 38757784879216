import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { NbComponentStatus, NbToastrService } from "@nebular/theme";

import { CostGroupsService } from "../../services/cost-groups/cost-groups.service";
import { CompanyFormat } from "../../services/insurance-conditions/companyFormat";
import {
  InsuranceConditionsService,
  InsuranceKind,
} from "../../services/insurance-conditions/insurance-conditions.service";
import { InsuranceConditionsFormat } from "../../services/insurance-conditions/insuranceConditionsFormat";
import { IdObject } from "../../interfaces/id-object";
import { NGXLogger } from "ngx-logger";

@Component({
  selector: "app-insurance-conditions",
  templateUrl: "./insurance-conditions.component.html",
  styleUrls: ["./insurance-conditions.component.scss"],
})
export class InsuranceConditionsComponent implements OnInit {
  private insuranceKind?: InsuranceKind;
  columns = this.formBuilder.group({
    conditions: this.formBuilder.array([]),
    companies: this.formBuilder.array([]),
  });
  page = this.formBuilder.group({
    columns: this.columns,
  });

  header = "";
  isSaving = false;
  companies: { id: string; label: string }[] = [];
  costGroups: IdObject[][] = [];
  private costGroupsByCompany: { [p: string]: IdObject[] } = {};

  ratingMin = 1;
  ratingMax = 20;

  ratingValidator = [
    Validators.required,
    Validators.min(this.ratingMin),
    Validators.max(this.ratingMax),
  ];
  ratingTooltip = `Tallet skal være mellem ${this.ratingMin} og ${this.ratingMax}`;
  Row = Row;

  isLoading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private costGroupsService: CostGroupsService,
    private insuranceConditionsService: InsuranceConditionsService,
    private toastrService: NbToastrService,
    private logger: NGXLogger
  ) {
    this.addCondition();

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.isLoading = true;
      const insuranceKind = params.get("insurance") as InsuranceKind;
      if (insuranceKind !== null) {
        this.insuranceKind = insuranceKind;
        this.header = this.translateURLParam(insuranceKind);
        switch (this.insuranceKind) {
          case "criticalIllness":
          case "lossOfEarningCapacity":
            this.companies = this.insuranceConditionsService.getPensionCompanies();
            break;
          case "healthInsurance":
            this.companies = this.insuranceConditionsService.getHealthInsuranceCompanies();
            break;
          default:
            this.logger.error("Unknown insurance kind '{insranceKind}'", this.insuranceKind);
        }

        this.costGroupsService
          .fetchCostGroupsByCompany(this.insuranceKind, this.companies)
          .then((costGroupsByCompany) => (this.costGroupsByCompany = costGroupsByCompany))
          .then(() => {
            this.addCompany(undefined);

            this.insuranceConditionsService.getData(insuranceKind).then((result) => {
              this.conditionsForms.clear();
              this.companiesForms.clear();
              this.costGroups = [];

              this.setUpConditions(result);
              this.setUpCompanies(result);
              this.isLoading = false;
              this.changeDetector.detectChanges();
            });
          });
      }
    });
  }

  translateURLParam(param: string): string {
    switch (param) {
      case "lossOfEarningCapacity":
        return "Tab af erhvervsevne";
      case "criticalIllness":
        return "Kritisk sygdom";
      case "healthInsurance":
        return "Sundhedsforsikring";
      default:
        return "";
    }
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  setUpConditions(data: InsuranceConditionsFormat): void {
    for (let i = 0; i < data.conditions.length; i++) {
      if (!this.conditionsForms.at(i)) {
        this.addCondition();
      }

      this.conditionsForms.at(i).setValue(data.conditions[i]);
    }
    if (this.conditionsForms.length < 1) {
      this.addCondition();
    }
  }

  setUpCompanies(data: InsuranceConditionsFormat): void {
    for (let i = 0; i < data.companies.length; i++) {
      if (!this.companiesForms.at(i)) {
        const company = data.companies[i];
        this.addCompany({
          companyId: company.companyId.toLowerCase(),
          companyName: company.companyName,
          costGroupId: company.costGroupId.toLowerCase(),
          costGroupName: company.costGroupName,
          coverages: company.coverages,
          ratings: company.ratings,
        });
      }
    }
    if (this.companiesForms.length < 1) {
      this.addCompany(undefined);
    }
  }

  addCondition(): void {
    this.conditionsForms.push(this.formBuilder.control(null, [Validators.required]));

    this.coveragesForms.forEach((coverage) => {
      coverage.push(this.formBuilder.control(null, [Validators.required]));
    });

    this.ratingssForms.forEach((rating) => {
      rating.push(this.formBuilder.control(null, this.ratingValidator));
    });
  }

  addCompany(companyData: CompanyFormat | undefined): void {
    const companyId = companyData?.companyId;
    const coverages = companyData
      ? this.formBuilder.array(
          companyData.coverages.map((c) => new UntypedFormControl(c, Validators.required))
        )
      : this.getArrayOfConditions(Row.Coverage);
    const ratings = companyData
      ? this.formBuilder.array(
          companyData.ratings.map((r) => new UntypedFormControl(r, this.ratingValidator))
        )
      : this.getArrayOfConditions(Row.Rating);
    const company = this.formBuilder.group({
      companyId: [companyId, Validators.required],
      companyName: companyData?.companyName,
      costGroupId: companyData?.costGroupId,
      costGroupName: companyData?.costGroupName,
      coverages,
      ratings,
    });

    const costGroups = this.getCostGroups(companyId);
    this.costGroups.push(costGroups);
    this.companiesForms.push(company);
  }

  deleteLastCondition(): void {
    const lastIndex = this.conditionsForms.length - 1;

    this.conditionsForms.removeAt(lastIndex);

    this.coveragesForms.forEach((coverage) => {
      coverage.removeAt(lastIndex);
    });

    this.ratingssForms.forEach((coverage) => {
      coverage.removeAt(lastIndex);
    });
  }

  deleteLastCompany(): void {
    this.companiesForms.removeAt(this.companiesForms.length - 1);
    this.costGroups.splice(this.costGroups.length - 1, 1);
  }

  getArrayOfConditions(controlType: Row): UntypedFormArray {
    const lengthOfConditionsArray: UntypedFormArray = this.formBuilder.array([]);

    this.conditionsForms.controls.forEach(() => {
      const control: UntypedFormControl = this.formBuilder.control(null);
      switch (controlType) {
        case Row.Coverage:
          control.setValidators(Validators.required);
          break;
        case Row.Rating:
          control.setValidators(this.ratingValidator);
          break;
        default:
          this.logger.error("Can't find control with type: {controlType}", controlType);
          break;
      }

      lengthOfConditionsArray.push(control);
    });

    return lengthOfConditionsArray;
  }

  async onSubmit(): Promise<void> {
    const insuranceKind = this.insuranceKind;
    if (!insuranceKind) {
      return;
    }
    this.isSaving = true;
    try {
      await this.insuranceConditionsService.setData(
        insuranceKind,
        this.columns.value as InsuranceConditionsFormat
      );
      this.toastrService.success("Data er gemt.");
    } catch (e) {
      this.toastrService.danger("Forsøg på at gemme data fejlede.");
      throw e;
    } finally {
      this.isSaving = false;
      this.changeDetector.markForCheck();
    }
  }

  setStatus(controlType: Row, i: number, j?: number): NbComponentStatus {
    let type: UntypedFormArray;
    if (j !== undefined) {
      type = this.getControlFromType(controlType, j);
    } else {
      type = this.getControlFromType(controlType);
    }

    return type.at(i).valid ? "info" : "danger";
  }

  setStatusCompanyId(i: number): string {
    const control = this.companiesForms.at(i).get("companyId") as UntypedFormControl;

    return control.valid ? "info" : "danger";
  }

  getControlFromType(controlType: Row, i?: number): UntypedFormArray {
    switch (controlType) {
      case Row.Condition:
        return this.conditionsForms;
      case Row.Coverage:
        if (i !== undefined) {
          return this.companiesForms.at(i).get("coverages") as UntypedFormArray;
        }
        throw new Error(`'${i}' is not defined for type ${controlType}`);
      case Row.Rating:
        if (i !== undefined) {
          return this.companiesForms.at(i).get("ratings") as UntypedFormArray;
        }
        throw new Error(`'${i}' is not defined for type ${controlType}`);
      default:
        throw new Error(`Can't find control with type: ${controlType}`);
    }
  }

  get conditionsForms(): UntypedFormArray {
    return this.columns.get("conditions") as UntypedFormArray;
  }

  get companiesForms(): UntypedFormArray {
    return this.columns.get("companies") as UntypedFormArray;
  }

  get coveragesForms(): UntypedFormArray[] {
    const coverages: any[] = [];

    for (let i = 0; i < this.companiesForms.length; i++) {
      coverages.push(this.companiesForms.at(i).get("coverages") as UntypedFormArray);
    }

    return coverages;
  }

  get ratingssForms(): UntypedFormArray[] {
    const ratings: any[] = [];

    for (let i = 0; i < this.companiesForms.length; i++) {
      ratings.push(this.companiesForms.at(i).get("ratings") as UntypedFormArray);
    }

    return ratings;
  }

  private getCostGroups(companyId: string | undefined): IdObject[] {
    if (!companyId || !this.costGroupsByCompany) {
      return [];
    }
    return this.costGroupsByCompany[companyId] ?? [];
  }

  onCompanyIdChange(newCompanyId: string, columnIndex: number): void {
    this.costGroups[columnIndex] = this.costGroupsByCompany[newCompanyId];
    this.companiesForms.at(columnIndex).patchValue({ costGroupId: null });
  }
}

enum Row {
  Condition = 0,
  Coverage = 1,
  Rating = 2,
}
