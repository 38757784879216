import { Injectable } from "@angular/core";
import { gql } from "@apollo/client/core";
import { NGXLogger } from "ngx-logger";

import { Analysis, AnalysisInput, WinningOffer } from "./analysis";

import { ApolloInstanceService } from "../apollo/apollo-instance.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(private apollo: ApolloInstanceService, private logger: NGXLogger) {}

  async getAnalyses(): Promise<Analysis[]> {
    const apollo = await this.apollo.getInstance();

    const query = gql`
      query {
        customer {
          getAnalyses {
            id
            name
            saveDate
            employeeFileName
            companyEmployeeCount
            arguments
            winningOffer {
              pensionCompanyId
              riskCompanyId
              healthInsuranceCompanyId
              reason
              valueDate
            }
          }
        }
      }
    `;

    const result = await apollo.query({ query, fetchPolicy: "network-only" });
    return result.data.customer.getAnalyses;
  }

  async saveAnalysis(analysisInput: AnalysisInput): Promise<SaveResult> {
    const apollo = await this.apollo.getInstance();

    const mutation = gql`
      mutation ($analysisInput: AnalysisInput!) {
        customer {
          saveAnalysis(analysisInput: $analysisInput) {
            saveSuccessfull
            errorMessage
          }
        }
      }
    `;

    const variables = { analysisInput };
    const result = await apollo.mutate({ mutation, variables });
    return result.data.customer.saveAnalysis;
  }

  async saveWinningOffer(analysisId: string, winningOffer: WinningOffer): Promise<SaveResult> {
    const apollo = await this.apollo.getInstance();

    const mutation = gql`
      mutation ($analysisId: Guid!, $winningOfferInput: WinningOfferInput!) {
        customer {
          saveWinningOffer(analysisId: $analysisId, winningOfferInput: $winningOfferInput) {
            saveSuccessfull
            errorMessage
          }
        }
      }
    `;

    const variables = {
      analysisId,
      winningOfferInput: winningOffer,
    };
    const result = await apollo.mutate({ mutation, variables });
    return result.data.customer.saveWinningOffer;
  }

  async deleteAnalysis(analysisId: string): Promise<SaveResult> {
    const apollo = await this.apollo.getInstance();

    const mutation = gql`
      mutation ($analysisId: Guid!) {
        customer {
          deleteAnalysis(analysisId: $analysisId) {
            saveSuccessfull
            errorMessage
          }
        }
      }
    `;

    const variables = { analysisId };
    const result = await apollo.mutate({ mutation, variables });
    return result.data.customer.deleteAnalysis;
  }
}

interface SaveResult {
  saveSuccessfull: boolean;
  errorMessage?: string;
}
