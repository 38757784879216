<div *ngIf="isLoading" class="full-screen center-and-middle">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!displayErrorMessage && !isLoading">
  <div style="margin-bottom: 30px;">
    <button [disabled]="costGroups.length < 2" nbButton size="medium" shape="round" status="info" (click)="deleteLastCostGroup()" style="margin-right: 20px;">
      <nb-icon icon="minus-outline"></nb-icon>
    </button>
    <button nbButton size="medium" shape="round" status="info" (click)="addEmptyCostGroup()">
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
  </div>

  <nb-card style="display: flex; flex-direction: row; max-width: fit-content;">
    <nb-card-body style="max-width: fit-content; margin-top: 40.25px;">
      <p>Pris</p>
    </nb-card-body>
    <nb-card-body [formGroup]="companyData">
      <div class="flex-row" formArrayName="costGroups">
        <div class="flex-column margin-right" *ngFor="let costGroup of costGroups.controls; let i = index" [formArrayName]="i">
          <input
            nbInput [status]="setStatus(i, 'name')" type="text"
            placeholder="Prisgruppenavn" shape="semi-round"
            formControlName="name" class="margin">
          <input
            nbTooltip={{costTooltip}} nbTooltipPlacement="right" nbTooltipStatus="info"
            nbInput [status]="setStatus(i, 'cost')"
            type="number" step="0.1"
            shape="semi-round" formControlName="cost"
            placeholder="Kr.">
        </div>
      </div>
    </nb-card-body>
  </nb-card>

  <button
    [disabled]="!companyData.valid || isSaving" nbButton (click)="saveData()"
    status="primary" type="submit" size="giant"
    [nbSpinner]="isSaving" nbSpinnerSize="giant" nbSpinnerStatus="control">
    Gem
  </button>
</div>
