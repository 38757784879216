import { Location, PlatformLocation } from "@angular/common";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import {
  MSAL_GUARD_CONFIG,
  MsalGuard,
  MsalService,
  MsalGuardConfiguration,
  MsalBroadcastService,
} from "@azure/msal-angular";
import { AppConfig, AppConfigService } from "../../app-config.service";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

/**
 * Provides functionality for protecting routes based on MSAL authentication.
 */
@Injectable({
  providedIn: "root",
})
export class AuthGuard extends MsalGuard {
  private appConfig: AppConfig;

  constructor(
    private appConfigService: AppConfigService,
    @Inject(MSAL_GUARD_CONFIG) msalConfig: MsalGuardConfiguration,
    authService: MsalService,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location,
    platformLocation: PlatformLocation,
    msalBroadcastService: MsalBroadcastService
  ) {
    super(msalConfig, msalBroadcastService, authService, location, router);
    this.appConfig = this.appConfigService.getConfig();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const subject = new Subject<boolean>();
    if (this.appConfig.common.offline?.enabled) {
      subject.next(true);
      return subject.asObservable();
    }
    return super.canActivate(route, state);
  }
}
