import { AfterViewInit, Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NbToastrService } from "@nebular/theme";
import { FileSaverService } from "ngx-filesaver";

import { YearCounter } from "../../interfaces/year.counter";
import { AnalysisFactory } from "./../../services/analysis/analysis-factory";
import { AnalysisInput } from "../../services/analysis/analysis-input";
import { AnalysisService } from "../../services/analysis/analysis.service";
import { CustomerService } from "../../services/customer/customer.service";
import { AuthService, Variant } from "./../../services/auth/auth.service";
import { YieldYearsService } from "../../services/yield-years/yield-years.service";
import { InvestmentExpensesService } from "../../services/investment-expenses/investment-expenses.service";
import { CostGroupsService } from "../../services/cost-groups/cost-groups.service";
import { Step1Component } from "./step1.component";
import { CompanyToCompareStore, Step2Component, Step2StoreValue } from "./step2.component";
import { Step3Component, Step3Value } from "./step3.component";
import { CostGroupColumnValue } from "./../../templates/cost-group-fields/cost-group-fields.component";
import { HealthInsuranceValue } from "./../../templates/health-insurance-fields/health-insurance-fields.component";

@Component({
  selector: "app-analysis-analyzer",
  template: "Not implemented",
})
export abstract class AnalysisComponent implements OnInit, AfterViewInit {
  protected step1Component!: Step1Component;
  protected step2Component!: Step2Component;
  protected step3Component!: Step3Component;

  private readonly maxYears = 5;

  isGeneratingReport = false;

  analysisForm = new UntypedFormGroup({});
  years: number[] = [];
  private coverageIds: (
    | "lossOfEarningCapacity"
    | "disabilityLumpSum"
    | "criticalIllness"
    | "death"
    | "orphanPension"
    | "healthInsurance"
  )[] = [
    "lossOfEarningCapacity",
    "disabilityLumpSum",
    "criticalIllness",
    "death",
    "orphanPension",
    "healthInsurance",
  ];

  Step = Step;
  yearCount = 0;

  isHealthInsurancePresent = false;
  employerName: string | undefined;

  analysisArgs: AnalysisArgs;
  variant: Variant;

  constructor(
    public analysis: AnalysisService,
    public authService: AuthService,
    public costGroupsService: CostGroupsService,
    public customerService: CustomerService,
    public fileSaver: FileSaverService,
    public builder: UntypedFormBuilder,
    public investmentExpensesService: InvestmentExpensesService,
    public router: Router,
    public toastrService: NbToastrService,
    public yieldYearsService: YieldYearsService
  ) {
    void this.setYears();
    const args = this.router.getCurrentNavigation()?.extras.state as AnalysisArgs;
    this.analysisArgs = args;
    this.variant = this.authService.getAssociatedCompany();
  }

  private async setYears() {
    this.years = await this.yieldYearsService.getYieldYears();
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.analysisForm = this.builder.group({
        step1: this.step1Component.step1Form,
        step2: this.step2Component.step2Form,
        step3: this.step3Component.step3Form,
      });

      if (this.analysisArgs) this.setAnalysisArguments(this.analysisArgs);

      this.step1IsHealthInsurancePresent.valueChanges.subscribe(() => {
        this.isHealthInsurancePresent = this.step1IsHealthInsurancePresent.value;
      });

      this.step1Component.information
        .get("employerName")
        ?.valueChanges.subscribe((name) => (this.employerName = name));
    });
  }

  async getAnalysisArguments(): Promise<AnalysisInput> {
    const step1Value = this.step1Component.step1Value;
    const step2Value = this.step2Component.step2Value;
    const step3Value = this.step3Component.step3Value;
    const factory = new AnalysisFactory(
      step1Value,
      step2Value,
      step3Value,
      this.investmentExpensesService,
      this.costGroupsService,
      this.yieldYearsService
    );

    return await factory.createAnalysisArguments(this.variant);
  }

  async downloadReports(): Promise<void> {
    this.isGeneratingReport = true;
    try {
      const analysisArguments = await this.getAnalysisArguments();
      const reportPromises = await this.analysis.getAnalysisReports(
        this.variant.analysisType,
        analysisArguments
      );
      for (const reportPromise of reportPromises) {
        const report = await reportPromise;
        if (!report.file) {
          continue;
        }
        this.fileSaver.save(report.file, report.metadata.fileName, report.metadata.fileType);
      }

      const step1Value = this.step1Component.step1Value;
      const step2Value = this.step2Component.step2Value;
      const step3Value = this.step3Component.step3Value;
      if (step3Value.saveAnalysis) {
        const args: AnalysisArgs = {
          step1: {
            currentCosts: {
              costGroupId: step1Value.currentCosts.costGroupId,
              basicCosts: step1Value.currentCosts.basicCosts,
              healthInsurance: step1Value.currentCosts.healthInsurance,
              investmentMappings: step1Value.currentCosts.investmentCosts.reduce(
                (mapping, cost) => {
                  if (cost.chooseProfile) mapping[cost.header] = cost.chooseProfile;
                  return mapping;
                },
                {} as InvestmentProfileMapping
              ),
            },
            information: {
              pensionCompanyId: step1Value.information.pensionCompanyId,
              employerName: step1Value.information.employerName,
            },
          },
          step2: {
            companiesToCompare: step2Value.companiesToCompare.map((c) => ({
              pensionCompanyId: c.pensionCompanyId,
              costGroupId: c.costGroupId,
              basicCosts: c.basicCosts,
              investmentProfileMappings: c.investmentProfileInfos.reduce((mapping, profile) => {
                mapping[profile.match] = profile.investmentProfileId;
                return mapping;
              }, {} as InvestmentProfileMapping),
              healthInsurance: c.healthInsurance,
            })) as [CompanyToCompareStore],
          },
          step3: step3Value,
        };
        const jsonArgs = JSON.stringify(args);
        const customerAnalysisInput = {
          name: step3Value.analysisSaveName,
          employeeFileName: this.step1Component.textToDisplayInputControl.value,
          companyEmployeeCount: analysisArguments.companyData.numberOfEmployees,
          arguments: jsonArgs,
        };
        const result = await this.customerService.saveAnalysis(customerAnalysisInput);
        if (result.saveSuccessfull)
          this.toastrService.success("", `'${step3Value.analysisSaveName}' blev gemt`);
        else this.toastrService.danger("", "Analysen kunne ikke gemmes");
      }
    } catch (error) {
      this.toastrService.show("Rapporten kunne ikke dannes", "Fejl", { status: "danger" });
    } finally {
      this.isGeneratingReport = false;
    }
  }

  get companiesToCompareValues(): UntypedFormArray {
    return this.analysisForm.get("step2.companiesToCompare")?.value as UntypedFormArray;
  }

  get step1IsHealthInsurancePresent(): UntypedFormControl {
    return this.getStep(Step.One).get(
      "information.employerData.isHealthInsurancePresent"
    ) as UntypedFormControl;
  }

  getStep(stepType: Step): UntypedFormGroup {
    const stepString = this.getStepFromType(stepType);
    const step = this.analysisForm.get(stepString) as UntypedFormGroup;

    if (step !== null) {
      return step;
    } else {
      return this.builder.group({});
    }
  }

  private setAnalysisArguments(analysisArgs: AnalysisArgs): void {
    this.analysisForm.patchValue({
      step1: analysisArgs.step1,
      step2: analysisArgs.step2,
      step3: analysisArgs.step3,
    });
    this.step1Component.investmentAnalysisArgs = analysisArgs.step1.currentCosts.investmentMappings;
    this.step1Component.healthInsuranceFields.setHealthInsurance(
      analysisArgs.step1.currentCosts.healthInsurance
    );
    const isHealthInsurancePresent = !!analysisArgs.step1.currentCosts.healthInsurance;
    this.step1Component.employerData.patchValue({ isHealthInsurancePresent });
    this.isHealthInsurancePresent = isHealthInsurancePresent;

    this.step1Component.savedInvestmentProfiles.subscribe((investmentGroups) => {
      const investmentProfileMappings = analysisArgs.step2.companiesToCompare.map(
        (c) => c.investmentProfileMappings
      );
      this.step2Component.setInvestmentProfiles(investmentGroups, investmentProfileMappings);
    });

    analysisArgs.step2.companiesToCompare.forEach((c, i) => {
      if (i === 0) {
        this.step2Component.setHealthInsurance(c.healthInsurance, i);
      } else {
        this.step2Component.addCompanyToCompare(c, i);
      }
    });

    analysisArgs.step2.companiesToCompare.forEach(async (c, i) => {
      await this.step2Component.setDropdownOptionsNoClear(c.pensionCompanyId, i);
    });
    const pensionCompanyIds = analysisArgs.step2.companiesToCompare.map((c) => c.pensionCompanyId);
    this.step3Component.setCompanyOptions(pensionCompanyIds);
  }

  private getStepFromType(stepType: Step): string {
    switch (stepType) {
      case Step.One:
        return "step1";
      case Step.Two:
        return "step2";
      case Step.Three:
        return "step3";
      default:
        throw new Error(`Step '${stepType}' is not known`);
    }
  }

  getYearCount(): number | null {
    const steps: YearCounter[] = [this.step1Component, this.step2Component];
    const yearCounts: number[] = steps
      .map((step) => step?.getYearCount())
      .filter((yearCount): yearCount is number => Number.isFinite(yearCount));
    return yearCounts.length > 0 ? Math.min(...yearCounts, this.maxYears) : null;
  }

  yearCountChanged(): void {
    this.yearCount = this.getYearCount() ?? 0;
  }
}

enum Step {
  One = 0,
  Two = 1,
  Three = 2,
}

interface AnalysisArgs {
  step1: {
    currentCosts: {
      costGroupId: string;
      basicCosts: CostGroupColumnValue;
      healthInsurance: HealthInsuranceValue;
      investmentMappings: InvestmentProfileMapping;
    };
    information: {
      pensionCompanyId: string;
      employerName: string;
    };
  };
  step2: Step2StoreValue;
  step3: Step3Value;
}

export type InvestmentProfileMapping = { [header: string]: string };
