import { AgreementInput } from "./agreement-input";
import { CandidateInput, InvestmentProfileData } from "./candidate-input";
import { CompanyDataInput } from "./company-data-input";
import { CostGroupsService } from "../cost-groups/cost-groups.service";
import { DocumentInput } from "./document-input";
import { EmployeeInput } from "./employee-input";
import { IdObject } from "../../interfaces/id-object";
import { InvestmentExpensesData } from "../../interfaces/investment-expenses-data";
import { InvestmentExpensesService } from "../investment-expenses/investment-expenses.service";
import { InvestmentCostsValue, Step1Value } from "../../pages/analysis/step1.component";
import { Step1EmployerData } from "./../../pages/analysis/step1-employer-data";
import { CompanyToCompare, Step2Value } from "../../pages/analysis/step2.component";
import { Step3Value } from "../../pages/analysis/step3.component";
import { SelectionInput } from "./selection-input";
import { HealthInsuranceValue } from "../../templates/health-insurance-fields/health-insurance-fields.component";
import { isProfileValid } from "../../utils/investment-profile-utils";
import { YieldYearsService } from "../yield-years/yield-years.service";

/**
 * Holds the input parameters for the analysis.
 */
export interface AnalysisInput {
  companyData: CompanyDataInput;
  currentAgreement: AgreementInput;
  candidates: CandidateInput[];
  selections: SelectionInput;
  documents: DocumentInput;
}

export abstract class AnalysisInputHelper {
  protected noCompanyId = "00000000-0000-0000-0000-000000000000";
  protected coverageIds: (
    | "lossOfEarningCapacity"
    | "disabilityLumpSum"
    | "criticalIllness"
    | "death"
    | "orphanPension"
    | "healthInsurance"
  )[] = [
    "lossOfEarningCapacity",
    "disabilityLumpSum",
    "criticalIllness",
    "death",
    "orphanPension",
    "healthInsurance",
  ];

  constructor(
    protected step1Value: Step1Value,
    protected step2Value: Step2Value,
    protected step3Value: Step3Value,
    protected investmentExpensesService: InvestmentExpensesService,
    protected costGroupsService: CostGroupsService,
    protected yieldYearsService: YieldYearsService
  ) {}

  public async getAnalysisArguments(): Promise<AnalysisInput> {
    const employerData = this.step1Value.information.employerData;
    const currentCosts = this.step1Value.currentCosts;
    const investmentCosts = currentCosts.investmentCosts;

    const companyData: CompanyDataInput = this.createCompanyData(employerData, investmentCosts);

    const healthInsurance = currentCosts.healthInsurance;
    const pensionCompanyId =
      employerData.pensionCompanyId ?? this.step1Value.information.pensionCompanyId;
    const currentInvestmentExpenses = await this.investmentExpensesService.getInvestmentExpenses(
      pensionCompanyId
    );

    const candidateIds = [];
    for (const id in this.step3Value.companiesInAnalysis) {
      if (this.step3Value.companiesInAnalysis[id]) {
        candidateIds.push(id);
      }
    }

    const year2profile2yieldMap = {} as { [year: number]: { [profileId: string]: number } };
    currentInvestmentExpenses.forEach((expenses) => {
      const investmentProfileName = expenses.investmentProfileName;
      if (!investmentProfileName) {
        return;
      }
      expenses.yields.forEach((yieldInfo) => {
        let profile2yield = year2profile2yieldMap[yieldInfo.year];
        if (!profile2yield) {
          year2profile2yieldMap[yieldInfo.year] = profile2yield = {};
        }
        profile2yield[investmentProfileName] = yieldInfo.yield;
      });
    });

    const nullifiedPensionComapanyId =
      pensionCompanyId !== this.noCompanyId ? pensionCompanyId : undefined;

    const companyIds = [...candidateIds];
    if (pensionCompanyId !== this.noCompanyId) {
      companyIds.push(pensionCompanyId);
    }

    const healthInsuranceCompanyIds = this.step2Value.companiesToCompare
      .map((c) => c.healthInsurance?.company)
      .filter((id) => !!id);
    if (healthInsurance && healthInsurance.company !== this.noCompanyId) {
      healthInsuranceCompanyIds.push(healthInsurance.company);
    }

    const healthInsuranceCompanyCostGroupMap =
      await this.costGroupsService.fetchCostGroupsByCompany(
        "healthInsurance",
        healthInsuranceCompanyIds.map((c) => ({ id: c, label: "" }))
      );

    const years = await this.yieldYearsService.getYieldYears();
    const currentAgreement: AgreementInput = this.createCurrentAgreement(
      nullifiedPensionComapanyId,
      healthInsurance,
      healthInsuranceCompanyCostGroupMap,
      investmentCosts,
      currentInvestmentExpenses,
      years
    );

    const candidates: CandidateInput[] = await this.createCandidates(
      healthInsuranceCompanyCostGroupMap,
      investmentCosts
    );
    const selections: SelectionInput = this.createSelection(candidateIds);
    const documents = this.createDocumentsSelection();
    return {
      companyData,
      currentAgreement,
      candidates,
      selections,
      documents,
    };
  }

  protected createCompanyData(
    employerData: Step1EmployerData,
    investmentCosts: InvestmentCostsValue[]
  ): CompanyDataInput {
    return {
      employees: employerData.employees.map((e) => e as EmployeeInput),
      numberOfEmployees: employerData.numberOfEmployees,
      ageCount: employerData.ageCount ?? employerData.numberOfEmployees,
      ageTotal: employerData.ageTotal,
      lossOfEarningCapacityCount:
        employerData.lossOfEarningCapacityCount ?? employerData.numberOfEmployees,
      lossOfEarningCapacityTotal: employerData.lossOfEarningCapacityTotal ?? 0,
      disabilityLumpSumCount: employerData.disabilityLumpSumCount ?? employerData.numberOfEmployees,
      disabilityLumpSumTotal: employerData.disabilityLumpSumTotal ?? 0,
      criticalIllnessCount: employerData.criticalIllnessCount ?? employerData.numberOfEmployees,
      criticalIllnessTotal: employerData.criticalIllnessTotal ?? 0,
      aumCount: employerData.aumCount ?? employerData.numberOfEmployees,
      aumTotal:
        employerData.aumTotal ?? investmentCosts.reduce((sum, cost) => sum + (cost.aum ?? 0), 0),
      annualSalaryCount: employerData.annualSalaryCount ?? employerData.numberOfEmployees,
      annualSalaryTotal:
        employerData.annualSalaryTotal ??
        employerData.numberOfEmployees * employerData.annualSalaryMean,
      annualSalaryMean: employerData.annualSalaryMean,
      annualPremiumCount: employerData.annualPremiumCount ?? employerData.numberOfEmployees,
      annualPremiumTotal:
        employerData.annualPremiumTotal ??
        investmentCosts.reduce((sum, cost) => sum + (cost.annualPremium ?? 0), 0),
      deathCount: employerData.deathCount ?? employerData.numberOfEmployees,
      deathTotal: employerData.deathTotal ?? 0,
      orphansPensionCount: employerData.orphansPensionCount ?? employerData.numberOfEmployees,
      orphansPensionTotal: employerData.orphansPensionTotal ?? 0,
      isHealthInsurancePresent: employerData.isHealthInsurancePresent,
    };
  }

  protected async createCandidates(
    healthInsuranceCompanyCostGroupMap: { [key: string]: IdObject[] },
    investmentCosts: InvestmentCostsValue[]
  ): Promise<CandidateInput[]> {
    return await Promise.all(
      this.step2Value.companiesToCompare?.map(async (company) => {
        const candidateRiskCoverage = company.basicCosts.riskCoverage;
        const candidateHealthInsurance = company.healthInsurance;
        const candidateCosts = company.basicCosts.costs;
        const healthInsurance = candidateHealthInsurance
          ? {
              companyId: candidateHealthInsurance.company,
              costGroupId: candidateHealthInsurance.costGroupId,
              costGroupName:
                healthInsuranceCompanyCostGroupMap[candidateHealthInsurance.company].find(
                  (g) => g.id === candidateHealthInsurance.costGroupId
                )?.label ?? "",
              cost: candidateHealthInsurance.cost,
            }
          : undefined;
        return {
          id: company.pensionCompanyId,
          costGroupId: company.costGroupId,
          costGroup: {
            lossOfEarningCapacity: candidateRiskCoverage.lossOfEarningCapacity,
            resourceCourseHandoutDkk: candidateRiskCoverage.resourceCourseHandoutDkk ?? 0,
            resourceCourseHandoutPercent: candidateRiskCoverage.resourceCourseHandoutPercent ?? 0,
            disabilityLumpSum: candidateRiskCoverage.disabilityLumpSum,
            waiverOfPremium: candidateRiskCoverage.waiverOfPremium,
            criticalIllness: candidateRiskCoverage.criticalIllness,
            death: candidateRiskCoverage.death,
            orphanPension: candidateRiskCoverage.orphanPension,
            administrationFee: candidateCosts.administrationFee,
            administrationFeeOfPremiumStaircase: candidateCosts.stairCasePremium
              .filter(
                (step) => Number.isFinite(step.lowerLimit) && Number.isFinite(step.percentage)
              )
              .map((step) => ({
                start: step.lowerLimit,
                value: step.percentage,
              })),
            administrationFeeOfAuMStaircase: candidateCosts.stairCaseAuM
              .filter(
                (step) => Number.isFinite(step.lowerLimit) && Number.isFinite(step.percentage)
              )
              .map((step) => ({
                start: step.lowerLimit,
                value: step.percentage,
              })),
            brokerFee: candidateCosts.brokerFee,
            ongoingCompensation: candidateCosts.ongoingCompensation ?? 0,
            brokerFeeOfAuM: candidateCosts.brokerFeeOfAuM,
            establishmentFee: candidateCosts.establishmentFee,
            establishmentFeeDiscount: candidateCosts.establishmentFeeDiscount,
          },
          healthInsurance,
          investmentProfiles: this.createInvestmentProfileData(company, investmentCosts),
        };
      })
    );
  }

  private createInvestmentProfileData(
    company: CompanyToCompare,
    investmentCosts: InvestmentCostsValue[]
  ): InvestmentProfileData[] {
    return company.investmentProfileInfos
      .map((profileInfo) => {
        const matchedCurrentCost = investmentCosts.find(
          (c) => c.investmentProfileName === profileInfo.match || c.header === profileInfo.match
        );

        if (!matchedCurrentCost || !isProfileValid(matchedCurrentCost)) return undefined;

        return {
          investmentProfileHeader: profileInfo.investmentProfileName,
          investmentProfileName: profileInfo.investmentProfileName,
          investmentExpenses: profileInfo.investmentExpenses,
          yields: profileInfo.yields.slice(0, this.step3Value.yearsInAnalysis).map((y) => ({
            year: y.year,
            yield: y.yield,
          })),
          annualPremium: matchedCurrentCost.annualPremium,
          aum: matchedCurrentCost.aum,
          employeeCount: matchedCurrentCost.employeeCount,
        };
      })
      .filter((profile) => !!profile) as InvestmentProfileData[];
  }

  protected createCurrentAgreement(
    nullifiedPensionComapanyId: string | undefined,
    healthInsurance: HealthInsuranceValue,
    healthInsuranceCompanyCostGroupMap: {
      [key: string]: IdObject[];
    },
    investmentCosts: InvestmentCostsValue[],
    currentInvestmentExpenses: InvestmentExpensesData[],
    years: number[]
  ): AgreementInput {
    const currentCosts = this.step1Value.currentCosts;
    const basicCosts = currentCosts.basicCosts;
    const costs = basicCosts?.costs;
    const riskCoverage = basicCosts?.riskCoverage;
    const agreementHealthCostGroupName = healthInsurance
      ? healthInsuranceCompanyCostGroupMap[healthInsurance.company].find(
          (g) => g.id === healthInsurance.costGroupId
        )?.label ?? ""
      : "";
    return {
      pensionCompanyId: nullifiedPensionComapanyId,
      costGroupId: this.step1Value.currentCosts.costGroupId,
      employerName: this.step1Value.information.employerName,
      costGroup:
        riskCoverage && costs
          ? {
              lossOfEarningCapacity: riskCoverage.lossOfEarningCapacity,
              resourceCourseHandoutDkk: riskCoverage.resourceCourseHandoutDkk ?? 0,
              resourceCourseHandoutPercent: riskCoverage.resourceCourseHandoutPercent ?? 0,
              disabilityLumpSum: riskCoverage.disabilityLumpSum,
              waiverOfPremium: riskCoverage.waiverOfPremium,
              criticalIllness: riskCoverage.criticalIllness,
              death: riskCoverage.death,
              orphanPension: riskCoverage.orphanPension,
              administrationFee: costs.administrationFee,
              administrationFeeOfAuMStaircase: costs.stairCaseAuM
                .filter(
                  (step) => Number.isFinite(step.lowerLimit) && Number.isFinite(step.percentage)
                )
                .map((step) => ({
                  start: step.lowerLimit,
                  value: step.percentage,
                })),
              administrationFeeOfPremiumStaircase: costs.stairCasePremium
                .filter(
                  (step) => Number.isFinite(step.lowerLimit) && Number.isFinite(step.percentage)
                )
                .map((step) => ({
                  start: step.lowerLimit,
                  value: step.percentage,
                })),
              brokerFee: costs.brokerFee,
              ongoingCompensation: costs.ongoingCompensation ?? 0,
              brokerFeeOfAuM: costs.brokerFeeOfAuM,
              establishmentFee: costs.establishmentFee,
              establishmentFeeDiscount: costs.establishmentFeeDiscount,
            }
          : undefined,
      healthInsurance: healthInsurance
        ? {
            companyId: healthInsurance.company,
            costGroupId: healthInsurance.costGroupId,
            costGroupName: agreementHealthCostGroupName,
            cost: healthInsurance.cost,
          }
        : undefined,
      investmentProfiles: investmentCosts.filter(isProfileValid).map((cost) => ({
        investmentProfileHeader: cost.header,
        investmentProfileName: cost.investmentProfileName,
        investmentExpenses: cost.percentOfDepository ?? 0,
        yields:
          currentInvestmentExpenses
            .find((e) => e.investmentProfileName === cost.investmentProfileName)
            ?.yields.map((y) => ({ year: y.year, yield: y.yield })) ??
          Array(this.step3Value.yearsInAnalysis)
            .fill(0)
            .map((yld: number, i: number) => ({
              year: years[i],
              yield: yld,
            })),
        annualPremium: cost.annualPremium ?? 0,
        aum: cost.aum ?? 0,
        employeeCount: cost.employeeCount,
      })),
    };
  }

  protected createDocumentsSelection(): DocumentInput {
    return {
      doMakeWord: this.step3Value.fileFormat.word,
      doMakePdf: this.step3Value.fileFormat.pdf,
    };
  }

  protected createSelection(candidateIds: string[]): SelectionInput {
    return {
      years: this.step3Value.yearsInAnalysis,
      coverageTypes: this.coverageIds.filter((id) => this.step3Value.coverages[id]),
      candidateIds,
      includeCompareConditions: false,
      doDisplayCandidates: this.step3Value.showCompanies,
    };
  }
}

export class HonestusAnalyzerInputHelper extends AnalysisInputHelper {
  protected createSelection(candidateIds: string[]): SelectionInput {
    const defaultSelectionInput = super.createSelection(candidateIds);
    defaultSelectionInput.includeCompareConditions = this.step3Value.compareConditions;
    const clone = { ...defaultSelectionInput };
    clone.includeCompareConditions = this.step3Value.compareConditions;
    return clone;
  }
}

export class MancofiAnalyzerInputHelper extends AnalysisInputHelper {
  protected createDocumentsSelection(): DocumentInput {
    return {
      doMakeWord: true,
      doMakePdf: false,
    };
  }
}
