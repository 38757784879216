import { ChangeDetectorRef, Component, OnInit } from "@angular/core";

import { YieldYearsService } from "./../../services/yield-years/yield-years.service";

@Component({
  selector: "app-investment-expenses-labels",
  templateUrl: "./investment-expenses-labels.component.html",
  styleUrls: ["./investment-expenses-labels.component.scss"],
})
export class InvestmentExpensesLabelsComponent implements OnInit {
  yieldYears: number[] = [];

  constructor(private ref: ChangeDetectorRef, private yieldYearsService: YieldYearsService) {
    this.setYears();
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  async setYears(): Promise<void> {
    this.yieldYears = await this.yieldYearsService.getYieldYears();
    this.ref.detectChanges();
  }
}
