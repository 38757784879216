import { Injectable } from "@angular/core";
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache";
import { createHttpLink, DocumentNode, NetworkStatus, ApolloClient } from "@apollo/client/core";
import { AuthService } from "../auth/auth.service";
import { MonitoringService } from "../monitoring/monitoring.service";
import { FieldNode, GraphQLError, OperationDefinitionNode } from "graphql";

@Injectable({
  providedIn: "root",
})
export class ApolloInstanceService {
  // The maximum length of the string containing error details transmitted in
  // telemetry. Default value: 8192.
  readonly maxErrorStringLength = 8192;

  // The maximum length of the string containing the GraphQL query transmitted in
  // telemetry. Default value: 2048.
  readonly maxQueryStringLength = 2048;

  // The maximum length of the string containing GraphQL variables transmitted in telemetry.
  // Default value: 4096;
  readonly maxVariableStringLength = 4096;

  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;
  private readonly graphQlHost: string;
  private readonly graphQlPath = "/graphql";
  private readonly graphQlUrl: string;

  constructor(private authService: AuthService, private monitor: MonitoringService) {
    const url = new URL(window.location.href);
    this.graphQlHost = url.host;
    this.graphQlUrl = url.protocol + "//" + this.graphQlHost + this.graphQlPath;

    this.apolloClient = this.getApolloClient();
  }

  async getInstance(): Promise<ApolloClient<NormalizedCacheObject>> {
    return this.apolloClient;
  }

  private getApolloClient(): ApolloClient<NormalizedCacheObject> {
    const authorizedFetch = async (input: URL | RequestInfo, init?: RequestInit): Promise<Response> => {
      const token = await this.authService.getToken();
      if (!init!.headers) {
        init!.headers = new Headers();
      }
      if (init!.headers instanceof Headers) {
        init!.headers.set("Authorization", `Bearer ${token}`);
      } else {
        (init!.headers as { [key: string]: string }).Authorization = `Bearer ${token}`;
      }
      return await fetch(input, init);
    };
    

    const link = createHttpLink({ fetch: authorizedFetch });
    const client = new ApolloClient<NormalizedCacheObject>({ link, cache: new InMemoryCache() });
    client.query = this.executeOperation(
      this,
      client,
      client.query,
      "Query",
      (options) => options.query,
      (result) => result.networkStatus
    );
    client.mutate = this.executeOperation(
      this,
      client,
      client.mutate,
      "Mutation",
      (options) => options.mutation,
      (result) => (result.errors ? NetworkStatus.error : NetworkStatus.ready)
    );

    return client;
  }

  private executeOperation<
    T extends { variables?: { [key: string]: any } },
    TResult extends { errors?: ReadonlyArray<GraphQLError> }
  >(
    _this: ApolloInstanceService,
    client: ApolloClient<NormalizedCacheObject>,
    clientOperation: (options: T) => Promise<TResult>,
    operationType: string,
    getDocumentNode: (x: T) => DocumentNode,
    getResponseCode: (r: TResult) => number
  ) {
    return async (options: T) => {
      // QueryOptions<Record<string, any>> | MutationOptions<any, Record<string, any>>
      const operation = getDocumentNode(options).definitions.find(
        (d) => d.kind === "OperationDefinition"
      ) as OperationDefinitionNode | undefined;
      let operationNameArgs = operation?.name?.value ?? "";
      if ((operation?.variableDefinitions?.length ?? 0) > 0) {
        operationNameArgs += `(${
          (operation as OperationDefinitionNode).variableDefinitions
            ?.map((d) => d.variable.name.value)
            ?.join(", ") ?? ""
        })`;
      }
      if (operationNameArgs !== "") {
        operationNameArgs += " ";
      }
      const selectionNames =
        (
          operation?.selectionSet?.selections?.filter((s) => s.kind === "Field") as FieldNode[]
        )?.map((s) => s.name.value) ?? [];
      let result: TResult;
      let responseCode = -1;
      let success = false;
      const properties: { [p: string]: any } = {};
      let stopTime = -1;
      const startDate = new Date();
      const startTime = performance.now();
      try {
        result = await clientOperation.apply(client, [options]);
        stopTime = performance.now();
        responseCode = getResponseCode(result);
        success = true;
        const errors = result.errors?.map((e) => e.message);
        if (errors?.length && errors.length > 0) {
          success = false;
          _this.setProperties(properties, getDocumentNode, options, errors);
        }
      } catch (exception) {
        stopTime = performance.now();
        responseCode = NetworkStatus.error;
        success = false;
        const typedException = exception as { graphQlErrors: string[]; message: string };
        const errors = typedException.graphQlErrors ?? [typedException.message];
        _this.setProperties(properties, getDocumentNode, options, errors);
        throw exception;
      } finally {
        _this.logDependency(
          operationType,
          operationNameArgs,
          selectionNames,
          success,
          responseCode,
          properties,
          startDate,
          stopTime - startTime
        );
      }
      return result;
    };
  }

  private setProperties<
    T extends { variables?: { [key: string]: any }; context?: { version: string } }
  >(
    properties: { [p: string]: any },
    getDocumentNode: (x: T) => DocumentNode,
    options: T,
    errors: string[]
  ) {
    properties.Query = this.truncate(
      this.maxQueryStringLength,
      getDocumentNode(options).toString()
    );
    const variablesJson = JSON.stringify(options.variables);
    properties.Variables = this.truncate(this.maxVariableStringLength, variablesJson);
    const errorsJson = JSON.stringify(errors);
    properties.ErrorData = this.truncate(this.maxErrorStringLength, errorsJson);
  }

  private logDependency(
    operationType: string,
    operationNameArgs: string,
    selectionNames: string[],
    success: boolean,
    responseCode: number,
    properties: { [p: string]: any },
    startTime: Date,
    durationMs: number
  ) {
    const name = `GRAPHQL ${operationType} ${operationNameArgs}{ ${selectionNames?.join(" ")} }`;
    const id = this.generateId();
    this.monitor.logDependency({
      id,
      target: this.graphQlUrl,
      type: "GRAPHQL",
      name,
      success,
      responseCode,
      startTime,
      duration: durationMs,
      properties,
    });
  }

  private generateId() {
    return "xxxxxxxxxxxxxxxx".replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16));
  }

  private truncate(maxLength: number, s: string) {
    return (s?.length ?? 0) > maxLength ? s?.slice(0, maxLength - 3) + "..." : s;
  }
}
