<form class="grid-wrapper" [formGroup]="step3Form">

  <nb-card class="years-in-analysis">
    <nb-card-header>Periode i analyse</nb-card-header>
    <nb-card-body style="min-width: 110px;">
      <nb-radio-group name="yearsInAnalysis" status="info" formControlName="yearsInAnalysis">
        <nb-radio *ngFor="let i of naturalNumbers(yearCount)" [value]="i">
          {{ i }} år
        </nb-radio>
      </nb-radio-group>
    </nb-card-body>
  </nb-card>

  <nb-card class="coverages">
    <nb-card-header>Dækninger med i analyse</nb-card-header>
    <nb-card-body>
      <div class="flex-column" formGroupName="coverages">
        <nb-checkbox *ngFor="let coverType of coveragesInAnalysis; let i = index" class="checkbox" status="info" formControlName={{coverType.id}}>
          {{ coverType.label }}
        </nb-checkbox>
      </div>
    </nb-card-body>
  </nb-card>

  <nb-card class="companies-in-analysis">
    <nb-card-header>Selskaber med i analyse</nb-card-header>
    <nb-card-body>
      <div class="flex-column" formGroupName="companiesInAnalysis">
        <nb-checkbox *ngFor="let company of companies" class="checkbox" status="info" formControlName={{company.id}}>
          {{ company.label }}
        </nb-checkbox>
      </div>
    </nb-card-body>
  </nb-card>

  <nb-card class="show-companies">
    <nb-card-header>Oplys selskaber</nb-card-header>
    <nb-card-body>
      <nb-radio-group name="showCompanies" status="info" class="flex-column" formControlName="showCompanies">
        <nb-radio [value]=true>Ja</nb-radio>
        <nb-radio [value]=false>Nej</nb-radio>
      </nb-radio-group>
    </nb-card-body>
  </nb-card>

  <nb-card class="save-analysis">
    <nb-card-header>Gem rapport</nb-card-header>
    <nb-card-body>
      <nb-radio-group name="saveAnalysis" status="info" style="display: flex;" formControlName="saveAnalysis">
        <nb-radio [value]=true>Ja</nb-radio>
        <nb-radio [value]=false>Nej</nb-radio>
      </nb-radio-group>
    </nb-card-body>
  </nb-card>

  <nb-card class="analysis-save-name">
    <nb-card-header>Navn på gemt rapport</nb-card-header>
    <nb-card-body>
      <input nbInput shape="semi-round" formControlName="analysisSaveName" style="min-width: 50%; margin: auto; display: flex;">
    </nb-card-body>
  </nb-card>
</form>
