import { RecommendationsData } from "./recommendationsData";
import { Injectable } from "@angular/core";
import { gql } from "@apollo/client/core";
import { ApolloInstanceService } from "../apollo/apollo-instance.service";
import { RecommendationsStepData } from "./recommendationsStepData";

@Injectable({
  providedIn: "root",
})
export class RecommendationsService {
  constructor(private clientService: ApolloInstanceService) {}

  private getRecommendationsQuery = gql`
    query {
      honestusRecommendations {
        __typename
        ... on HonestusRecommendations {
          deathPercentMin
          criticalIllnessMin
          retirementAumYearlySalaryMinFactor
          lossOfEarningCapacity {
            intervalStart
            valueMin
          }
        }
        ... on HonestusError {
          status
          errors
        }
      }
    }
  `;

  private getCalculationParametersQuery = gql`
    query {
      honestusCalculationParameters {
        __typename
        ... on HonestusCalculationParameters {
          typicalRetirementAge
          averageInterest
          averageYieldTax
        }
        ... on HonestusError {
          status
          errors
        }
      }
    }
  `;

  private setRecommendationsMutation = gql`
    mutation RecommendationsMutation($recommendations: HonestusRecommendationsInput!) {
      honestusSetRecommendations(recommendations: $recommendations) {
        status
        errors
      }
    }
  `;

  private setCalculationParametersMutation = gql`
    mutation CalculationParametersMutation($parameters: HonestusCalculationParametersInput!) {
      honestusSetCalculationParameters(parameters: $parameters) {
        status
        errors
      }
    }
  `;

  async getData(): Promise<RecommendationsData> {
    const recommendationsResultData = await this.getRecommendations();
    const calculationParametersResultData = await this.getCalculationParameters();
    const steps = recommendationsResultData?.lossOfEarningCapacity?.map(
      (element) => new RecommendationsStepData(element.intervalStart, element.valueMin)
    );

    return new RecommendationsData(
      recommendationsResultData?.deathPercentMin ?? null,
      recommendationsResultData?.criticalIllnessMin ?? null,
      recommendationsResultData?.retirementAumYearlySalaryMinFactor ?? null,
      calculationParametersResultData?.typicalRetirementAge ?? null,
      calculationParametersResultData?.averageInterest ?? null,
      calculationParametersResultData?.averageYieldTax ?? null,
      steps ?? null
    );
  }

  async getRecommendations(): Promise<HonestusRecommendations | null> {
    const apollo = await this.clientService.getInstance();
    const query = this.getRecommendationsQuery;
    const result = await apollo.query({ query, fetchPolicy: "network-only" });

    if (result.errors) {
      throw new Error("Server request failed: " + result.errors.join("\n"));
    }

    const resultData = (result.data as { honestusRecommendations: HonestusRecommendations })
      .honestusRecommendations;

    if (resultData.__typename === "HonestusError") {
      return null;
    }

    if (resultData.__typename !== "HonestusRecommendations") {
      throw new Error(`GraphQL request failed: ${resultData.errorMessage || "(message missing)"}`);
    }

    return resultData;
  }

  async getCalculationParameters(): Promise<HonestusCalculationParameters | null> {
    const apollo = await this.clientService.getInstance();
    const query = this.getCalculationParametersQuery;
    const result = await apollo.query({ query, fetchPolicy: "network-only" });

    if (result.errors) {
      throw new Error("Server request failed: " + result.errors.join("\n"));
    }
    const resultData = (
      result.data as {
        honestusCalculationParameters: HonestusCalculationParameters;
      }
    ).honestusCalculationParameters;

    if (resultData.__typename === "HonestusError") {
      return null;
    }

    if (resultData.__typename !== "HonestusCalculationParameters") {
      throw new Error(`GraphQL request failed: ${resultData.errorMessage || "(message missing)"}`);
    }

    return resultData;
  }

  async setData(data: RecommendationsData): Promise<void> {
    const lossOfEarningCapacity = data?.lossOfEarningCapacity?.map((step) => ({
      intervalStart: step.intervalStart,
      valueMin: step.valueMin,
    })) as HonestusMinMaxStep[] | null;
    const recommendationsVariables = {
      recommendations: {
        deathPercentMin: data.deathPercentMin,
        criticalIllnessMin: data.criticalIllnessMin,
        retirementAumYearlySalaryMinFactor: data.retirementAumYearlySalaryMinFactor,
        lossOfEarningCapacity,
      },
    };
    await this.setRecommendations(recommendationsVariables);

    const calculationParametersVariables = {
      parameters: {
        typicalRetirementAge: data.typicalRetirementAge,
        averageInterest: data.averageInterest,
        averageYieldTax: data.averageYieldTax,
      },
    };
    await this.setCalculationParameters(calculationParametersVariables);
  }

  async setRecommendations(variables: any): Promise<void> {
    const mutation = this.setRecommendationsMutation;
    const apollo = await this.clientService.getInstance();
    const result = await apollo.mutate({ mutation, variables });

    if (result.errors) {
      throw new Error("Server request failed: " + result.errors.join("\n"));
    }

    const resultData = (result.data as { honestusSetRecommendations: HonestusSetResult })
      .honestusSetRecommendations;

    if (resultData.status !== "OK") {
      throw new Error(
        `GraphQL request failed: ${resultData.status} ${resultData.errors.join(" / ")}.`
      );
    }
  }

  async setCalculationParameters(variables: any): Promise<void> {
    const mutation = this.setCalculationParametersMutation;
    const apollo = await this.clientService.getInstance();
    const result = await apollo.mutate({ mutation, variables });

    if (result.errors) {
      throw new Error("Server request failed: " + result.errors.join("\n"));
    }

    const resultData = (result.data as { honestusSetCalculationParameters: HonestusSetResult })
      .honestusSetCalculationParameters;

    if (resultData.status !== "OK") {
      throw new Error(
        `GraphQL request failed: ${resultData.status} ${resultData.errors.join(" / ")}.`
      );
    }
  }
}

interface HonestusRecommendations {
  __typename: string;
  errorMessage?: string;
  deathPercentMin: number;
  criticalIllnessMin: number;
  retirementAumYearlySalaryMinFactor: number;
  lossOfEarningCapacity: HonestusMinMaxStep[];
}

interface HonestusMinMaxStep {
  intervalStart: number;
  valueMin: number;
}

interface HonestusCalculationParameters {
  __typename: string;
  errorMessage?: string;
  typicalRetirementAge: number;
  averageInterest: number;
  averageYieldTax: number;
}

interface HonestusSetResult {
  errors: string[];
  status: HonestusSetStatus;
}

type HonestusSetStatus = "OK" | "PERMISSIONDENIED";
