import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NbComponentStatus } from "@nebular/theme";

import { InvestmentExpensesData } from "../../interfaces/investment-expenses-data";
import { YieldYearsService } from "../../services/yield-years/yield-years.service";
import { YearCounter } from "../../interfaces/year.counter";

export interface InvestmentExpensesColumnValue {
  expense: number;
  yields: [number];
}

@Component({
  selector: "app-investment-expenses-fields",
  templateUrl: "./investment-expenses-fields.component.html",
  styleUrls: ["./investment-expenses-fields.component.scss"],
})
export class InvestmentExpensesFieldsComponent implements OnInit, YearCounter {
  @Output() yearCountChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() yieldYearsFetched: EventEmitter<{ [key: number]: number }> = new EventEmitter<{
    [key: number]: number;
  }>();

  expenseTooltip = "Tallet skal være positivt";

  investmentExpenses = this.formBuilder.group({
    expense: [null, [Validators.required, Validators.min(0)]],
    yields: this.formBuilder.array([], Validators.required),
  });

  years: number[] = [];
  private yearIndex: { [key: number]: number } = {};
  private yearCount = 0;

  constructor(
    private ref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private yearsService: YieldYearsService
  ) {
    void this.fetchYieldYears();
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  async fetchYieldYears(): Promise<void> {
    this.years = await this.yearsService.getYieldYears();
    this.yearIndex = {};
    this.years.forEach((year, index) => (this.yearIndex[year] = index));
    this.setYieldControls();

    this.registerYieldYears();
    this.yieldYearsFetched.emit();
  }

  registerYieldYears(): void {
    this.investmentExpenses.registerControl("yieldYears", this.formBuilder.array(this.years));
  }

  setExpenseField(data: InvestmentExpensesData): void {
    this.expenseForms.setValue(data.investmentExpenses);
  }

  setUpYields(data: InvestmentExpensesData): void {
    const yields = data.yields;

    for (const yieldObject of yields) {
      const yearIndex = this.yearIndex[yieldObject.year];
      if (Number.isFinite(yearIndex)) {
        this.yieldsForms?.at(yearIndex).setValue(yieldObject.yield);
      }
    }
  }

  setYieldControls(): void {
    this.years.forEach(() => {
      this.yieldsForms.push(this.formBuilder.control(null));
    });
    this.yieldsForms.valueChanges.subscribe(() => this.yieldValuesChanged());
    this.ref.detectChanges();
  }

  setExpenseStatus(): NbComponentStatus {
    const isExpenseValid = this.expenseForms.valid;
    return isExpenseValid ? "info" : "danger";
  }

  setYieldStatus(i: number): NbComponentStatus {
    const isYieldValid = this.yieldsForms.at(i).valid;
    return isYieldValid ? "info" : "danger";
  }

  get yieldsForms(): UntypedFormArray {
    return this.investmentExpenses.get("yields") as UntypedFormArray;
  }

  get expenseForms(): UntypedFormControl {
    return this.investmentExpenses.get("expense") as UntypedFormControl;
  }

  get column(): UntypedFormGroup {
    return this.investmentExpenses;
  }

  getYearCount(): number | null {
    return this.yearCount;
  }

  private yieldValuesChanged() {
    const newYearCount = this.computeYearCount();
    const oldYearCount = this.yearCount;
    if (newYearCount !== oldYearCount) {
      this.yearCount = newYearCount;
      this.yearCountChanged.emit();
    }
  }

  private computeYearCount(): number {
    const yieldList = this.yieldsForms.value as (number | null)[];
    const indexOfFirstBlank = yieldList.findIndex((yld) => !Number.isFinite(yld));
    return indexOfFirstBlank < 0 ? yieldList.length : indexOfFirstBlank;
  }
}
