import { Injectable } from "@angular/core";
import { FetchResult, gql } from "@apollo/client/core";

import { ApolloInstanceService } from "../apollo/apollo-instance.service";
import { HealthInsuranceCostGroupData } from "../../../app/interfaces/health-insurance-cost-group-data";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class HealthInsuranceCostGroupsService {
  constructor(private apollo: ApolloInstanceService, private authService: AuthService) {}

  async getCostGroups(healthInsuranceCompanyId: string): Promise<HealthInsuranceCostGroupData[]> {
    const apollo = await this.apollo.getInstance();

    const query = gql`
      query CompanyDataHealthInsuranceCostGroups($healthInsuranceCompanyId: Guid!) {
        companyDataHealthInsuranceCostGroups(healthInsuranceCompanyId: $healthInsuranceCompanyId) {
          costGroups {
            priority
            costGroupId
            name
            cost
          }
        }
      }
    `;

    const variables = { healthInsuranceCompanyId };
    const result = await apollo.query({ query, variables, fetchPolicy: "network-only" });
    const unSortedCostGroups = result.data.companyDataHealthInsuranceCostGroups.costGroups;

    return this.sortByPriority(unSortedCostGroups);
  }

  async saveCostGroups(
    healthInsuranceCompanyId: string,
    costGroups: HealthInsuranceCostGroupData[]
  ): Promise<FetchResult<any>> {
    const apollo = await this.apollo.getInstance();
    const mutation = gql`
      mutation CompanyDataSetHealthInsuranceCostGroups(
        $healthInsuranceInput: CompanyDataHealthInsuranceInput!
      ) {
        companyDataSetHealthInsuranceCostGroups(healthInsuranceInput: $healthInsuranceInput) {
          status
        }
      }
    `;

    costGroups.map((g, index) => (g.priority = g.priority ?? index));
    const variables = {
      healthInsuranceInput: {
        healthInsuranceCompanyId,
        costGroups,
      },
    };
    const result = await apollo.mutate({ mutation, variables });

    return result;
  }

  private sortByPriority(unSortedArray: HealthInsuranceCostGroupData[]) {
    return [...unSortedArray].sort(
      (obj1: HealthInsuranceCostGroupData, obj2: HealthInsuranceCostGroupData) =>
        obj1.priority - obj2.priority
    );
  }
}
