import { Injectable } from "@angular/core";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { NgxLoggerLevel, NGXLoggerMonitor, NGXLogInterface } from "ngx-logger";

import { MonitoringService } from "./monitoring.service";

@Injectable({
  providedIn: "root",
})
export class AppInsightsLoggerMonitor implements NGXLoggerMonitor {
  constructor(private monitor: MonitoringService) {}

  private severityLevels: { [key in NgxLoggerLevel]: SeverityLevel } = {
    [NgxLoggerLevel.TRACE]: SeverityLevel.Verbose,
    [NgxLoggerLevel.DEBUG]: SeverityLevel.Verbose,
    [NgxLoggerLevel.INFO]: SeverityLevel.Information,
    [NgxLoggerLevel.LOG]: SeverityLevel.Information,
    [NgxLoggerLevel.WARN]: SeverityLevel.Warning,
    [NgxLoggerLevel.ERROR]: SeverityLevel.Error,
    [NgxLoggerLevel.FATAL]: SeverityLevel.Critical,
    [NgxLoggerLevel.OFF]: SeverityLevel.Critical + 1,
  };

  onLog(logObject: NGXLogInterface): void {
    switch (logObject.level) {
      case NgxLoggerLevel.TRACE:
      case NgxLoggerLevel.DEBUG:
      case NgxLoggerLevel.INFO:
      case NgxLoggerLevel.LOG:
      case NgxLoggerLevel.WARN:
        this.monitor.logTrace(this.severityLevels[logObject.level], logObject.message, {
          fileName: logObject.fileName,
          lineNumber: logObject.lineNumber,
        });
        break;
      case NgxLoggerLevel.ERROR:
      case NgxLoggerLevel.FATAL:
        this.monitor.logError(
          undefined,
          {
            message: logObject.message,
            fileName: logObject.fileName,
            lineNumber: logObject.lineNumber,
            additional: logObject.additional,
          },
          this.severityLevels[logObject.level]
        );
        break;
      case NgxLoggerLevel.OFF:
        break;
    }
  }
}
