<div *ngFor="let companyCost of companyCosts" class="row-flex margin box-height">
  <div>{{companyCost.label}}</div>
  <div></div>
  <div class="row-flex">
    <div class="sublabel">{{companyCost.sublabel}}</div>
  </div>
</div>
<div *ngFor="let step of extraStepsInStaircase">
  <div class="sublabel fromTo">{{ step.sublabel }}</div>
</div>
