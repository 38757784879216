import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cost-group-labels",
  templateUrl: "./cost-group-labels.component.html",
  styleUrls: ["./cost-group-labels.component.scss"],
})
export class CostGroupLabelsComponent implements OnInit {
  companyCosts = [
    { label: "Tab af erhvervsevne", sublabel: "40%" },
    { label: "Resourceforløbsydelse", sublabel: "kr." },
    { label: "Resourceforløbsydelse", sublabel: "%" },
    { label: "Invalidesum", sublabel: "100.000 kr." },
    { label: "Præmiefritagelse", sublabel: "10.000 kr." },
    { label: "Kritisk sygdom", sublabel: "100.000 kr." },
    { label: "Død", sublabel: "100%" },
    { label: "Børnepension", sublabel: "10.000 kr." },
    { label: "Mægleromk. gebyr", sublabel: "kr." },
    { label: "Løbende kompensation", sublabel: "kr." },
    { label: "Mægleromk. % af depot", sublabel: "" },
    { label: "Etableringsgebyr ", sublabel: "kr." },
    { label: "Etableringsgebyr rabat", sublabel: "kr." },
    { label: "Administrationsomk. gebyr", sublabel: "kr." },
    { label: "Administrationsomk. % af præmien", sublabel: "fra kr." },
    { label: "", sublabel: "fra kr." },
    { label: "", sublabel: "fra kr." },
    { label: "Administrationsomk. % af depot", sublabel: "fra kr." },
    { label: "", sublabel: "fra kr." },
    { label: "", sublabel: "fra kr." },
  ];

  extraStepsInStaircase: { sublabel: string }[] = [];

  ngOnInit(): void {
    // Nothing to do on init yet
  }
}
