import { FetchResult, gql } from "@apollo/client/core";
import { Injectable } from "@angular/core";

import { AuthService } from "../auth/auth.service";
import { ApolloInstanceService } from "../apollo/apollo-instance.service";
import { InvestmentExpensesData } from "../../interfaces/investment-expenses-data";

@Injectable({
  providedIn: "root",
})
export class InvestmentExpensesService {
  private noCompanyId = "00000000-0000-0000-0000-000000000000";

  constructor(private apollo: ApolloInstanceService, private authService: AuthService) {}

  async getInvestmentExpenses(pensionCompanyId: string): Promise<InvestmentExpensesData[]> {
    if (pensionCompanyId === this.noCompanyId) {
      return [
        {
          investmentProfileId: "00000000-0000-0000-0000-000000000000",
          investmentProfileName: "Investeringsprofiler",
          priority: 0,
          investmentExpenses: 0,
          yields: [],
        },
      ];
    }
    const apollo = await this.apollo.getInstance();

    const query = gql`
      query {
        investmentProfiles(
          pensionCompanyId: "${pensionCompanyId}"
        ) {
            investmentProfileId
            investmentProfileName
            priority
            investmentExpenses
            yields {
              year
              yield
            }
          }
      }`;

    const result = await apollo.query({ query, fetchPolicy: "network-only" });
    const unSortedInvestmentExpenses = result.data.investmentProfiles;

    return this.sortByPriority(unSortedInvestmentExpenses);
  }

  async saveInvestmentExpenses(
    pensionCompanyId: string,
    mutationData: InvestmentExpensesData[]
  ): Promise<FetchResult<any>> {
    const apollo = await this.apollo.getInstance();
    const dataString = this.mutationDataToString(mutationData);

    const mutation = gql`
      mutation {
        createUpdateAndDeleteInvestmentProfiles(
          pensionCompanyId: "${pensionCompanyId}",
          investmentProfiles: [${dataString}]
        )
      }`;

    return await apollo.mutate({ mutation });
  }

  private sortByPriority(unSortedArray: InvestmentExpensesData[]) {
    return [...unSortedArray].sort(
      (obj1: InvestmentExpensesData, obj2: InvestmentExpensesData) => obj1.priority - obj2.priority
    );
  }

  private mutationDataToString(dataObjects: InvestmentExpensesData[]) {
    let dataString = "";
    dataObjects.map((data) => {
      // We need to build the investmentProfileId string here since there needs to be double quotes in the string if the id is not null.
      let investmentProfileId: string;
      data.investmentProfileId
        ? (investmentProfileId = `"${data.investmentProfileId}"`)
        : (investmentProfileId = "null");
      let investmentProfileName: string;
      data.investmentProfileName
        ? (investmentProfileName = `"${data.investmentProfileName}"`)
        : (investmentProfileName = "null");

      const yieldsString = this.getYieldString(data);

      dataString += `{
        investmentProfileId: ${investmentProfileId}
        priority: ${data.priority}
        investmentProfileName: ${investmentProfileName}
        investmentExpenses: ${data.investmentExpenses}
        yields: [${yieldsString}]
      }`;
    });

    return dataString;
  }

  private getYieldString(data: InvestmentExpensesData) {
    let yieldsString = "";
    data.yields.map((yields) => {
      yieldsString += `{ year: ${yields.year}, yield: ${yields.yield} },`;
    });
    return yieldsString;
  }
}
