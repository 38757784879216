import { Injectable } from "@angular/core";
import { FetchResult, gql } from "@apollo/client/core";

import { ApolloInstanceService } from "../apollo/apollo-instance.service";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class YieldYearsService {
  constructor(private apollo: ApolloInstanceService, private authService: AuthService) {}

  async getYieldYears(): Promise<number[]> {
    const apollo = await this.apollo.getInstance();

    const query = gql`
      query {
        yieldYears
      }
    `;

    // Cache disabled since the changed just made by the user
    // won't be fetched without reloading the entire page with cache turned on.
    const result = await apollo.query({ query, fetchPolicy: "network-only" });

    return result.data.yieldYears;
  }

  async saveYieldYears(years: number[]): Promise<FetchResult<any>> {
    const apollo = await this.apollo.getInstance();

    const mutation = gql`
      mutation {
        createUpdateAndDeleteYieldYears(
          years: [${years}]
        )
      }`;

    return await apollo.mutate({ mutation });
  }
}
