import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { gql } from "@apollo/client/core";
import { TransferProgressEvent } from "@azure/core-http";
import { BlobServiceClient } from "@azure/storage-blob";
import JSZip from "jszip";
import { NGXLogger } from "ngx-logger";
import { filter, map, tap } from "rxjs/operators";

import { FileMetadata } from "../analysis/file-metadata";
import { ApolloInstanceService } from "../apollo/apollo-instance.service";

/**
 * Provides functionality for transferring files between client and server.
 */
@Injectable({
  providedIn: "root",
})
export class FileTransfererService {
  /**
   * Constructor for <code>FileTransfererService</code> class.
   *
   * @param apolloInstanceService
   * @param http
   * @param logger
   */
  constructor(
    private apolloInstanceService: ApolloInstanceService,
    private http: HttpClient,
    private logger: NGXLogger
  ) {}

  private static getDownloadQuery(
    queryFunctionName: string,
    parameterTypeNames: { [p: string]: string },
    queryResultTypeName: string,
    queryResultElementName: string,
    queryErrorTypeName: string,
    extraMetadataGql: string,
    extraFileMetadataGql: string = "",
    extraErrorDataGql: string = ""
  ) {
    const queryParameterList = [];
    const functionParameterList = [];
    // tslint:disable-next-line: forin
    for (const parameterName in parameterTypeNames) {
      const parameterTypeName = parameterTypeNames[parameterName];
      queryParameterList.push(`$${parameterName}: ${parameterTypeName}`);
      functionParameterList.push(`${parameterName}: $${parameterName}`);
    }
    const query = gql`
      query FileDownloadQuery(${queryParameterList.join(" ")}) {
        ${queryFunctionName}(${functionParameterList.join(" ")}) {
          __typename
          ... on ${queryResultTypeName} {
            ${queryResultElementName} {
              url
              fileName
              fileType
              ${extraFileMetadataGql}
            }
            ${extraMetadataGql}
          }
          ...on ${queryErrorTypeName} {
            errorMessage
            ${extraErrorDataGql}
          }
        }
      }`;
    return query;
  }

  /**
   * Assert that there are in fact selected either 1 or 2 files and combines the FileList to a single file.
   * @param files The selected files by the user.
   * @returns If one file is selected then the file is simply returned along with the name of the file.
   * Else the two files are zipped and returned along with their combined names.
   */
  private async combineFiles(files: FileList): Promise<[Blob, string]> {
    if (files.length == 0 || files.length > 2)
      throw new Error("Unsupported amount of excels files uploaded, must be either 1 or 2.");

    if (files.length == 1) {
      const file = files[0];
      return [file, file.name];
    }

    const zipper = new JSZip();
    const fileArray = Array.from(files);
    fileArray.forEach((f) => zipper.file(`${f.name}`, f));
    const zip = await zipper.generateAsync({ type: "blob", compression: "STORE" });
    const zipFileName = fileArray.map((f) => f.name).join("|");
    return [zip, zipFileName];
  }

  /**
   * Uploads a file located at a given path using a GraphQL endpoint at the server.
   *
   * @param file  The file to upload.
   * @param graphQlUploadFunction  The top level GraphQL query function that returns a URL to which
   *        the file can be uploaded.
   * @returns A key identifying the file upload.
   */
  async uploadFiles(files: FileList, graphQlUploadFunction: string): Promise<string> {
    const apollo = await this.apolloInstanceService.getInstance();
    const requestUrlQuery = gql`query ${graphQlUploadFunction} { ${graphQlUploadFunction} { key url } }`;
    const urlResult = await apollo.query({ query: requestUrlQuery, fetchPolicy: "network-only" });
    if (urlResult.errors) {
      throw new Error("Server request failed: " + urlResult.errors.join("\n"));
    }
    const uploadLocation = urlResult.data[graphQlUploadFunction] as { key: string; url: string };

    const [file, fileName] = await this.combineFiles(files);

    const url = new URL(uploadLocation.url);
    const serviceClient = new BlobServiceClient(url.protocol + "//" + url.host + url.search);
    const containerName = /\/([^/?]+).*/[Symbol.replace](url.pathname, "$1");
    const blobName = /\/[^/?]+\/([^/?]+).*/[Symbol.replace](url.pathname, "$1");
    const containerClient = serviceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlockBlobClient(blobName);
    const fileSize = file.size;
    const onProgress = (progress: TransferProgressEvent) => {
      const uploadPercentage = Math.round((100 * progress.loadedBytes) / fileSize);
      this.logger.debug(
        "File '{fileName}' is {uploadPercentage}% uploaded.",
        fileName,
        uploadPercentage
      );
    };
    await blobClient.uploadBrowserData(file, { onProgress });
    this.logger.info("Uploaded file '{fileName}'.", fileName);
    return uploadLocation.key;
  }

  /**
   * Sends a GraphQL query to the server to fetch metadata for some files, and starts downloading
   * them.
   *
   * @param queryFunctionName    The name of the GraphQL function to query.
   * @param parameterTypeNames   A map, mapping each function parameter name to its GraphQL type name.
   * @param variables            A map, mapping each query variable name to its value.
   * @param queryResultTypeName  The name of the GraphQL type of the function return success value.
   * @param queryResultElementName  The name of the GraphQL field holding file metadata.
   * @param queryErrorTypeName   The name of the GraphQL type of the function return error value.
   * @param extraMetadataGql     Optional GraphQL query text to include in the function query.
   * @param extraFileMetadataGql Optional GrqphQL query text to include in the file metadata.
   * @param extraErrorDataGql    Optional GraphQL query text to include in the error value.
   * @returns A list with one promise for each file download.
   */
  async startFileDownloads<TFileMetadata extends FileMetadata>(
    queryFunctionName: string,
    parameterTypeNames: { [p: string]: string },
    variables: { [p: string]: any },
    queryResultTypeName: string,
    queryResultElementName: string,
    queryErrorTypeName: string,
    extraMetadataGql: string = "",
    extraFileMetadataGql: string = "",
    extraErrorDataGql: string = ""
  ): Promise<Promise<{ file: Blob | null; metadata: TFileMetadata }>[]> {
    const query = FileTransfererService.getDownloadQuery(
      queryFunctionName,
      parameterTypeNames,
      queryResultTypeName,
      queryResultElementName,
      queryErrorTypeName,
      extraMetadataGql,
      extraFileMetadataGql,
      extraErrorDataGql
    );
    const apollo = await this.apolloInstanceService.getInstance();
    const result = await apollo.query({
      query,
      variables,
      fetchPolicy: "network-only",
    });
    if (result.errors) {
      throw new Error("Server request failed: " + result.errors.join("\n"));
    }
    const resultData = result.data[queryFunctionName];
    if (resultData.__typename !== queryResultTypeName) {
      throw new Error("GraphQL request failed: " + result.data[queryFunctionName]?.errorMessage);
    }
    const filesMetadata = resultData[queryResultElementName] as [TFileMetadata];
    const promises = filesMetadata.map(async (fileMetadata) => {
      const fileName = fileMetadata.fileName;
      const fileType = fileMetadata.fileType;
      const url = fileMetadata.url;
      const result = await this.http
        .get(url, { observe: "events", responseType: "blob", reportProgress: true })
        .pipe(
          this.reportProgress(fileName, url),
          filter((event) => event instanceof HttpResponse),
          map((event) => ({
            blob: (event as HttpResponse<Blob>).body,
            fileName,
            fileType,
          }))
        )
        .toPromise();
      if (!result?.blob) {
        this.logger.error("Nothing returned when downloading file '{fileName}'.", fileName);
      }
      return { file: result.blob, metadata: fileMetadata };
    });
    return promises;
  }

  private reportProgress(fileName: string, url: string) {
    return tap(
      (event: HttpEvent<Blob>) => {
        if (!event) {
          return;
        }
        if (event.type === HttpEventType.DownloadProgress) {
          const progress = event.total
            ? `${Math.round((100 * event.loaded) / event.total)}%`
            : `${event.loaded} bytes`;
          this.logger.debug("Downloaded {progress} of file '{fileName}'.", progress, fileName);
        } else if (event.type === HttpEventType.Response) {
          this.logger.debug("Downloaded file '{fileName}'.", fileName);
        }
      },
      (error) => {
        this.logger.error("Error downloading file from {url}: {error}", url, error);
      },
      () => {
        this.logger.info("Downloaded file '{fileName}'.", fileName);
      }
    );
  }
}
