import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "timeoutText" })
export class TimeoutTextPipe implements PipeTransform {
  transform(timeoutPlusOffset?: number, offset: number = 0): string {
    if (timeoutPlusOffset === undefined) {
      return "Sessionen er ikke ved at time ud";
    }
    let timeoutSec = timeoutPlusOffset - offset;
    if (timeoutSec <= 0) {
      return "Du bliver logget af";
    }
    timeoutSec = Math.round(timeoutSec);
    const minutes = Math.floor(timeoutSec / 60);
    let timeText: string;
    if (minutes >= 5) {
      timeText = this.getMinutesText(minutes);
    } else {
      const seconds = timeoutSec % 60;
      const secondsText = this.getSecondsText(seconds);
      if (minutes >= 1) {
        const minutesText = this.getMinutesText(minutes);
        timeText = `${minutesText} og ${secondsText}`;
      } else {
        timeText = secondsText;
      }
    }
    return `Du bliver logget af om ${timeText}`;
  }

  private getMinutesText(minutes: number) {
    return minutes === 1 ? "1 minut" : `${minutes} minutter`;
  }

  private getSecondsText(seconds: number) {
    return seconds === 1 ? "1 sekund" : `${seconds} sekunder`;
  }
}
