import { Injectable, Type } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveEnd, Router } from "@angular/router";
import {
  ApplicationInsights,
  DistributedTracingModes,
  IDependencyTelemetry,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { AppConfigService } from "../../app-config.service";
import { filter, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MonitoringService {
  private appInsights?: ApplicationInsights;
  private viewPageName = "";

  constructor(appConfigService: AppConfigService, private router: Router) {
    const appConfig = appConfigService.getConfig();
    const instrumentationKey = (appConfig.common.azure.applicationInsights || ({} as any))
      .instrumentationKey;
    if (!instrumentationKey) {
      this.disableMonitoring();
      return;
    }
    const doDisableTelemetry = appConfig.doDisableTelemetry;

    this.appInsights = new ApplicationInsights({
      config: {
        correlationHeaderExcludedDomains: ["b2clogin.com"],
        disableExceptionTracking: false,
        disableFetchTracking: false,
        disableTelemetry: doDisableTelemetry,
        distributedTracingMode: DistributedTracingModes.W3C,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableUnhandledPromiseRejectionTracking: true,
        instrumentationKey,
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((envelope) => {
      const baseData = envelope.baseData;
      if (baseData) {
        if (baseData.type === "Ajax" && MonitoringService.isSockJsCall(baseData.target)) {
          return false;
        }
        if (envelope.name.endsWith("Pageview")) {
          baseData.name = this.viewPageName;
        }
      }
      if (envelope.tags) {
        envelope.tags["ai.cloud.role"] = appConfig.applicationInsights.roleName;
      }
      return true;
    });
    this.appInsights.trackPageView();
    this.subscribePageViews();
  }

  static isSockJsCall(target: string): boolean {
    const url = new URL(target);
    return url.pathname.startsWith("sockjs-node/", 1);
  }

  /**
   * Logs to Appliction Insights that the user has viewed a new page.
   * Explicit calls to this method should not be necessary, because we have set
   * enableAutoRouteTracking = true.
   * Thus, whenever the user selects a new page (i.e., tab), a new operation ID is used for logging
   * to Application Insights.
   * This reduces the number of loggings that are combined into a single operation in
   * Application Insights, making it easier to perform debugging.
   *
   * @param name
   * @param uri
   * @param properties
   */
  logPageView(name: string, uri?: string, properties?: { [key: string]: string }): void {
    this.appInsights?.trackPageView({ name, uri, properties });
  }

  logEvent(name: string, properties?: { [key: string]: string }): void {
    this.appInsights?.trackEvent({ name, properties });
  }

  logError(
    error: Error | undefined,
    properties?: { [key: string]: any },
    severityLevel: SeverityLevel = SeverityLevel.Error
  ): void {
    this.appInsights?.trackException({
      exception: error,
      severityLevel,
      properties,
    });
  }

  logTrace(
    severityLevel: SeverityLevel,
    message: string,
    properties?: { [key: string]: any }
  ): void {
    this.appInsights?.trackTrace({ message, severityLevel, properties });
  }

  logDependency(dependencyTelemetry: IDependencyTelemetry): void {
    this.appInsights?.trackDependencyData(dependencyTelemetry);
  }

  setAuthenticatedUserId(userId: string, accountId?: string): void {
    this.appInsights?.setAuthenticatedUserContext(userId, accountId);
  }

  clearAuthenticatedUserId(): void {
    this.appInsights?.clearAuthenticatedUserContext();
  }

  private disableMonitoring(): void {
    Object.assign(this, new DisabledMonitoringService());
  }

  private subscribePageViews(): void {
    this.router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .pipe(map((event) => event as ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          const name = (activatedComponent as { name: string }).name;
          this.viewPageName = `${name} ${this.getRouteTemplate(event.state.root)}`;
        }
      });
  }

  getActivatedComponent(snapshot: ActivatedRouteSnapshot): string | Type<any> | null {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = "";
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
class DisabledMonitoringService {
  logPageView(name: string, uri?: string, properties?: { [key: string]: string }): void {
    // Do nothing
  }

  logEvent(name: string, properties?: { [key: string]: string }): void {
    // Do nothing
  }

  logError(error: Error, properties?: { [key: string]: string }): void {
    // Do nothing
  }

  setAuthenticatedUserId(userId: string, accountId?: string): void {
    // Do nothing
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars */
