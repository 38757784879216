export const danishCalender = {
  firstDayOfWeek: 0,
  dayNames: ["mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag", "søndag"],
  dayNamesShort: ["man", "tir", "ons", "tor", "fre", "lør", "søn"],
  dayNamesMin: ["ma", "ti", "on", "to", "fr", "lø", "sø"],
  monthNames: [
    "Januar",
    "Februar",
    "Marts",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "December",
  ],
  monthNamesShort: [
    "jan",
    "feb",
    "mar",
    "abr",
    "maj",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ],
  today: "I dag",
  clear: "Ryd",
  weekHeader: "uge",
};
