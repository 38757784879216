import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";

import { AuthService, Company } from "./../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const expectedRole = route.data.expectedRole as Company;
    const associatedCompany = this.authService.getAssociatedCompany(); // should be decided by the URL and if it contains Honestus or not

    if (associatedCompany.name === expectedRole) {
      return true;
    }

    if (associatedCompany.name === Company.Honestus) {
      return this.router.parseUrl("/analysis");
    }

    return this.router.parseUrl("/analysis-a");
  }
}
