import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { LocalDate, nativeJs } from "@js-joda/core";
import { PrimeNGConfig } from "primeng/api";
import { NbDialogRef, NbToastrService } from "@nebular/theme";

import { AppConfigService } from "../../../app-config.service";
import { danishCalender } from "./danish-calender";
import { CustomerService } from "./../../../services/customer/customer.service";
import { AnalysisTableEntry, WinningOffer } from "./../../../services/customer/analysis";

@Component({
  selector: "app-winning-offer",
  templateUrl: "./winning-offer.component.html",
  styleUrls: ["./winning-offer.component.scss"],
})
export class WinningOfferComponent implements OnInit {
  pensionCompanies: { label: string; id: string }[];
  healthInsuranceCompanies: { label: string; id: string }[];
  analysisTableEntry: AnalysisTableEntry | undefined;
  form: UntypedFormGroup = this.fb.group({
    pensionCompanyId: [null, Validators.required],
    riskCompanyId: [null, Validators.required],
    healthInsuranceCompanyId: [null],
    reason: [null],
    valueDate: [null, Validators.required],
  });

  constructor(
    private appConfigService: AppConfigService,
    private customerService: CustomerService,
    private fb: UntypedFormBuilder,
    private dialogRef: NbDialogRef<WinningOfferComponent>,
    private primeNGConfig: PrimeNGConfig,
    private toastrService: NbToastrService
  ) {
    const appConfigCommon = this.appConfigService.getConfig().common;
    this.pensionCompanies = appConfigCommon.pensionCompanies.map((p) => ({
      id: p.id.toLowerCase(),
      label: p.label,
    }));
    this.healthInsuranceCompanies = appConfigCommon.healthInsuranceCompanies;
    this.primeNGConfig.setTranslation(danishCalender);
  }

  ngOnInit(): void {
    if (this.analysisTableEntry?.winningOffer) {
      const dateString = this.analysisTableEntry.winningOffer.valueDate as unknown as string;
      this.form.patchValue({
        pensionCompanyId: this.analysisTableEntry.winningOffer.pensionCompanyId,
        riskCompanyId: this.analysisTableEntry.winningOffer.riskCompanyId,
        healthInsuranceCompanyId: this.analysisTableEntry.winningOffer.healthInsuranceCompanyId,
        reason: this.analysisTableEntry.winningOffer.reason,
        valueDate: new Date(dateString),
      });
    }
  }

  async save(winningOffer: WinningOffer): Promise<void> {
    if (!this.analysisTableEntry) return;
    // The date from the input is not automatically cast to a LocalDate, but the type thinks that it is one.
    const d = winningOffer.valueDate as unknown as Date;
    winningOffer.valueDate = LocalDate.from(nativeJs(d));
    const result = await this.customerService.saveWinningOffer(
      this.analysisTableEntry.id,
      winningOffer
    );
    if (result.saveSuccessfull) {
      this.toastrService.success("", "Effektueret");
      this.analysisTableEntry.winningOffer = { ...winningOffer };
      this.dialogRef.close(this.analysisTableEntry);
    } else this.toastrService.danger("", `Kunne ikke gemme effektuering.`);
  }

  close(): void {
    this.dialogRef.close();
  }
}
