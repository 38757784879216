<div class="grid-wrapper" style="overflow: auto;">

  <nb-card class="file-upload" style="padding: 0;">
    <nb-card-header style="display: flex; justify-content: space-between;">
      <div>Medarbejderbestandsfil</div>
      <button
        [disabled]="fileUploadDisabled" nbButton size="tiny" shape="round"
        [nbSpinner]="isDownloadingTemplates" nbSpinnerSize="small" nbSpinnerStatus="control"
        (click)="downloadTemplates()">
        ⭳ Skabeloner
      </button>
    </nb-card-header>
    <nb-card-body style="overflow: hidden;">
      <div class="row-flex" [formGroup]="fileInputs">
        <button [disabled]="fileUploadDisabled" TabIndex="-1" nbButton shape="round" size="small" status="info">Vælg Fil</button>
        <input TabIndex="-1" type="text" nbInput fullWidth shape="round" placeholder="Filnavn" formControlName="textToDisplayInput">
        <input #employerDataFileInput type="file" nbInput fullWidth shape="round" class="hideFileInput" formControlName="fileInput" (change)="updateFileDisplay($event)">
      </div>
    </nb-card-body>
    <nb-card-footer style="min-width: 270px;">
      <button *ngIf="doDisplayUploadExtraFileButton" TabIndex="-1" nbButton shape="round" size="small" status="info" (click)="chooseExtraFile(employerDataFileInput)">
        Vælg ekstra fil
      </button>
      <button
        [disabled]="uploadButtonDisabled || isUploadingFile" nbButton
        shape="round" size="small" status="info"
        (click)="upload(employerDataFileInput.files, [])"
        style="float: right;"
        [nbSpinner]="isUploadingFile" nbSpinnerSize="medium" nbSpinnerStatus="control">
        Upload fil ⭱
      </button>
    </nb-card-footer>
  </nb-card>

  <nb-card class="current-companies">
    <nb-card-header>Nuværende selskab</nb-card-header>
    <nb-card-body>
      <form [formGroup]="information">
        <nb-radio-group
          [status]="setStatus(ControlType.CurrentCompany)"
          formControlName="pensionCompanyId"
          (valueChange)="setInputAsMultiple($event, employerDataFileInput)">
          <nb-radio *ngFor="let company of companies" [value]="company.id" name="company">
            {{ company.label }}
          </nb-radio>
        </nb-radio-group>
      </form>
    </nb-card-body>
  </nb-card>

  <nb-card class="company-name">
    <nb-card-header>Firma</nb-card-header>
    <nb-card-body>
      <form class="row-flex" [formGroup]="information">
        <div>Firmanavn</div>
        <input
          [status]="setStatus(ControlType.EmployerName)"
          type="text" nbInput fullWidth
          shape="semi-round" formControlName="employerName">
      </form>
    </nb-card-body>
  </nb-card>

  <nb-flip-card [showToggleButton]="false" [flipped]="flipped" class="information" id="information">

    <nb-card-front>
      <nb-card id="informationFront" style="overflow: auto;">
        <nb-card-header>Oplysninger</nb-card-header>
        <nb-card-body>
          <form [formGroup]="employerData">
            <div *ngFor="let item of employerDataDisplayed1" class="row-flex margin-bottom">
              <div>{{item.label}}</div>
              <input
                [status]="setStatus(item.controlType)"
                type="number" nbInput shape="semi-round"
                placeholder={{item.placeholder}}
                formControlName={{item.formControlName}}/>
            </div>
            <div *ngFor="let item of employerDataDisplayed" class="row-flex margin-bottom">
              <div>{{item.label}}</div>
              <input
                [status]="setStatus(item.controlType)"
                type="number" nbInput shape="semi-round"
                placeholder={{item.placeholder}}
                formControlName={{item.formControlName}}/>
            </div>
            <div class="row-flex" style="margin-bottom: 2rem;">
              <div>Sundhedsforsikring</div>
              <nb-radio-group name="healthInsurance" [status]="setStatus(ControlType.IsHealthInsurancePresent)" style="display: flex;" formControlName="isHealthInsurancePresent">
                <nb-radio name="healthInsurance" [value]=true>Ja</nb-radio>
                <nb-radio name="healthInsurance" [value]=false>Nej</nb-radio>
              </nb-radio-group>
            </div>
          </form>

          <div *ngIf="isLoading" class="loading-spinner-margin center-and-middle">
            <app-loading-spinner></app-loading-spinner>
          </div>

          <form *ngIf="!isLoading"  [formGroup]="currentCosts">
            <div *ngIf="investmentProfileDataForms.length > 0" class="margin-bottom"
              style="display: flex; justify-content: flex-end;">
              <div *ngIf="displayChooseInvestmentProfile" style="min-width: 9rem; margin-right: 12px;">Investerings­profil</div>
              <div style="min-width: 9rem; margin-right: 12px;">Samlet indbetaling (årlig)</div>
              <div>Samlet opsparing</div>
            </div>
            <div formArrayName="investmentCosts" class="margin-bottom"
              *ngFor="let data of investmentProfileDataForms.controls; let i = index">
              <div class="row-flex" style="padding-right: 20px;" [formGroupName]="i">
                <div style="min-width: 24px;">{{data.value.header}}</div>
                <div style="display: flex; justify-content: flex-end;">
                  <nb-select placeholder="Vælg" *ngIf="displayChooseInvestmentProfile"
                    (selectedChange)="setInvestmentProfileNameData($event, i)" shape="semi-round" style="margin-right: 12px;" formControlName="chooseProfile"
                    [status]="setStatus(ControlType.ChooseProfile, i)">
                    <nb-option *ngFor="let investmentExpensesHeader of investmentExpensesHeaders"
                      value={{investmentExpensesHeader.id}} [disabled]="yearCounts[investmentExpensesHeader.label] === 0">
                      {{investmentExpensesHeader.label}}
                      </nb-option>
                  </nb-select>
                  <input
                    [status]="setStatus(ControlType.AnnualPremium, i)"
                    type="number" nbInput
                    shape="semi-round" style="width: 9rem; margin-right: 12px;"
                    formControlName="annualPremium">
                  <input
                    [status]="setStatus(ControlType.Aum, i)"
                    type="number" nbInput
                    shape="semi-round" style="width: 9rem;"
                    formControlName="aum">
                </div>
              </div>
            </div>
          </form>
        </nb-card-body>
        <nb-card-footer *ngIf="showWarningFlipper" >
          <nb-icon
            icon="alert-triangle" status="warning"
            [options]="iconOptions"
            class="flip-icon"
            (click)="flipInformation()"></nb-icon>
        </nb-card-footer>
      </nb-card>
    </nb-card-front>

    <nb-card-back>
      <nb-card accent="warning" id="informationBack">
        <nb-card-header>Uregelmæssigheder</nb-card-header>
        <nb-card-body>
          <nb-list>
            <nb-list-item *ngFor="let warningHeader of warningHeaders; let i = index" style="display: flex; flex-direction: column;">
              <b>{{warningHeader}}</b>
              <ul *ngIf="warningData[i] && warningData[i].length > 0" >
                <li *ngFor="let item of warningData[i]">{{item}}</li>
              </ul>
            </nb-list-item>
          </nb-list>
        </nb-card-body>
        <nb-card-footer *ngIf="showWarningFlipper">
          <nb-icon
            icon="alert-triangle" status="warning"
            [options]="iconOptions"
            class="flip-icon"
            (click)="flipInformation()"></nb-icon>
        </nb-card-footer>
      </nb-card>
    </nb-card-back>
  </nb-flip-card>

  <nb-card class="current-costs" style="min-width: 510px; overflow: auto; max-height: 120vh;">
    <nb-card-header>Nuværende priser (årligt)</nb-card-header>
    <form [formGroup]="currentCosts">
      <div style="display: flex; flex-direction: column;">
        <div style="display: flex; flex-direction: row;">
          <nb-card-body style="padding-right: 0; padding-bottom: 0;">
            <div *ngIf="displayChooseCostGroups" class="single-label">Prisgruppe</div>
            <app-cost-group-labels></app-cost-group-labels>
            <app-health-group-labels></app-health-group-labels>
          </nb-card-body>
          <nb-card-body class="custom-body">
            <nb-select placeholder="Vælg gruppe" formControlName="costGroupId" #costGroupsDropdown *ngIf="displayChooseCostGroups" shape="semi-round" style="width: 215px; margin-bottom: 10px;">
              <nb-option *ngFor="let costGroupsHeader of costGroupsHeaders" [value]="costGroupsHeader.id">{{costGroupsHeader.label}}</nb-option>
            </nb-select>

            <app-cost-group-fields></app-cost-group-fields>
            <app-health-insurance-fields [isHealthInsuranceDisabled]="!isHealthInsurancePresent"></app-health-insurance-fields>
          </nb-card-body>
        </div>
        <nb-card-body *ngIf="investmentProfileDataForms.length > 0" style="padding-top: 0;">
          <div class="margin-bottom" >Investeringsomk. % af depotet</div>
          <div formArrayName="investmentCosts" class="margin-bottom" *ngFor="let data of investmentProfileDataForms.controls; let i = index">
            <div class="row-flex" style="padding-right: 20px;" [formGroupName]="i">
              <div>{{data.value.header}}</div>
              <input
                [status]="setStatus(ControlType.PercentOfDepository, i)"
                type="number" nbInput
                shape="semi-round"
                formControlName="percentOfDepository">
            </div>
          </div>
        </nb-card-body>
      </div>
    </form>
  </nb-card>

</div>
