import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NbComponentStatus, NbToastrService } from "@nebular/theme";

import { CostGroupData } from "../../../interfaces/cost-group-data";
import { CostGroupsService } from "../../../services/cost-groups/cost-groups.service";
import { MonitoringService } from "../../../services/monitoring/monitoring.service";
import { TableDataEditor, TypeOfUserMessage } from "../table-data-editor";
import { CostGroupFieldsComponent } from "../../../templates/cost-group-fields/cost-group-fields.component";

@Component({
  selector: "app-cost-groups",
  templateUrl: "./cost-groups.component.html",
  styleUrls: ["./cost-groups.component.scss"],
})
export class CostGroupsComponent extends TableDataEditor implements OnInit {
  @ViewChildren(CostGroupFieldsComponent) costGroupFields?: QueryList<CostGroupFieldsComponent>;

  data: CostGroupData[] = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public ref: ChangeDetectorRef,
    public costGroupsService: CostGroupsService,
    public formBuilder: UntypedFormBuilder,
    public monitoringService: MonitoringService,
    public toastService: NbToastrService
  ) {
    super(activatedRoute, costGroupsService, ref, formBuilder, monitoringService, toastService);
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  setUpData(): void {
    for (let i = 0; i < this.data.length; i++) {
      setTimeout(() => {
        const dataAtIndex = this.data[i];
        this.headersForms.at(i)?.setValue(dataAtIndex.costGroupName);

        this.setRiskCoverageData(dataAtIndex, i);
        this.setCostData(dataAtIndex, i);
        this.setStaircaseData(dataAtIndex, i);
      });
    }
  }

  setRiskCoverageData(data: CostGroupData, i: number): void {
    this.getFieldsArray(i)?.setRiskCoverageData(data);
  }

  setCostData(data: CostGroupData, i: number): void {
    this.getFieldsArray(i)?.setCostData(data);
  }

  setStaircaseData(data: CostGroupData, i: number): void {
    this.getFieldsArray(i)?.setStaircaseData(data);
  }

  async saveData(): Promise<void> {
    this.isSaving = true;
    try {
      const mutationData: CostGroupData[] = [];
      let i = 0;
      this.costGroupFields?.forEach((field) => {
        const stairCasePremium: StairCase[] = field.stairCasePremium.getRawValue();
        const stairCaseAuM: StairCase[] = field.stairCaseAuM.getRawValue();
        const riskCoverageValues: RiskCoverageValues = field.riskCoverage.value;
        const costValues: CostValues = field.costs.value;
  
        // Only one type of resourceCourseHandout can be entered in the GUI. The other one becomes undefined, which the graphql endpoint does not allow, thus we set it to null.
        const resourceCourseHandoutDkk = riskCoverageValues.resourceCourseHandoutDkk !== undefined ? riskCoverageValues.resourceCourseHandoutDkk : null;
        const resourceCourseHandoutPercent = riskCoverageValues.resourceCourseHandoutPercent !== undefined ? riskCoverageValues.resourceCourseHandoutPercent : null;
  
        const data: CostGroupData = {
          costGroupId: this.data[i] ? this.data[i].costGroupId : null,
          costGroupName: this.headersForms.at(i).value,
          priority: i,
          lossOfEarningCapacity: riskCoverageValues.lossOfEarningCapacity,
          resourceCourseHandoutDkk: resourceCourseHandoutDkk,
          resourceCourseHandoutPercent: resourceCourseHandoutPercent,
          disabilityLumpSum: riskCoverageValues.disabilityLumpSum,
          waiverOfPremium: riskCoverageValues.waiverOfPremium,
          criticalIllness: riskCoverageValues.criticalIllness,
          death: riskCoverageValues.death,
          orphanPension: riskCoverageValues.orphanPension,
          brokerFee: costValues.brokerFee,
          ongoingCompensation: costValues.ongoingCompensation,
          brokerFeeOfAuM: costValues.brokerFeeOfAuM,
          establishmentFee: costValues.establishmentFee,
          establishmentFeeDiscount: costValues.establishmentFeeDiscount,
          administrationFee: costValues.administrationFee,
          administrationFeeLimitOfPremium2: stairCasePremium[1].lowerLimit,
          administrationFeeLimitOfPremium3: stairCasePremium[2].lowerLimit,
          administrationFeeOfPremium1: stairCasePremium[0].percentage,
          administrationFeeOfPremium2: stairCasePremium[1].percentage,
          administrationFeeOfPremium3: stairCasePremium[2].percentage,
          administrationFeeLimitOfAuM2: stairCaseAuM[1].lowerLimit,
          administrationFeeLimitOfAuM3: stairCaseAuM[2].lowerLimit,
          administrationFeeOfAuM1: stairCaseAuM[0].percentage,
          administrationFeeOfAuM2: stairCaseAuM[1].percentage,
          administrationFeeOfAuM3: stairCaseAuM[2].percentage,
        };
  
        mutationData.push(data);
        i++;
      });
  
      await this.costGroupsService.saveCostGroups(this.pensionCompanyId, mutationData);
  
      this.setUserMessage(TypeOfUserMessage.ConfirmSave);
    } catch (Error) {
      this.setUserMessage(TypeOfUserMessage.SaveError);
    } finally {
      this.isSaving = false;
    }
  }

  getFieldsArray(i: number): CostGroupFieldsComponent | undefined {
    return this.costGroupFields?.toArray()[i];
  }

  get isColumnsValid(): boolean {
    const costGroupsFieldsArray = this.costGroupFields?.toArray();
    if (costGroupsFieldsArray !== undefined) {
      const allFieldsAreValid = costGroupsFieldsArray.every((costGroup) => costGroup.column.valid);
      return allFieldsAreValid && this.headersForms.valid;
    } else {
      return false;
    }
  }

  setStatusHeader(i: number): NbComponentStatus {
    return this.headersForms.at(i).valid ? "info" : "danger";
  }
}

interface RiskCoverageValues {
  lossOfEarningCapacity: number;
  resourceCourseHandoutDkk?: number;
  resourceCourseHandoutPercent?: number;
  disabilityLumpSum: number;
  waiverOfPremium: number;
  criticalIllness: number;
  death: number;
  orphanPension: number;
  healthInsurance: number;
  healthInsuranceCompany: string;
}

interface CostValues {
  brokerFee: number;
  ongoingCompensation?: number;
  brokerFeeOfAuM: number;
  establishmentFee: number;
  establishmentFeeDiscount: number;
  administrationFee: number;
}

interface StairCase {
  lowerLimit: number;
  percentage: number;
}
