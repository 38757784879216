import { Injectable } from "@angular/core";
import { ApolloQueryResult, gql } from "@apollo/client/core";

import { Alias } from "./alias";
import { FileMetadata } from "../analysis/file-metadata";
import { ApolloInstanceService } from "../apollo/apollo-instance.service";
import { EmployerData } from "./employerData";
import { FileParseError } from "./file-parse-error/file-parse-error";
import { FileTransfererService } from "./../file-transferrer/file-transferer.service";

@Injectable({
  providedIn: "root",
})
export class EmployerFileParserService {
  constructor(
    private clientService: ApolloInstanceService,
    private fileTransferer: FileTransfererService
  ) {}

  async getEmployerData(
    key: string,
    companyId: string,
    aliases: Alias[]
  ): Promise<EmployerData | FileParseError> {
    const employerDataQuery = gql`
      query CompanyDataQuery($key: Guid!, $aliases: [AliasInput]!, $pensionCompanyId: String!) {
        parserGetCompanyData(key: $key, aliases: $aliases, pensionCompanyId: $pensionCompanyId) {
          __typename
          ... on CompanyData {
            suggestions {
              expectedHeaderName
              suggestions {
                suggestedHeaderName
                exampleValues
              }
            }
            missingCoverages {
              deathPctOfSalaryNames
              criticalSicknessNames
              lossOfEarningCapacityNames
            }
            aliases {
              key
              coverageAlias {
                suggestion
                setZero
              }
            }
            pensionCompanyId
            numberOfEmployees
            ageCount
            ageTotal
            annualSalaryCount
            annualSalaryTotal
            annualPremiumCount
            annualPremiumTotal
            aumCount
            aumTotal
            lossOfEarningCapacityCount
            lossOfEarningCapacityTotal
            disabilityLumpSumCount
            disabilityLumpSumTotal
            criticalIllnessCount
            criticalIllnessTotal
            deathCount
            deathTotal
            investmentGroups {
              investmentProfile
              numberOfEmployees
              annualPremium
              aum
            }
            isHealthInsurancePresent
            orphansPensionCount
            orphansPensionTotal
            warningList {
              uidValue
              annualSalary
              annualPremium
              aum
              investmentProfile
              rowOccurance
              rowNotIncluded
            }

            employees {
              age
              annualSalary
              annualPremium
              aum
              investmentProfile
              lossOfEarningCapacity
              disabilityLumpSum
              criticalIllness
              deathPctOfSalary
              orphansPension
              healthInsurance
            }
          }
          ... on CompanyDataFileParseError {
            suggestions {
              expectedHeaderName
              suggestions {
                suggestedHeaderName
                exampleValues
              }
            }
            missingCoverages {
              deathPctOfSalaryNames
              criticalSicknessNames
              lossOfEarningCapacityNames
            }
            aliases {
              key
              coverageAlias {
                suggestion
                setZero
              }
            }
            companyName
            unexpectedNumberPages {
              actual
              expected
            }
            missingColumnHeadersPage1 {
              ageNames
              salaryNames
              premiumNames
              aumNames
              investmentProfileNames
            }
            missingColumnHeadersPage2 {
              categoryNames
              amountNames
              uidOfEmployeeNames
            }
            missingColumnHeadersRow {
              investmentProfileNames
              categoryNames
              amountNames
              uidOfEmployeeNames
            }
            errorMessage
          }
        }
      }
    `;
    const apollo = await this.clientService.getInstance();
    const variables = {
      key: key,
      aliases: aliases,
      pensionCompanyId: companyId,
    };
    const result: ApolloQueryResult<ParserGetCompanyData> = await apollo.query({
      query: employerDataQuery,
      fetchPolicy: "network-only",
      variables: variables,
    });
    if (result.errors) {
      throw new Error("Server request failed: " + result.errors.join("\n"));
    }
    return result.data.parserGetCompanyData;
  }

  async getTemplates(
    companyId: string
  ): Promise<Promise<{ file: Blob | null; metadata: FileMetadata }>[]> {
    return this.fileTransferer.startFileDownloads(
      "getCompanyTemplates",
      { pensionCompanyId: "String!" },
      { pensionCompanyId: companyId },
      "TemplateFiles",
      "templates",
      "TemplateError"
    );
  }
}

interface ParserGetCompanyData {
  parserGetCompanyData: EmployerData | FileParseError;
}
