import { ChangeDetectorRef } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { NbToastrService } from "@nebular/theme";

import { CostGroupData } from "../../interfaces/cost-group-data";
import { CostGroupsService } from "../../services/cost-groups/cost-groups.service";
import { InvestmentExpensesData } from "../../interfaces/investment-expenses-data";
import { InvestmentExpensesService } from "../../services/investment-expenses/investment-expenses.service";
import { MonitoringService } from "../../services/monitoring/monitoring.service";

export abstract class TableDataEditor {
  group = this.formBuilder.group({
    headers: this.formBuilder.array([]),
  });

  headerTooltip = "Skal være udfyldt";

  data: CostGroupData[] | InvestmentExpensesData[] = [];
  pensionCompanyId = "";
  displayErrorMessage = false;
  errorMessage = "";

  isLoading = false;
  isSaving = false;

  required = [Validators.required];

  constructor(
    public activatedRoute: ActivatedRoute,
    public dataService: CostGroupsService | InvestmentExpensesService,
    public ref: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    public monitoringService: MonitoringService,
    public toastService: NbToastrService
  ) {
    this.activatedRoute.paramMap.subscribe(async (params: ParamMap) => {
      this.isLoading = true;
      const company = params.get("companyId");

      if (company !== null) {
        this.pensionCompanyId = company;

        try {
          if (this.dataService instanceof CostGroupsService) {
            this.data = await this.dataService.getCostGroups(company);
          } else {
            this.data = await this.dataService.getInvestmentExpenses(company);
          }

          this.displayErrorMessage = false;
          if (this.data.length === 0) {
            // If there does not exist data in the database from the company, only add one column.
            this.headersForms.clear();
            this.addColumn();
          } else {
            // Else add as many columns to the model as we have in the database.
            this.setUpInitialColumns();
            this.setUpData();
          }
        } catch (error: any) {
          this.displayErrorMessage = true;
          this.setUserMessage(TypeOfUserMessage.GetDataError);
          monitoringService.logError(error);
        } finally {
          this.isLoading = false;
          this.ref.detectChanges();
        }
      }
    });
  }

  abstract setUpData(): void;

  abstract saveData(): Promise<void>;

  setUpInitialColumns(): void {
    this.headersForms.clear();
    this.data.forEach(() => {
      this.addColumn();
    });
  }

  addColumn(): void {
    this.headersForms.push(this.formBuilder.control(null, this.required));
  }

  deleteLastColumn(): void {
    this.headersForms.removeAt(this.headersForms.length - 1);
  }

  get headersForms(): UntypedFormArray {
    return this.group.get("headers") as UntypedFormArray;
  }

  protected setUserMessage(type: TypeOfUserMessage): void {
    switch (type) {
      case TypeOfUserMessage.ConfirmSave:
        this.toastService.success("Data er gemt.");
        break;
      case TypeOfUserMessage.GetDataError:
        this.errorMessage = "Kunne ikke hente data, prøv igen eller kontakt Mancofi.";
        break;
      case TypeOfUserMessage.SaveError:
        this.toastService.danger("Forsøg på at gemme data fejlede.");
        break;
    }
  }
}

export enum TypeOfUserMessage {
  ConfirmSave = 1,
  GetDataError = 2,
  SaveError = 3,
}
