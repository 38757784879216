<nb-card>
  <nb-card-header>Gemte analyser</nb-card-header>
  <nb-card-body>
    <div class="card" *ngIf="analysisEntries">
      <p-table
        #analysisTable
        [value]="analysisEntries"
        [(selection)]="selectedEntry"
        (sortFunction)="customSort($event)"
        [customSort]="true"
        [globalFilterFields]="globalFilterFields"
        [rowHover]="true"
        [paginator]="true"
        paginatorDropdownAppendTo="body"
        [rows]="paginatorOptions[0]"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="paginatorOptions"
        currentPageReportTemplate="{first} til {last} af i alt {totalRecords}"
      >
        <ng-template pTemplate="caption">
          <div style="display: flex; justify-content: flex-end;">
            <nb-form-field status="primary">
              <nb-icon nbPrefix icon="search" pack="eva"></nb-icon>
              <input
                #searchBox
                nbInput
                type="text"
                (input)="search(analysisTable, $event)"
                placeholder="Søgeord"/>
                <button
                  nbButton
                  nbSuffix
                  ghost
                  (click)="clear(analysisTable, searchBox)">
                  <nb-icon nbPrefix icon="close" pack="eva"></nb-icon>
                </button>
            </nb-form-field>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem"></th>
            <th *ngFor="let column of sortableColumn" [pSortableColumn]="column.columnName">
              {{ column.displayHeader }} <p-sortIcon [field]="column.columnName"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-reportEntry>
          <tr>
            <td>
              <p-tableRadioButton [value]="reportEntry"></p-tableRadioButton>
            </td>
            <td (click)="onRowClick(reportEntry)" *ngFor="let item of globalFilterFields" style="cursor: pointer;" >
              {{ reportEntry[item] }}
            </td>
            <td>
              <button nbButton shape="semi-round" status="primary" (click)="delete(reportEntry)">Slet</button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">Kunne ikke finde nogle rapporter.</td>
          </tr>
      </ng-template>
      </p-table>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton shape="semi-round" status="primary" [disabled]="!selectedEntry" (click)="openWinnerWindow(selectedEntry!)" style="margin-right: 20px;">
      Effektuer
    </button>
    <button nbButton shape="semi-round" status="primary" [disabled]="!selectedEntry" (click)="restoreAnalysis(selectedEntry?.arguments)">
      Genskab analyse
    </button>
  </nb-card-footer>
</nb-card>
