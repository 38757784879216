import {
  AnalysisInput,
  HonestusAnalyzerInputHelper,
  MancofiAnalyzerInputHelper,
} from "./analysis-input";
import { Company, Variant } from "./../auth/auth.service";
import { CostGroupsService } from "../cost-groups/cost-groups.service";
import { InvestmentExpensesService } from "../investment-expenses/investment-expenses.service";
import { YieldYearsService } from "../../services/yield-years/yield-years.service";
import { Step2Value } from "./../../pages/analysis/step2.component";
import { Step3Value } from "./../../pages/analysis/step3.component";
import { Step1Value } from "./../../pages/analysis/step1.component";

export class AnalysisFactory {
  constructor(
    private step1Value: Step1Value,
    private step2Value: Step2Value,
    private step3Value: Step3Value,
    private investmentExpensesService: InvestmentExpensesService,
    private costGroupsService: CostGroupsService,
    private yieldYearsService: YieldYearsService
  ) {}

  public async createAnalysisArguments(variant: Variant): Promise<AnalysisInput> {
    if (variant.name === Company.Honestus) {
      const analyzerInputHelper = new HonestusAnalyzerInputHelper(
        this.step1Value,
        this.step2Value,
        this.step3Value,
        this.investmentExpensesService,
        this.costGroupsService,
        this.yieldYearsService
      );
      return analyzerInputHelper.getAnalysisArguments();
    } else if (variant.name === Company.Analyzer) {
      const analyzerInputHelper = new MancofiAnalyzerInputHelper(
        this.step1Value,
        this.step2Value,
        this.step3Value,
        this.investmentExpensesService,
        this.costGroupsService,
        this.yieldYearsService
      );
      return analyzerInputHelper.getAnalysisArguments();
    }
    throw new Error(`No such environment '${variant.name}'`);
  }
}
