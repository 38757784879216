import { Component, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NbToastrService } from "@nebular/theme";
import { FileSaverService } from "ngx-filesaver";

import { AnalysisService } from "../../../services/analysis/analysis.service";
import { Step1HonestusComponent } from "./step-1-honestus/step-1-honestus.component";
import { Step2HonestusComponent } from "./step-2-honestus/step-2-honestus.component";
import { Step3HonestusComponent } from "./step-3-honestus/step-3-honestus.component";
import { CostGroupsService } from "./../../../services/cost-groups/cost-groups.service";
import { CustomerService } from "../../../services/customer/customer.service";
import { YieldYearsService } from "../../../services/yield-years/yield-years.service";
import { InvestmentExpensesService } from "../../../services/investment-expenses/investment-expenses.service";
import { AnalysisComponent } from "../analysis.component";
import { AuthService } from "./../../../services/auth/auth.service";

@Component({
  selector: "app-analysis-honestus",
  templateUrl: "./analysis-honestus.component.html",
  styleUrls: ["./analysis-honestus.component.scss"],
})
export class AnalysisHonestusComponent extends AnalysisComponent {
  @ViewChild(Step1HonestusComponent)
  step1Component!: Step1HonestusComponent;
  @ViewChild(Step2HonestusComponent)
  step2Component!: Step2HonestusComponent;
  @ViewChild(Step3HonestusComponent)
  step3Component!: Step3HonestusComponent;

  constructor(
    analysis: AnalysisService,
    authService: AuthService,
    costGroupsService: CostGroupsService,
    customerService: CustomerService,
    fileSaver: FileSaverService,
    builder: UntypedFormBuilder,
    investmentExpensesService: InvestmentExpensesService,
    router: Router,
    toastrService: NbToastrService,
    yieldYearsService: YieldYearsService
  ) {
    super(
      analysis,
      authService,
      costGroupsService,
      customerService,
      fileSaver,
      builder,
      investmentExpensesService,
      router,
      toastrService,
      yieldYearsService
    );
  }
}
