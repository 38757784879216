<nb-layout>
  <nb-layout-header fixed>
    <div class="logo" (click)="navigateToHome()">
      <img *ngIf="doDisplayHonestusLogo else analyzerLogo"  src="assets/img/Honestus_dark@4x.png" alt="home" />
      <ng-template #analyzerLogo>
        <img src="assets/img/Logo_BrokerAnalyzer_lille.png" alt="home" />
      </ng-template>
    </div>
    <nb-user size="large" name={{userName}}
      [nbContextMenu]="profile"
      nbContextMenuTrigger="hover" nbContextMenuTag="my-profile-menu">
    </nb-user>
  </nb-layout-header>

  <nb-sidebar>
    <nb-menu [items]="items" autoCollapse>
    </nb-menu>
    <div style="height: 8rem"></div>
  </nb-sidebar>

  <nb-layout-column>
    <router-outlet *ngIf="!isIframe"></router-outlet>
    <div style="height: 7rem"></div>
  </nb-layout-column>
  <nb-layout-footer *ngIf="isUserTimeoutCountingDown" fixed>
    <div style="width: 100%; display: flex;">
      <div style="flex-grow: 1;">
        <nb-progress-bar *ngIf="secondsLeft !== undefined" style="flex-grow: 1;"
          [displayValue]="false" [value]="100 * secondsLeft / (secondsLeft > warningThresholdSec ? userIdleTimeoutSec : secondsLeft > dangerThresholdSec ? warningThresholdSec : dangerThresholdSec)"
          [status]="secondsLeft > warningThresholdSec ? 'success' : secondsLeft > dangerThresholdSec ? 'warning' : 'danger'"
          size="giant">
        </nb-progress-bar>
          <p class="timeouttext">{{secondsLeft | timeoutText:0 }}</p>
      </div>
    </div>
  </nb-layout-footer>
</nb-layout>
