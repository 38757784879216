<div *ngIf="isLoading" class="full-screen center-and-middle">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!displayErrorMessage && !isLoading">
  <div style="display: flex; flex-direction: row;">
    <div style="display: flex; flex-direction: column;">
      <button [disabled]="disableAddButton" nbButton size="medium" shape="round" status="info" (click)="addYearField()" style="margin-bottom: 20px; margin-right: 20px;">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
      <button [disabled]="disableDeleteButton" nbButton size="medium" shape="round" status="info" (click)="deleteFirstYearField()" style="margin-right: 20px;">
        <nb-icon icon="minus-outline"></nb-icon>
      </button>
    </div>

    <nb-card style="width: fit-content;">
      <nb-card-header>Indtast år</nb-card-header>
      <nb-card-body>
        <form [formGroup]="yearsGroup">
          <div formArrayName="yieldYears">
            <div *ngFor="let control of yieldYears.controls; let i = index" class="margin-bottom" style="display: flex; align-items: center;">
              <div style="margin-right: 10px;">Afkast i år:</div>
              <input
                nbTooltip={{yearInputTooltip}} nbTooltipPlacement="right" nbTooltipStatus="info"
                type="number" class="year-input" nbInput [status]="setStatus(i)"
                shape="semi-round" max="3000" placeholder="År" [formControlName]="i">
            </div>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
  </div>

  <button
    [disabled]="!yearsGroup.valid || isSaving"
    nbButton (click)="saveYieldYears()"
    status="primary" type="submit" size="giant"
    [nbSpinner]="isSaving" nbSpinnerSize="giant" nbSpinnerStatus="control">
    Gem
  </button>
</div>

<p class="error-message" *ngIf="displayErrorMessage">{{ errorMessage }}</p>
