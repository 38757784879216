import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  NbComponentStatus,
  NbDialogService,
  NbSelectComponent,
  NbToastRef,
  NbToastrService,
} from "@nebular/theme";
import { FileSaverService } from "ngx-filesaver";
import { NGXLogger } from "ngx-logger";

import { InvestmentProfileMapping } from "./analysis.component";
import { AppConfigService } from "../../app-config.service";
import { CostGroupData } from "../../interfaces/cost-group-data";
import { IdObject } from "../../interfaces/id-object";
import { InvestmentExpensesData } from "../../interfaces/investment-expenses-data";
import { YearCounter } from "../../interfaces/year.counter";
import { Alias } from "../../services/company-file-parser/alias";
import { EmployerData } from "./../../services/company-file-parser/employerData";
import { EmployerFileParserService } from "../../services/company-file-parser/employer-file-parser.service";
import { FileParseError } from "./../../services/company-file-parser/file-parse-error/file-parse-error";
import { InvestmentGroup } from "../../services/company-file-parser/investmentGroup";
import { SuggestionColumn } from "../../services/company-file-parser/suggestion-column";
import { CostGroupsService } from "../../services/cost-groups/cost-groups.service";
import { FileTransfererService } from "../../services/file-transferrer/file-transferer.service";
import { InvestmentExpensesService } from "../../services/investment-expenses/investment-expenses.service";
import { MonitoringService } from "../../services/monitoring/monitoring.service";
import { YieldYearsService } from "../../services/yield-years/yield-years.service";
import { Step1EmployerData } from "./step1-employer-data";
import {
  CostGroupColumnValue,
  CostGroupFieldsComponent,
} from "../../templates/cost-group-fields/cost-group-fields.component";
import { ErrorPopUpComponent } from "../../templates/error-pop-up/error-pop-up.component";
import {
  HealthInsuranceFieldsComponent,
  HealthInsuranceValue,
} from "../../templates/health-insurance-fields/health-insurance-fields.component";

export interface Step1Value {
  information: {
    pensionCompanyId: string;
    employerName: string;
    employerData: Step1EmployerData;
  };
  currentCosts: {
    costGroupId: string;
    investmentCosts: InvestmentCostsValue[];
    basicCosts: CostGroupColumnValue;
    healthInsurance: HealthInsuranceValue;
  };
}

export interface InvestmentCostsValue {
  header: string;
  investmentProfileName: string;
  annualPremium: number;
  aum: number;
  employeeCount: number;
  percentOfDepository: number;
  chooseProfile?: string;
}

@Component({
  selector: "app-step-1",
  template: "NotImplemented",
})
export abstract class Step1Component implements OnInit, AfterViewInit, YearCounter {
  // tslint:disable-next-line: variable-name
  private _fileUploadDisabled = true;
  private noCompanyId = "00000000-0000-0000-0000-000000000000";
  private noCompany: IdObject = {
    id: this.noCompanyId,
    label: "Intet",
  };
  private otherCompanyLabel = "Andet";
  private notKnownCompanyIds = [this.noCompanyId];
  private company = "";

  @Input() isHealthInsurancePresent!: boolean;
  @Output() yearCountChanged: EventEmitter<void> = new EventEmitter<void>();
  investmentAnalysisArgs: InvestmentProfileMapping | undefined;
  savedInvestmentProfiles = new EventEmitter<[InvestmentGroup]>();

  ControlType = ControlType;
  companies: IdObject[];
  private yieldYears: number[] = [];
  private yearCount: number | null = null;

  isLoading = false;
  isDownloadingTemplates = false;
  isHealthInsuranceDisabled = false;
  flipped = false;
  hasWarnings = false;
  showWarningFlipper = false;
  iconOptions = {
    animation: { type: "zoom" },
    height: "20px",
    width: "20px",
  };

  positiveNumberValidator = [Validators.required, Validators.min(1)];

  fileInputs: UntypedFormGroup = this.formBuilder.group({
    fileInput: this.formBuilder.control({ value: "", disabled: true }),
    textToDisplayInput: this.formBuilder.control({ value: "", disabled: true }),
  });
  employerData: UntypedFormGroup = this.formBuilder.group({
    pensionCompanyId: null,
    numberOfEmployees: [null, this.positiveNumberValidator],
    ageCount: null,
    ageMean: [null],
    ageTotal: null,
    annualSalaryCount: null,
    annualSalaryMean: [null, this.positiveNumberValidator],
    annualSalaryTotal: null,
    annualPremiumCount: null,
    annualPremiumTotal: null,
    aumCount: null,
    aumTotal: null,
    lossOfEarningCapacityCount: null,
    lossOfEarningCapacityMean: null,
    lossOfEarningCapacityTotal: null,
    disabilityLumpSumCount: null,
    disabilityLumpSumMean: null,
    disabilityLumpSumTotal: null,
    criticalIllnessCount: null,
    criticalIllnessMean: null,
    criticalIllnessTotal: null,
    criticalIllnessChildCount: null,
    criticalIllnessChildMean: null,
    criticalIllnessChildTotal: null,
    deathCount: null,
    deathMean: null,
    deathTotal: null,
    orphansPensionCount: null,
    orphansPensionMean: null,
    orphansPensionTotal: null,
    isHealthInsurancePresent: [null, Validators.required],
    employees: this.formBuilder.array([]),
    warningList: this.formBuilder.array([]),
  });
  information: UntypedFormGroup = this.formBuilder.group({
    pensionCompanyId: [null, Validators.required],
    employerName: [null, Validators.required],
    employerData: this.employerData,
  });

  investmentCosts: UntypedFormArray = this.formBuilder.array([]);
  basicCosts: UntypedFormGroup = this.formBuilder.group({});
  currentCosts: UntypedFormGroup = this.formBuilder.group({
    costGroupId: null,
    investmentCosts: this.investmentCosts,
    basicCosts: this.basicCosts,
  });

  step1Form = this.formBuilder.group({});

  get step1Value(): Step1Value {
    return this.step1Form.value as Step1Value;
  }

  isUploadingFile = false;

  displayChooseCostGroups = true;
  costGroupsHeaders: any[] = [];
  costGroupsData: CostGroupData[] = [];
  displayChooseInvestmentProfile = false;
  investmentExpensesHeaders: IdObject[] = [];
  investmentExpensesData: InvestmentExpensesData[] = [];

  employerDataDisplayed1 = [
    {
      label: "Antal ansatte",
      placeholder: "",
      formControlName: "numberOfEmployees",
      controlType: ControlType.NumberOfEmployees,
    },
    {
      label: "Gennemsnitsårsløn",
      placeholder: "Kr.",
      formControlName: "annualSalaryMean",
      controlType: ControlType.AnnualSalaryMean,
    },
  ];

  employerDataDisplayed = [
    {
      label: "Samlet TAE",
      placeholder: "Kr.",
      formControlName: "lossOfEarningCapacityTotal",
      controlType: ControlType.None,
    },
    {
      label: "Invalidesum",
      placeholder: "Kr.",
      formControlName: "disabilityLumpSumTotal",
      controlType: ControlType.None,
    },
    {
      label: "Samlet kritisk sygdom",
      placeholder: "Kr.",
      formControlName: "criticalIllnessTotal",
      controlType: ControlType.None,
    },
    {
      label: "Samlet død",
      placeholder: "Kr.",
      formControlName: "deathTotal",
      controlType: ControlType.None,
    },
    {
      label: "Børnepension",
      placeholder: "Kr.",
      formControlName: "orphansPensionTotal",
      controlType: ControlType.None,
    },
  ];

  previousToast: NbToastRef | null = null;
  yearCounts: { [investmentProfileName: string]: number } = { null: 0 };
  doDisplayUploadExtraFileButton = false;
  APCompanyId: string | undefined;

  @ViewChild(CostGroupFieldsComponent) costGroupFields!: CostGroupFieldsComponent;
  @ViewChild("costGroupsDropdown") costGroupsDropdown!: NbSelectComponent;
  @ViewChild(HealthInsuranceFieldsComponent) healthInsuranceFields!: HealthInsuranceFieldsComponent;

  warningHeaders: string[] = [];
  warningData: string[][] = [];

  constructor(
    public appConfigService: AppConfigService,
    public ref: ChangeDetectorRef,
    public costGroupsService: CostGroupsService,
    public employerFileParser: EmployerFileParserService,
    public fileSaver: FileSaverService,
    public fileTransferer: FileTransfererService,
    public formBuilder: UntypedFormBuilder,
    public investmentExpensesService: InvestmentExpensesService,
    public monitoring: MonitoringService,
    public dialogService: NbDialogService,
    public toastrService: NbToastrService,
    public logger: NGXLogger,
    public yieldYearsService: YieldYearsService
  ) {
    const pensionCompanies = this.appConfigService.getConfig().common.pensionCompanies;
    this.companies = [...pensionCompanies, this.noCompany];
    const otherCompanyId = pensionCompanies.find((c) => c.label === this.otherCompanyLabel)?.id;
    this.APCompanyId = pensionCompanies.find((c) => c.label === "AP Pension")?.id;
    if (otherCompanyId) {
      this.notKnownCompanyIds.push(otherCompanyId);
    }
    this.yieldYearsService.getYieldYears().then((yieldYears) => {
      this.yieldYears = yieldYears;
    });
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.basicCosts = this.costGroupFields.column;
      this.investmentCosts = this.formBuilder.array([]);
      this.currentCosts = this.formBuilder.group({
        costGroupId: null,
        basicCosts: this.basicCosts,
        investmentCosts: this.investmentCosts,
        healthInsurance: this.healthInsuranceFields.healthInsurance,
      });
      this.step1Form = this.formBuilder.group({
        information: this.information,
        currentCosts: this.currentCosts,
      });
    });

    this.setUpChangeListeners();

    this.setInformationCardHeight();
  }

  async downloadTemplates(): Promise<void> {
    this.isDownloadingTemplates = true;
    try {
      const reportPromises = await this.employerFileParser.getTemplates(this.company);
      for (const reportPromise of reportPromises) {
        const report = await reportPromise;
        if (!report.file) {
          continue;
        }
        this.fileSaver.save(report.file, report.metadata.fileName, report.metadata.fileType);
      }
    } catch (error) {
      const currentCompany = this.companies.find((c) => c.id === this.company);
      this.toastrService.show(
        `Skabelonerne for firma '${currentCompany?.label}' kunne ikke dannes`,
        "Fejl",
        { status: "danger" }
      );
    } finally {
      this.isDownloadingTemplates = false;
    }
  }

  updateFileDisplay(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.adjustFileDisplay(input);
  }

  private adjustFileDisplay(input: HTMLInputElement): void {
    const files = input.files as FileList;
    switch (files.length) {
      case 2:
        this.fileInputChange("2 filer valgt");
        this.doDisplayUploadExtraFileButton = false;
        break;
      case 1:
      case 0:
        this.fileInputChange(files?.item(0)?.name ?? "");
        this.doDisplayUploadExtraFileButton = this.isCompanyAp(this.company) && files.length === 1;
        break;
      default:
        this.doDisplayUploadExtraFileButton = false;
        this.dialogService
          .open(ErrorPopUpComponent, {
            context: { errorMessage: "Du kan max vælge 2 filer." },
          })
          .onClose.subscribe(() => {
            this.fileInputs.reset();
            this.ref.detectChanges();
          });
    }
  }

  setInputAsMultiple(selectedCompany: string, fileInput: HTMLInputElement): void {
    fileInput.multiple = this.isCompanyAp(selectedCompany);
  }

  chooseExtraFile(fileInput: HTMLInputElement): void {
    const currentFile = fileInput.files?.[0] as File;
    fileInput.multiple = false;
    fileInput.click();
    fileInput.onchange = () => {
      if (fileInput.files?.length == 1) {
        const choosenFile = fileInput.files?.[0] as File;
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(currentFile);
        dataTransfer.items.add(choosenFile);
        fileInput.files = dataTransfer.files;
        this.adjustFileDisplay(fileInput);
        this.ref.detectChanges();
      }

      // Reset the input and it's event listeners.
      fileInput.multiple = true;
      fileInput.onchange = () => {
        return;
      };
    };
  }

  setUpChangeListeners(): void {
    this.information.get("pensionCompanyId")?.valueChanges.subscribe((change) => {
      this.currentCompanyChanged(change);
      this.company = change;
    });

    this.costGroupsDropdown.selectedChange.subscribe(() => {
      this.costGroupsData.forEach((data) => {
        this.setCostGroupData(data);
      });
    });
  }

  setCostGroupData(costGroupData: CostGroupData): void {
    if (costGroupData.costGroupId === this.costGroupsDropdown.selected) {
      this.costGroupFields.setRiskCoverageData(costGroupData);
      this.costGroupFields.setCostData(costGroupData);
      this.costGroupFields.setStaircaseData(costGroupData);
    }
  }

  currentCompanyChanged(company: string): void {
    this.isLoading = true;
    if (this.previousToast) {
      this.previousToast.close();
    }

    this.doDisplayUploadExtraFileButton = false;
    this.adjustPageToCompany(company);
    void this.setInvestmentExpensesFields(company).then(() => {
      if (company === this.noCompanyId) {
        this.basicCosts.disable();
        this.investmentCosts.controls.forEach((c) => c.get("percentOfDepository")?.disable());
        this.costGroupsDropdown.setDisabledState(true);
        this.isHealthInsuranceDisabled = true;
      } else {
        this.basicCosts.enable();
        this.costGroupsDropdown.setDisabledState(false);
        if (!this.isHealthInsurancePresent) {
          this.basicCosts.get("healthInsurance")?.disable();
        }
        this.isHealthInsuranceDisabled = false;

        this.checkProfilesForZeroYields(company);
      }
      this.isLoading = false;
    });

    void this.setDropdownOptions(company);
    if (this.costGroupsDropdown) {
      this.costGroupsDropdown.selected = null;
    }

    this.warningHeaders = [];
    this.warningData = [];

    this.clearEmployeeListAndResetData();
  }

  async setDropdownOptions(company: string): Promise<void> {
    this.costGroupsHeaders = [];

    this.costGroupsData = await this.costGroupsService.getCostGroups(company);
    this.costGroupsData.forEach((costGroup) => {
      this.costGroupsHeaders.push({ label: costGroup.costGroupName, id: costGroup.costGroupId });
    });
  }

  adjustPageToCompany(company: string): void {
    this.displayChooseInvestmentProfile = false;
    this.displayChooseCostGroups = true;

    const isNotKnownCompany = this.notKnownCompanyIds.some((id) => id === company);
    if (isNotKnownCompany) {
      this.fileUploadDisabled = true;
    } else {
      this.fileUploadDisabled = false;
    }

    this.flipped = false;
    this.showWarningFlipper = false;
  }

  setInvestmentProfileNameData(investmentProfileId: string, columnIndex: number): void {
    const data = this.investmentExpensesData.find(
      (d) => d.investmentProfileId === investmentProfileId
    );
    if (data) {
      const aumControl = this.getControlFromType(ControlType.Aum, columnIndex);
      const annualPremiumControl = this.getControlFromType(ControlType.AnnualPremium, columnIndex);
      const percentOfDepositoryControl = this.getControlFromType(
        ControlType.PercentOfDepository,
        columnIndex
      );

      if (!this.yearCounts[data.investmentProfileName]) {
        aumControl?.disable();
        annualPremiumControl?.disable();
        percentOfDepositoryControl?.disable();
      } else {
        aumControl?.enable();
        annualPremiumControl?.enable();
        percentOfDepositoryControl?.enable();

        this.investmentProfileDataForms.at(columnIndex).patchValue({
          investmentProfileName: data.investmentProfileName,
          percentOfDepository: data.investmentExpenses,
          chooseProfile: investmentProfileId,
        });
      }
    } else {
      this.toastrService.warning(
        "En investeringsprofil kunne ikke findes, og kan derfor ikke sættes automatisk",
        "Advarsel",
        { duration: 0 }
      );
    }
  }

  async setInvestmentExpensesFields(company: string): Promise<void> {
    this.investmentExpensesHeaders = [];
    this.investmentExpensesData = await this.investmentExpensesService.getInvestmentExpenses(
      company
    );
    const yearCounts = this.computeCompanyYearCounts(this.investmentExpensesData);
    this.yearCounts = yearCounts;
    this.investmentProfileDataForms.clear();
    this.investmentExpensesData.forEach((investmentExpense) => {
      this.addinvestmentProfileData(
        company,
        yearCounts,
        investmentExpense.investmentProfileName,
        investmentExpense.investmentProfileName,
        undefined,
        undefined,
        undefined,
        investmentExpense.investmentExpenses
      );
      if (investmentExpense.investmentProfileId != null) {
        this.investmentExpensesHeaders.push({
          label: investmentExpense.investmentProfileName,
          id: investmentExpense.investmentProfileId,
        });
      } else {
        this.logger.debug(
          "Investment profile {investmentProfileName} from company {company} has no investment profile ID.",
          investmentExpense.investmentProfileName,
          company
        );
      }
    });

    this.ref.detectChanges();
    this.setInformationCardHeight();
  }

  addinvestmentProfileData(
    companyId: string,
    yearCounts: { [p: string]: number },
    headerName: string,
    investmentProfileName: string | null,
    annualPremium?: number,
    aum?: number,
    employeeCount?: number,
    percentOfDepository?: number
  ): void {
    const data = this.formBuilder.group({
      header: headerName,
      investmentProfileName,
      annualPremium,
      aum,
      employeeCount,
      percentOfDepository: [percentOfDepository, [Validators.required, Validators.min(0)]],
      chooseProfile: [null, [this.chooseInvestmentProfileValidator()]],
      yearCount: null,
    });

    this.investmentProfileDataForms.push(data);

    const last = this.investmentProfileDataForms.length - 1;
    const isYieldlessProfileSelected = investmentProfileName && !yearCounts[investmentProfileName];
    if (isYieldlessProfileSelected && companyId !== this.noCompanyId) {
      this.getControlFromType(ControlType.Aum, last)?.disable();
      this.getControlFromType(ControlType.AnnualPremium, last)?.disable();
      this.getControlFromType(ControlType.PercentOfDepository, last)?.disable();
    }

    this.investmentProfileDataForms
      .at(last)
      .get("annualPremium")
      ?.setValidators([this.savingDepositValidator(ControlType.AnnualPremium, last)]);
    this.investmentProfileDataForms
      .at(last)
      .get("aum")
      ?.setValidators([this.savingDepositValidator(ControlType.Aum, last)]);

    data
      .get("investmentProfileName")
      ?.valueChanges.subscribe((newProfileName?: string) =>
        this.adjustYearCount(yearCounts, data, { investmentProfileName: newProfileName })
      );
    data
      .get("aum")
      ?.valueChanges.subscribe((newAuM?: number) =>
        this.adjustYearCount(yearCounts, data, { auM: newAuM })
      );
    data
      .get("yearCount")
      ?.valueChanges.subscribe((_) => setTimeout(() => this.recomputeYearCount()));
  }

  fileInputChange(value: string): void {
    this.textToDisplayInputControl.setValue(value.replace("C:\\fakepath\\", ""));
  }

  get textToDisplayInputControl(): UntypedFormControl {
    return this.fileInputs.get("textToDisplayInput") as UntypedFormControl;
  }
  async upload(files: FileList | null | undefined, aliases: Alias[]): Promise<void> {
    if (!files) {
      this.logger.debug("No file(s) selected for upload.");
      return;
    }

    this.isUploadingFile = true;

    try {
      const key = await this.fileTransferer.uploadFiles(files, "parserGetCompanyDataFileUploadUrl");
      const pensionCompanyId = this.information.value.pensionCompanyId;
      const employerDataResult = await this.employerFileParser.getEmployerData(
        key,
        pensionCompanyId,
        aliases
      );

      if (employerDataResult.__typename !== "CompanyData") {
        const error = employerDataResult as FileParseError;
        this.dialogService
          .open(ErrorPopUpComponent, {
            context: {
              fileParseError: error,
              aliases: error.aliases,
            },
          })
          .onClose.subscribe((aliases) => {
            if (aliases && aliases.length > 0) this.upload(files, aliases);
          });
        this.isUploadingFile = false;
        this.ref.detectChanges();
        return;
      }

      const employerData = employerDataResult as EmployerData;

      const investmentExpenses = await this.investmentExpensesService.getInvestmentExpenses(
        pensionCompanyId
      );
      const yearCounts = this.computeCompanyYearCounts(investmentExpenses);
      this.logger.debug("Got company data: ", employerData);

      const isHealthInsuranceAlreadyPresent =
        this.employerData.get("isHealthInsurancePresent")?.value !== null;
      const pastIsHealthInsurancePresent = this.employerData.get("isHealthInsurancePresent")?.value;

      this.employerData.patchValue(employerData);
      if (isHealthInsuranceAlreadyPresent)
        this.employerData.get("isHealthInsurancePresent")?.setValue(pastIsHealthInsurancePresent);

      const employeesFormArray = this.employerData.get("employees") as UntypedFormArray;
      employeesFormArray.clear();
      employerData.employees.forEach((employee) => {
        const employeeFormGroup = this.formBuilder.group(employee);
        employeeFormGroup.removeControl("__typename");
        employeesFormArray.push(employeeFormGroup);
      });
      this.warningListFormArray.clear();
      employerData.warningList.forEach((w) =>
        this.warningListFormArray.push(this.formBuilder.control(w))
      );
      if (employerData.annualSalaryCount > 0) {
        const annualSalaryMean = employerData.annualSalaryTotal / employerData.annualSalaryCount;
        const ageMean =
          employerData.ageCount > 0 ? employerData.ageTotal / employerData.ageCount : 0;
        const lossOfEarningCapacityMean =
          employerData.lossOfEarningCapacityCount > 0
            ? employerData.lossOfEarningCapacityTotal / employerData.lossOfEarningCapacityCount
            : 0;
        const disabilityLumpSumMean =
          employerData.disabilityLumpSumTotal > 0
            ? employerData.disabilityLumpSumTotal / employerData.disabilityLumpSumCount
            : 0;
        const criticalIllnessMean =
          employerData.criticalIllnessCount > 0
            ? employerData.criticalIllnessTotal / employerData.criticalIllnessCount
            : 0;
        const deathMean =
          employerData.deathCount > 0 ? employerData.deathTotal / employerData.deathCount : 0;
        const orphansPensionMean =
          employerData.orphansPensionCount > 0
            ? employerData.orphansPensionTotal / employerData.orphansPensionCount
            : 0;
        this.employerData.patchValue({
          annualSalaryMean: annualSalaryMean.toFixed(0),
          ageMean: ageMean.toFixed(0),
          lossOfEarningCapacityMean: lossOfEarningCapacityMean.toFixed(2),
          disabilityLumpSumMean: disabilityLumpSumMean.toFixed(2),
          criticalIllnessMean: criticalIllnessMean.toFixed(2),
          criticalIllnessChildMean: 0,
          deathMean: deathMean.toFixed(2),
          orphansPensionMean: orphansPensionMean.toFixed(2),
        });
        this.investmentProfileDataForms.clear();
        employerData.investmentGroups.forEach((investmentGroup) => {
          this.addinvestmentProfileData(
            pensionCompanyId,
            yearCounts,
            investmentGroup.investmentProfile,
            null,
            investmentGroup.annualPremium,
            investmentGroup.aum,
            investmentGroup.numberOfEmployees,
            undefined
          );
        });

        this.setSavedInvestmentProfiles(employerData.investmentGroups);

        this.displayChooseInvestmentProfile = true;
      } else {
        this.logger.debug("Number of employees <= 0");
      }

      this.warningHeaders = [];
      this.warningData = [];
      this.showWarningFlipper = false;
      this.checkProfilesForZeroYields(this.company);
      this.flipped = false;
      this.showUploadedConfirmation(this.warningListFormArray);
      setTimeout(() => this.setInformationCardHeight());

      if (employerDataResult.missingCoverages) {
        const suggestionsMap: { [key: string]: SuggestionColumn[] } = {};
        employerDataResult.suggestions.forEach(
          (s) => (suggestionsMap[s.expectedHeaderName] = s.suggestions)
        );
        this.dialogService
          .open(ErrorPopUpComponent, {
            context: {
              missingCoverages: employerDataResult.missingCoverages,
              suggestionsMap: suggestionsMap,
              aliases: employerDataResult.aliases,
            },
          })
          .onClose.subscribe((aliases) => {
            if (aliases && aliases.length > 0) this.upload(files, aliases);
          });
      }

      this.setWarningMessages();
    } catch (exception) {
      const fileName = Array.from(files)
        .map((f) => f.name)
        .join("|");
      this.logger.error(exception, { fileName });
      this.toastrService.show("Kunne ikke indlæse fil", "Fejl", { status: "danger" });
    } finally {
      this.isUploadingFile = false;
    }
  }

  flipInformation(): void {
    this.flipped = !this.flipped;
  }

  private showUploadedConfirmation(warningListFormArray: UntypedFormArray) {
    if (warningListFormArray.length === 0) {
      this.toastrService.success("Filen blev indlæst", "Succes");
    } else {
      this.toastrService.warning(
        "Filen blev indlæst, men der er nogle uregelsmæssigheder",
        "Uregelmæssigheder"
      );
      this.showWarningFlipper = true;
    }
  }

  private setInformationCardHeight() {
    const informationFront = document.getElementById("informationFront");
    const informationBack = document.getElementById("informationBack");

    // 'flipcard-body' and 'front-container' are dynamically added by the nebular flipcard
    const body = document.querySelector(".flipcard-body") as HTMLElement;
    const frontContainer = document.querySelector(".front-container") as HTMLElement;
    if (body && informationBack && informationFront && frontContainer) {
      body.style.maxHeight = "88vh";
      body.style.height = "100%";
      informationFront.style.height = "100%";
      informationBack.style.height = "100%";

      frontContainer.style.maxWidth = "100%";
      frontContainer.style.height = "100%";
    }
  }

  private setWarningMessages() {
    this.warningListFormArray.controls.forEach((control) => {
      const value = control.value;

      if (value.rowNotIncluded) {
        this.warningHeaders.push(
          `Række ${value.rowNotIncluded} blev ikke inkluderet i beregningerne.`
        );
      } else if (value.rowOccurance) {
        this.warningHeaders.push(
          `I række ${value.rowOccurance} er følgende oplysning(er) ikke udfyldt:`
        );
      } else {
        this.warningHeaders.push(
          `For en medarbejde '${value.uidValue}' er følgende oplysning(er) ikke udfyldt:`
        );
      }

      const data = [];

      if (value.annualSalary === 0) {
        data.push(`Årsløn: ${value.annualSalary}`);
      }
      if (value.annualPremium === 0) {
        data.push(`Årlig præmie uden sundhedsforsikringsindbetaling: ${value.annualPremium}`);
      }
      if (value.aum === 0) {
        data.push(`Samlet opsparing: ${value.aum}`);
      }
      if (value.investmentProfile === "Ingen") {
        data.push(`Investeringsprofil kunne ikke findes, sættes til ${value.investmentProfile}`);
      }

      this.warningData.push(data);
    });
  }

  setStatus(controlType: ControlType, i?: number): NbComponentStatus {
    if (controlType === ControlType.None) {
      return "basic";
    }

    try {
      const control = this.getControlFromType(controlType, i);

      if (
        (controlType === ControlType.Aum || controlType === ControlType.AnnualPremium) &&
        i !== undefined
      ) {
        this.investmentProfileDataForms.controls.forEach((group) => {
          group.get("aum")?.updateValueAndValidity();
          group.get("annualPremium")?.updateValueAndValidity();
        });
      }

      return control.valid ? "info" : "danger";
    } catch (e) {
      return "basic";
    }
  }

  get investmentProfileDataForms(): UntypedFormArray {
    return this.currentCosts.get("investmentCosts") as UntypedFormArray;
  }

  get fileUploadDisabled(): boolean {
    return this._fileUploadDisabled;
  }

  set fileUploadDisabled(value: boolean) {
    if (this._fileUploadDisabled === value) {
      return;
    }
    this._fileUploadDisabled = value;

    if (value) {
      this.fileInputs.disable();
    } else {
      this.fileInputs.enable();
    }
  }

  get uploadButtonDisabled(): boolean {
    return !this.fileInputs.get("fileInput")?.value || this.fileUploadDisabled;
  }

  get warningListFormArray(): UntypedFormArray {
    return this.employerData.get("warningList") as UntypedFormArray;
  }

  private setSavedInvestmentProfiles(investmentGroups: [InvestmentGroup]): void {
    if (!this.investmentAnalysisArgs) {
      return;
    }

    for (const group of investmentGroups) {
      const controls = this.investmentProfileDataForms.controls;
      const controlIndex = controls.findIndex((c) => c.value.header === group.investmentProfile);
      const profileId = this.investmentAnalysisArgs[group.investmentProfile];
      this.setInvestmentProfileNameData(profileId, controlIndex);
    }

    this.savedInvestmentProfiles.emit(investmentGroups);
  }

  private isCompanyAp(currentCompany: string): boolean {
    return currentCompany === this.APCompanyId;
  }

  private clearEmployeeListAndResetData() {
    const list = this.employerData.get("employees") as UntypedFormArray;
    list.clear();
    this.employerData.reset();

    this.fileInputs.reset();
  }

  private getControlFromType(controlType: ControlType, i?: number): UntypedFormControl {
    switch (controlType) {
      case ControlType.EmployerName:
        return this.information.get("employerName") as UntypedFormControl;
      case ControlType.NumberOfEmployees:
        return this.employerData.get("numberOfEmployees") as UntypedFormControl;
      case ControlType.AgeMean:
        return this.employerData.get("ageMean") as UntypedFormControl;
      case ControlType.AnnualSalaryMean:
        return this.employerData.get("annualSalaryMean") as UntypedFormControl;
      case ControlType.IsHealthInsurancePresent:
        return this.employerData.get("isHealthInsurancePresent") as UntypedFormControl;
      case ControlType.PercentOfDepository:
        if (i !== undefined) {
          return this.investmentProfileDataForms
            .at(i)
            .get("percentOfDepository") as UntypedFormControl;
        }
        throw new Error(`'${i}' is not defined`);
      case ControlType.AnnualPremium:
        if (i !== undefined) {
          return this.investmentProfileDataForms.at(i).get("annualPremium") as UntypedFormControl;
        }
        throw new Error(`'${i}' is not defined`);
      case ControlType.Aum:
        if (i !== undefined) {
          return this.investmentProfileDataForms.at(i).get("aum") as UntypedFormControl;
        }
        throw new Error(`'${i}' is not defined`);
      case ControlType.LossOfEarningCapacityMean:
        return this.employerData.get("lossOfEarningCapacityMean") as UntypedFormControl;
      case ControlType.DisabilityLumpSumMean:
        return this.employerData.get("disabilityLumpSumMean") as UntypedFormControl;
      case ControlType.CriticalIllnessMean:
        return this.employerData.get("criticalIllnessMean") as UntypedFormControl;
      case ControlType.CriticalIllnessChildMean:
        return this.employerData.get("criticalIllnessChildMean") as UntypedFormControl;
      case ControlType.DeathMean:
        return this.employerData.get("deathMean") as UntypedFormControl;
      case ControlType.OrphansPensionMean:
        return this.employerData.get("orphansPensionMean") as UntypedFormControl;
      case ControlType.ChooseProfile:
        if (i !== undefined) {
          return this.investmentProfileDataForms.at(i).get("chooseProfile") as UntypedFormControl;
        }
        throw new Error(`'${i}' is not defined`);
      case ControlType.CurrentCompany:
        return this.information.get("pensionCompanyId") as UntypedFormControl;
      default:
        throw new Error(`Can't map type '${controlType}' to a control`);
    }
  }

  private chooseInvestmentProfileValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (this.displayChooseInvestmentProfile && control.value === null) {
        return { validator: true };
      }

      return null;
    };
  }

  /**
   * Validates if the a saving or deposit input field is valid.
   * Can only be used with control types: `ControlType.Saving` or `ControlType.Deposit`.
   *
   * @param controlType The type of the input field that should be validated.
   * @param i The index of which the input field has in its `FormArray`.
   * @returns A function that validates the input field.
   */
  private savingDepositValidator(controlType: ControlType, i: number): ValidatorFn {
    this.depositAndSavingValidator(controlType);

    // Maps each type to the opposite type.
    const oppositeMap = new Map([
      [ControlType.Aum, ControlType.AnnualPremium],
      [ControlType.AnnualPremium, ControlType.Aum],
    ]);

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isControlValid = control.value >= 0 && control.value !== null;

      const opposite = oppositeMap.get(controlType) as ControlType;
      const oppositeGroup = this.getControlFromType(opposite, i);
      const isOppositeDirty = oppositeGroup.value !== null;

      const isOtherControlsValid = this.checkValidityOfControls(controlType);

      if (isOppositeDirty && !isControlValid) {
        return { validator: true };
      }

      if (!isOtherControlsValid) {
        return { validator: true };
      }

      return null;
    };
  }

  /**
   * Checks the validity of controls of the same type as `ControlType.Saving` or `ControlType.Deposit`.
   *
   * @param controlType Type of the control.
   * @returns Whether all controls of the specific type is valid or not.
   */
  private checkValidityOfControls(controlType: ControlType): boolean {
    this.depositAndSavingValidator(controlType);

    let isOtherControlsValid = false;
    for (let j = 0; j < this.investmentProfileDataForms.controls.length; j++) {
      const depositControl = this.getControlFromType(controlType, j);
      if (depositControl.value !== null) {
        isOtherControlsValid = depositControl.value >= 0;
        if (depositControl.value < 0) {
          break;
        }
      }
    }
    return isOtherControlsValid;
  }

  private depositAndSavingValidator(controlType: ControlType): void {
    if (controlType !== ControlType.AnnualPremium && controlType !== ControlType.Aum) {
      throw new Error(
        `Method is only usable with type ${ControlType.AnnualPremium} or ${ControlType.Aum}`
      );
    }
  }

  get isNoCompanySelected(): boolean {
    return this.information.get("pensionCompanyId")?.value === this.noCompanyId;
  }

  getYearCount(): number | null {
    return this.yearCount;
  }

  /**
   * Adjusts the yearCount property of a given investment profile form group, based on the new value
   * of the AuM property and the number of available yield years of the new profile.
   *
   * @param investmentProfileFormGroup
   * @param change
   * @param change.auM
   * @param change.investmentProfileName
   * @private
   */
  private adjustYearCount(
    yearCounts: { [investmentProfileName: string]: number },
    investmentProfileFormGroup: UntypedFormGroup,
    change: { auM?: number; investmentProfileName?: string }
  ) {
    const oldYearCount = investmentProfileFormGroup.get("yearCount")?.value as number | undefined;
    const newAuM =
      change.auM ?? (investmentProfileFormGroup.get("auM")?.value as number | undefined);
    const newInvestmentProfileName =
      change.investmentProfileName ??
      (investmentProfileFormGroup.get("investmentProfileName")?.value as string | undefined);
    let newYearCount: number | null = null;
    if (newInvestmentProfileName && newAuM && newAuM !== 0 && !this.isNoCompanySelected) {
      const yearCount = yearCounts[newInvestmentProfileName];
      if (yearCount === undefined || yearCount === null) {
        this.logger.error(
          "Could not find yields for investment profile {newInvestmentProfileName}",
          newInvestmentProfileName
        );
        newYearCount = 0;
      } else {
        newYearCount = yearCount;
      }
    }
    if (newYearCount === oldYearCount) {
      return;
    }
    investmentProfileFormGroup.patchValue({ yearCount: newYearCount });
  }

  /**
   * Computes an object mapping each investment profile name to the number of sequenctial years of
   * yield data is available for that profile.
   *
   * @param companyInvestmentExpenses
   * @private
   */
  private computeCompanyYearCounts(companyInvestmentExpenses: InvestmentExpensesData[]): {
    [investmentProfileName: string]: number;
  } {
    return companyInvestmentExpenses.reduce(
      (yearCounts: { [investmentProfileName: string]: number }, expenses) => {
        const yieldList = expenses.yields;
        let i = 0;
        for (; i < this.yieldYears.length; i++) {
          const isYearMissing = yieldList[i]?.year !== this.yieldYears[i];
          if (isYearMissing) {
            break;
          }
          if (!Number.isFinite(yieldList[i]?.yield)) {
            break;
          }
        }
        const indexOfFirstBlank = i;
        yearCounts[expenses.investmentProfileName] = indexOfFirstBlank;
        return yearCounts;
      },
      {}
    );
  }

  private recomputeYearCount() {
    const oldYearCount = this.yearCount;
    const newYearCount = this.isNoCompanySelected
      ? null
      : this.investmentProfileDataForms.controls.reduce((minYearCount, control) => {
          const yearCount = (control.get("yearCount")?.value ?? null) as number | null;
          if (minYearCount === null) {
            return yearCount;
          }
          if (yearCount === null) {
            return minYearCount;
          }
          return Math.min(minYearCount, yearCount);
        }, null as number | null);
    if (newYearCount !== oldYearCount) {
      this.yearCount = newYearCount;
      this.yearCountChanged.emit();
    }
  }

  /**
   * Checks all the investment profiles whether they have zero yields.
   * If a profile has zero yields, a toast is displayed, and a persistent
   * warning message is set.
   */
  private checkProfilesForZeroYields(companyId: string): void {
    let latestYear;

    if (this.yieldYears.length > 0) {
      latestYear = Math.max(...this.yieldYears);
    } else {
      this.toastrService.danger(null, "Der er ikke indtastet nogen afkastår.", {
        preventDuplicates: true,
      });

      return;
    }

    const currentCompany = this.companies.find((c) => c.id === companyId);
    if (this.investmentExpensesData.length === 0) {
      this.toastrService.danger(
        null,
        `Der er ikke indtastet nogle investeringsprofiler for '${currentCompany?.label}'`,
        { duration: 5000, preventDuplicates: true }
      );
      return;
    }

    const zeroExpenses = this.investmentExpensesData.filter(
      (expense) => !this.yearCounts[expense.investmentProfileName]
    );

    if (zeroExpenses.length !== 0) {
      this.toastrService.warning(
        "Tryk på advarselstrekanten nederst for at se mere",
        "Nogle investeringsprofiler er ikke tilgængelige",
        { duration: 5000, preventDuplicates: true }
      );

      this.warningHeaders.push(
        `Følgende investeringsprofiler kan ikke anvendes, da de(n) ikke har et indtastet afkast for ${latestYear}:`
      );

      const zeroProfileNames = zeroExpenses.map((expense) => `'${expense.investmentProfileName}'`);
      this.warningData.push(zeroProfileNames);

      this.showWarningFlipper = true;
    }
  }
}

enum ControlType {
  EmployerName = 0,
  NumberOfEmployees = 1,
  AgeMean = 2,
  AnnualSalaryMean = 3,
  IsHealthInsurancePresent = 4,
  PercentOfDepository = 5,
  AnnualPremium = 6,
  Aum = 7,
  LossOfEarningCapacityMean = 8,
  DisabilityLumpSumMean = 9,
  CriticalIllnessMean = 10,
  CriticalIllnessChildMean = 11,
  DeathMean = 12,
  OrphansPensionMean = 13,
  CurrentCompany = 14,
  ChooseProfile = 15,
  None = 16,
}
