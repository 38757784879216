import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult, AccountInfo, SilentRequest } from "@azure/msal-browser";
import { Idle } from "@ng-idle/core";
import { ServerError } from "msal";
import { NGXLogger } from "ngx-logger";

import { Guid } from "guid-typescript";
import { AppConfigService } from "../../app-config.service";
import { MonitoringService } from "../monitoring/monitoring.service";
import { TokenClaims } from "@azure/msal-common";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly scopes: string[];
  private readonly redirectUrl: string;
  private expiresOn?: Date | null;
  private getTokenPromise?: Promise<string>;
  private refreshTokenPromise?: Promise<string>;
  private loginResponse: Promise<AuthenticationResult>;
  private analyzerVariant: Variant = {
    name: Company.Analyzer,
    analysisType: "HonestusAnalysisInput", //Should be "MancofiAnalysisInput", but doesn't work yet.
    landingPage: "analysis-a",
  };
  private honestusVariant: Variant = {
    name: Company.Honestus,
    analysisType: "HonestusAnalysisInput",
    landingPage: "analysis",
  };

  constructor(
    private appConfigService: AppConfigService,
    private idle: Idle,
    private monitoringService: MonitoringService,
    private msalService: MsalService,
    private logger: NGXLogger
  ) {
    const appConfig = this.appConfigService.getConfig();
    const azure = appConfig.common.azure;
    this.scopes = azure.scopes.map((scope) => azure.appIdUri + "/" + scope);
    this.redirectUrl = window.location.href.replace(/(https?:\/\/[^/]*).*/gi, "$1");
    const parameters: SilentRequest = {
      scopes: this.scopes,
      forceRefresh: false,
      redirectUri: this.redirectUrl,
    };
    this.loginResponse = this.getAutheticationResult(parameters);
  }

  getToken(): Promise<string> {
    let promise = this.getTokenPromise;
    if (!promise) {
      promise = this.getTokenPromise = this.createGetTokenPromise(false);
    }
    return promise;
  }

  refreshToken(): Promise<string> {
    let promise = this.refreshTokenPromise;
    if (!promise) {
      promise = this.refreshTokenPromise = this.createGetTokenPromise(true);
    }
    return promise;
  }

  private async createGetTokenPromise(doRefreshToken: boolean): Promise<string> {
    let token: string | undefined;
    let delayMs = 1000;
    const parameters: SilentRequest = {
      scopes: this.scopes,
      forceRefresh: doRefreshToken,
    };
    while (!token) {
      try {
        const response: AuthenticationResult | undefined = await this.loginResponse;
        this.expiresOn = response?.expiresOn;
        token = response?.accessToken;
      } catch (error) {
        if (error instanceof ServerError) {
          if (error?.errorCode === "acquiretoken_progress_error") {
            this.logger.debug("CAUGHT acquiretoken_progress_error");
            delayMs = 5000;
          } else if (error?.errorCode === "interaction_required") {
            this.logger.debug("CAUGHT interaction_required");
            delayMs = 3000;
          } else if (
            error?.errorCode === "redirect_uri_mismatch" ||
            error?.errorMessage?.includes("The request contains invalid redirect URI") ||
            error?.message?.includes("The request contains invalid redirect URI")
          ) {
            this.logger.debug("CAUGHT invalid redirect URI");
            parameters.redirectUri = this.redirectUrl;
            delayMs = 1000;
          } else {
            this.logger.debug(error);
            delayMs = 1000;
          }
        } else {
          this.logger.debug(error);
          delayMs = 1000;
        }
        await new Promise((resolve) => setTimeout(resolve, delayMs));
        this.loginResponse = this.getAutheticationResult(parameters);
      }
    }
    return token;
  }

  private async getAutheticationResult(parameters: SilentRequest): Promise<AuthenticationResult> {
    try {
      const response: AuthenticationResult = await this.silentLogin(parameters);
      this.msalService.instance.setActiveAccount(response.account);
      return response;
    } catch (exception) {
      await this.msalService.loginRedirect(parameters).toPromise();
      const response: AuthenticationResult = await this.silentLogin(parameters);
      return response;
    }
  }

  private async silentLogin(parameters: SilentRequest): Promise<AuthenticationResult> {
    return await this.msalService.ssoSilent(parameters).toPromise();
  }

  async getCustomerId(): Promise<Guid | undefined> {
    const response: AuthenticationResult = await this.loginResponse;
    const idTokenClaims = response?.idTokenClaims as TokenClaims & { extension_CustomerID: string };
    try {
      const extensionCustomerID: string | undefined = idTokenClaims["extension_CustomerID"];
      if (!extensionCustomerID) {
        return Guid.parse(extensionCustomerID);
      } else {
        this.logger.error("extension_CustomerID is undefined");
      }
    } catch (exception) {
      this.logger.error(
        "CAUGHT exception when attempting to parse extension_CustomerID",
        exception
      );
    }
    return undefined;
  }

  /**
   * Returns the signed in account
   * (the account object is created at the time of successful login)
   * or null when no state is found
   *
   * @returns - the account object stored in MSAL
   */
  getAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  async getUserName(): Promise<string | undefined> {
    const response: AuthenticationResult | undefined = await this.loginResponse;
    return response?.account?.name;
  }

  /**
   * Method for deciding the theme.
   * @returns analyzerVariant if the code is running at the demo site localhost - honestusVariant otherwise.
   */
  getAssociatedCompany(): Variant {
    const isDemoSite = window.location.hostname.startsWith("demo");
    const isLocalhost = window.location.hostname.startsWith("localhost");
    const isTestSite = window.location.hostname.startsWith("test");
    this.logger.debug("Host name is '{HostName}'", window.location.hostname);

    if (isDemoSite || isLocalhost || isTestSite) return this.analyzerVariant;

    return this.honestusVariant;
  }

  /**
   * Initiates the login process by redirecting the user's browser to the authorization endpoint,
   * or, if that fails, by opening a popup window in the user's browser
   *
   * @returns - a promise that is fulfilled when this function has completed, or rejected if an error was raised.
   */
  async login(): Promise<void> {
    try {
      await this.msalService.loginPopup();
    } catch (error) {
      this.msalService.loginRedirect();
    }
  }

  logout(): void {
    this.msalService.logout();
    this.monitoringService.clearAuthenticatedUserId();
  }
}

export interface Variant {
  name: Company;
  analysisType: string;
  landingPage: string;
}

export enum Company {
  Analyzer = "analyzer",
  Honestus = "honestus",
}
