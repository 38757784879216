import { LoadingSpinnerComponent } from "./templates/loading-spinner/loading-spinner.component";
import { LogLevel } from "msal";
import {
  LoggerConfig,
  NGXLogger,
  NGXLoggerHttpService,
  NgxLoggerLevel,
  NGXMapperService,
} from "ngx-logger";

import { DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from "@angular/platform-browser/animations";
import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
} from "@azure/msal-browser";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbProgressBarModule,
  NbRadioModule,
  NbSelectModule,
  NbSidebarModule,
  NbSidebarService,
  NbSpinnerModule,
  NbStepperModule,
  NbTagModule,
  NbThemeModule,
  NbToastrModule,
  NbTooltipModule,
  NbUserModule,
} from "@nebular/theme";
import { NgIdleModule } from "@ng-idle/core";
import { TableModule } from "primeng/table";

import { AppConfigService } from "./app-config.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AnalysisAnalyzerComponent } from "./pages/analysis/analysis-analyzer/analysis-analyzer.component";
import { AnalysisHonestusComponent } from "./pages/analysis/analysis-honestus/analysis-honestus.component";
import { CostGroupsComponent } from "./pages/company-data/cost-groups/cost-groups.component";
import { CostGroupFieldsComponent } from "./templates/cost-group-fields/cost-group-fields.component";
import { CostGroupLabelsComponent } from "./templates/cost-group-labels/cost-group-labels.component";
import { DotnetLogLevels } from "./dotnet-log-levels";
import { Step1AnalyzerComponent } from "./pages/analysis/analysis-analyzer/step-1-analyzer/step-1-analyzer.component";
import { Step2AnalyzerComponent } from "./pages/analysis/analysis-analyzer/step-2-analyzer/step-2-analyzer.component";
import { Step3AnalyzerComponent } from "./pages/analysis/analysis-analyzer/step-3-analyzer/step-3-analyzer.component";
import { Step1HonestusComponent } from "./pages/analysis/analysis-honestus/step-1-honestus/step-1-honestus.component";
import { Step2HonestusComponent } from "./pages/analysis/analysis-honestus/step-2-honestus/step-2-honestus.component";
import { Step3HonestusComponent } from "./pages/analysis/analysis-honestus/step-3-honestus/step-3-honestus.component";
import { InvestmentExpensesComponent } from "./pages/company-data/investment-expenses/investment-expenses.component";
import { HealthInsuranceCostsHonestusComponent } from "./pages/health-insurance/health-insurance-costs-honestus/health-insurance-costs-honestus.component";
import { HealthInsuranceCostAnalyzerComponent } from "./pages/health-insurance/health-insurance-cost-analyzer/health-insurance-cost-analyzer.component";
import { InsuranceConditionsComponent } from "./pages/insurance-conditions/insurance-conditions.component";
import { LogoutComponent } from "./pages/logout/logout.component";
import { RecommendationsComponent } from "./pages/recommendations/recommendations.component";
import { YieldYearsComponent } from "./pages/yield-years/yield-years.component";
import { TimeoutTextPipe } from "./pipes/timeout-text.pipe";
import { AppInsightsLoggerMonitor } from "./services/monitoring/app-insights-logger-monitor";
import { ErrorPopUpComponent } from "./templates/error-pop-up/error-pop-up.component";
import { HealthInsuranceCostComponent } from "./templates/health-insurance-cost/health-insurance-cost.component";
import { HealthInsuranceFieldsComponent } from "./templates/health-insurance-fields/health-insurance-fields.component";
import { HealthGroupLabelsComponent } from "./templates/health-group-labels/health-group-labels.component";
import { InvestmentExpensesFieldsComponent } from "./templates/investment-expenses-fields/investment-expenses-fields.component";
import { InvestmentExpensesLabelsComponent } from "./templates/investment-expenses-labels/investment-expenses-labels.component";
import { CustomerComponent } from "./pages/customer/customer.component";
import { WinningOfferComponent } from "./pages/customer/winning-offer/winning-offer.component";
import { CalendarModule } from "primeng/calendar";
import { ConfirmPopupComponent } from "./templates/confirm-popup/confirm-popup.component";

const appInitializerFn = (appConfigService: AppConfigService) => (): Promise<any> =>
  appConfigService.loadAppConfig();

const msalLogLevels: { [key in DotnetLogLevels]: LogLevel } = {
  Trace: LogLevel.Verbose,
  Debug: LogLevel.Verbose,
  Information: LogLevel.Info,
  Warning: LogLevel.Warning,
  Error: LogLevel.Error,
  Critical: LogLevel.Error,
  None: LogLevel.Error,
};

const ngxLogLevels: { [key in DotnetLogLevels]: NgxLoggerLevel } = {
  Trace: NgxLoggerLevel.TRACE,
  Debug: NgxLoggerLevel.DEBUG,
  Information: NgxLoggerLevel.INFO,
  Warning: NgxLoggerLevel.WARN,
  Error: NgxLoggerLevel.ERROR,
  Critical: NgxLoggerLevel.FATAL,
  None: NgxLoggerLevel.OFF,
};

const getErrorHandler = (appConfigService: AppConfigService): ErrorHandler =>
  new ThrowingErrorHandler(appConfigService.getConfig().environmentName === "Development");

export function MSALInstanceFactory(appConfigService: AppConfigService): IPublicClientApplication {
  const appConfig = appConfigService.getConfig();
  const azure = appConfig.common.azure;
  return new PublicClientApplication({
    auth: {
      clientId: azure.clientId,
      authority: `https://${azure.tenantName}.b2clogin.com/${azure.tenantName}.onmicrosoft.com/${azure.signUpSignInPolicyId}`,
      knownAuthorities: [`${azure.tenantName}.b2clogin.com`],
      redirectUri: window.location.origin,
      postLogoutRedirectUri: "/",
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        logLevel: msalLogLevels[appConfig.client.logLevel],
        piiLoggingEnabled: true,
      },
    },
  });
}

export function MSALGuardConfigFactory(appConfigService: AppConfigService): MsalGuardConfiguration {
  const appConfig = appConfigService.getConfig();
  const azure = appConfig.common.azure;
  const scopes = azure.scopes.map((scope) => azure.appIdUri + "/" + scope);
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: scopes,
    },
  };
}

const getLogger = (
  appConfigService: AppConfigService,
  appInsightsLoggerMonitor: AppInsightsLoggerMonitor,
  ngxLoggerHttpService: NGXLoggerHttpService,
  ngxMapperService: NGXMapperService
): NGXLogger => {
  const appConfig = appConfigService.getConfig();
  const loggerConfig: LoggerConfig = {
    disableConsoleLogging: appConfig.environmentName !== "Development",
    enableSourceMaps: appConfig.environmentName === "Development",
    level: ngxLogLevels[appConfig.client.logLevel],
    serverLoggingUrl: undefined,
    serverLogLevel: NgxLoggerLevel.OFF,
    timestampFormat: "long",
  };
  const logger = new NGXLogger(
    ngxMapperService,
    ngxLoggerHttpService,
    loggerConfig,
    null,
    new DatePipe("en-DK")
  );
  logger.registerMonitor(appInsightsLoggerMonitor);
  return logger;
};

@NgModule({
  declarations: [
    AnalysisHonestusComponent,
    AnalysisAnalyzerComponent,
    AppComponent,
    CostGroupFieldsComponent,
    CostGroupLabelsComponent,
    CostGroupsComponent,
    ErrorPopUpComponent,
    HealthInsuranceCostComponent,
    HealthInsuranceCostAnalyzerComponent,
    HealthInsuranceCostsHonestusComponent,
    HealthInsuranceFieldsComponent,
    HealthGroupLabelsComponent,
    InsuranceConditionsComponent,
    InvestmentExpensesComponent,
    InvestmentExpensesFieldsComponent,
    InvestmentExpensesLabelsComponent,
    LoadingSpinnerComponent,
    LogoutComponent,
    RecommendationsComponent,
    Step1AnalyzerComponent,
    Step2AnalyzerComponent,
    Step3AnalyzerComponent,
    Step1HonestusComponent,
    Step2HonestusComponent,
    Step3HonestusComponent,
    TimeoutTextPipe,
    YieldYearsComponent,
    CustomerComponent,
    WinningOfferComponent,
    ConfirmPopupComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MsalModule,
    NbButtonModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDialogModule.forRoot(),
    NbEvaIconsModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule.forRoot(),
    NbProgressBarModule,
    NbRadioModule,
    NbSelectModule,
    NbSidebarModule.forRoot(),
    NbSpinnerModule,
    NbStepperModule,
    NbTagModule,
    NbThemeModule.forRoot({ name: "honestus" }),
    NbToastrModule.forRoot(),
    NbTooltipModule,
    NbUserModule,
    NgIdleModule.forRoot(),
    NoopAnimationsModule,
    ReactiveFormsModule,
    TableModule,
    NbFormFieldModule,
    CalendarModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService, APP_INITIALIZER],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfigService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    NbSidebarService,
    {
      provide: NGXLogger,
      useFactory: getLogger,
      deps: [AppConfigService, AppInsightsLoggerMonitor, NGXLoggerHttpService, NGXMapperService],
    },
    NGXLoggerHttpService,
    NGXMapperService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// Error handler that simply re-throws the error
class ThrowingErrorHandler extends ErrorHandler {
  constructor(private enableConsoleLogging: boolean) {
    super();
  }

  handleError(error: any): void {
    if (this.enableConsoleLogging) {
      super.handleError(error);
    }
    // Throw error, letting the Application Insights module log it to the cloud
    throw error;
  }
}
