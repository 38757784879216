import { Injectable } from "@angular/core";
import { NbMenuItem } from "@nebular/theme/components/menu/menu.service";

import { AppConfigService } from "./app-config.service";

@Injectable({
  providedIn: "root",
})
export class AppMenuService {
  get insuranceConditions(): NbMenuItem[] {
    return [
      {
        title: "Tab af erhvervsevne",
        link: "/insuranceConditions/lossOfEarningCapacity",
      },
      {
        title: "Kritisk sygdom",
        link: "/insuranceConditions/criticalIllness",
      },
      {
        title: "Sundhedsforsikring",
        link: "/insuranceConditions/healthInsurance",
      },
    ];
  }

  constructor(private appConfigService: AppConfigService) {}

  get menuItemsHonestus(): NbMenuItem[] {
    const menuItems: NbMenuItem[] = [
      {
        title: "Analyse",
        link: "/analysis",
      },
      {
        title: "Forsikringsbetingelser",
        children: this.insuranceConditions,
      },
      {
        title: "Pensionsselskaber",
        children: this.pensionCompanies,
      },
      {
        title: "Anbefalinger",
        link: "/recommendations",
      },
      {
        title: "Afkast-år",
        link: "/yieldYears",
      },
      {
        title: "Sundhedsforsikringspriser",
        link: "/healthInsuranceCosts",
      },
      {
        title: "Gemte analyser",
        link: "/customer",
      },
    ];

    return menuItems;
  }

  get pensionCompanies(): NbMenuItem[] {
    const companyMenuItems: NbMenuItem[] = [];

    this.appConfigService.getConfig().common.pensionCompanies.forEach((company) => {
      companyMenuItems.push({
        title: company.label,
        children: [
          {
            title: "Prisgrupper / adm. omk",
            link: "/pensionCompanies/" + company.id + "/costGroups",
          },
          {
            title: "Investeringsprofiler",
            link: "/pensionCompanies/" + company.id + "/investmentExpenses",
          },
        ],
      });
    });

    return companyMenuItems;
  }

  get menuItemsAnalyzer(): NbMenuItem[] {
    return [
      {
        title: "Tilbud",
        link: "/analysis-a",
        icon: "edit-2",
      },
      {
        title: "Prisgrupper",
        children: this.costGroups,
        icon: "pie-chart-2",
      },
      {
        title: "Investeringsprofiler",
        children: this.investmentProfiles,
        icon: "trending-up",
      },
      {
        title: "Anbefalinger",
        link: "/recommendations",
        icon: "options-2",
      },
      {
        title: "Gemte analyser",
        link: "/customer",
        icon: "edit-1",
      },
    ];
  }

  get costGroups(): NbMenuItem[] {
    return [
      {
        title: "Pensionsselskaber",
        children: this.pensionCompaniesCostGroups,
      },
      {
        title: "Sundhedsforsikring",
        children: this.healthInsuranceCompanies,
      },
    ];
  }

  get investmentProfiles(): NbMenuItem[] {
    return [
      {
        title: "Pensionsselskaber",
        children: this.pensionCompaniesInvestmentProfiles,
      },
      {
        title: "Afkastår",
        link: "/yieldYears",
      },
    ];
  }

  get pensionCompaniesCostGroups(): NbMenuItem[] {
    return this.appConfigService.getConfig().common.pensionCompanies.map((company) => {
      return {
        title: company.label,
        link: `/pensionCompanies/${company.id}/costGroups`,
      };
    });
  }

  get pensionCompaniesInvestmentProfiles(): NbMenuItem[] {
    return this.appConfigService.getConfig().common.pensionCompanies.map((company) => {
      return {
        title: company.label,
        link: `/pensionCompanies/${company.id}/investmentExpenses`,
      };
    });
  }

  get healthInsuranceCompanies(): NbMenuItem[] {
    return this.appConfigService.getConfig().common.healthInsuranceCompanies.map((company) => {
      return {
        title: company.label,
        link: `/${company.id}/healthInsuranceCost`,
      };
    });
  }
}
