

<div class="grid-wrapper">
  <nb-card class="information">
    <nb-card-header style="display: flex; justify-content: space-between;">
      <div>Oplysninger</div>
      <button
        [disabled]="fileUploadDisabled" nbButton size="tiny" shape="round"
        [nbSpinner]="isDownloadingTemplates" nbSpinnerSize="small" nbSpinnerStatus="control"
        (click)="downloadTemplates()">
        ⭳ Skabeloner
      </button>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="information">
        <div class="row-flex margin-bottom">
          <div>Nuværende selskab</div>
          <nb-select placeholder="Vælg selskab"
              shape="semi-round" style="margin-right: 12px;" formControlName="pensionCompanyId"
              [status]="setStatus(ControlType.CurrentCompany)" style="min-width: 13rem;"
              (selectedChange)="setInputAsMultiple($event, employerDataFileInput)">
            <nb-option *ngFor="let company of companies" [value]="company.id" name="company">
              {{company.label}}
            </nb-option>
          </nb-select>
        </div>

        <div class="margin-bottom" style="overflow: hidden;">
          <div class="row-flex" [formGroup]="fileInputs">
            <button [disabled]="fileUploadDisabled" [tabIndex]="-1" nbButton shape="round" size="small" status="info">Vælg Fil</button>
            <input TabIndex="-1" type="text" nbInput fullWidth shape="round" placeholder="Filnavn" formControlName="textToDisplayInput">
            <input #employerDataFileInput type="file" class="hideFileInput" formControlName="fileInput" (change)="updateFileDisplay($event)">
          </div>
          <div style="margin-top: 20px;">
            <button *ngIf="doDisplayUploadExtraFileButton" TabIndex="-1" nbButton shape="round" size="small" status="info" (click)="chooseExtraFile(employerDataFileInput)">
              Vælg ekstra fil
            </button>
            <button
              [disabled]="uploadButtonDisabled || isUploadingFile" nbButton
              shape="round" size="small" status="info"
              (click)="upload(employerDataFileInput.files, [])"
              style="float: right;"
              [nbSpinner]="isUploadingFile" nbSpinnerSize="medium" nbSpinnerStatus="control">
              Upload fil ⭱
            </button>
          </div>
        </div>

        <div class="row-flex margin-bottom">
          <div>Firmanavn</div>
          <input
            [status]="setStatus(ControlType.EmployerName)"
            type="text" nbInput shape="semi-round"
            formControlName="employerName" />
        </div>

        <div class="row-flex margin-bottom" formGroupName="employerData">
          <div>Antal ansatte</div>
          <input
            [status]="setStatus(ControlType.NumberOfEmployees)"
            type="number" nbInput shape="semi-round"
            formControlName="numberOfEmployees" />
        </div>

        <div class="row-flex margin-bottom" formGroupName="employerData">
          <div>Gennemsnitsalder</div>
          <input
            [status]="setStatus(ControlType.AgeMean)"
            type="number" nbInput shape="semi-round"
            formControlName="ageMean" />
        </div>

        <div class="row-flex margin-bottom" formGroupName="employerData">
          <div>Gennemsnitsårsløn</div>
          <input
            placeholder="Kr."
            [status]="setStatus(ControlType.AnnualSalaryMean)"
            type="number" nbInput shape="semi-round"
            formControlName="annualSalaryMean" />
        </div>
      </form>
    </nb-card-body>
  </nb-card>

  <nb-flip-card [showToggleButton]="false" [flipped]="flipped" class="investmentProfiles" id="information">
    <nb-card-front>
    <nb-card id="informationFront">
      <nb-card-header class="margin-bottom">Investeringsprofiler</nb-card-header>
      <nb-card-body *ngIf="isLoading" class="center-and-middle">
        <app-loading-spinner></app-loading-spinner>
      </nb-card-body>
      <nb-card-body *ngIf="!isLoading" style="padding-top: 0;">
        <div *ngIf="investmentProfileDataForms.controls.length === 0" style="display: flex; flex-direction: column;" class="center-and-middle full-screen">
          <svg width="100" height="100" viewBox="0 -6 50 100" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 100px; height: 100px;">
            <style>
              .line-mouse1 {animation:line-mouse1-scroll 2s infinite;}
              @keyframes line-mouse1-scroll {
                  0%{transform:translate3d(0, 0, 0);}
                  50%{transform:translate3d(0, 10px, 0);}
                  100%{transform:translate3d(0, 0, 0);}
              }
              @media (prefers-reduced-motion: reduce) {
                  .line-mouse1 {
                      animation: none;
                  }
              }
            </style>
            <rect class="stroke1 fill1" x="1" y="1" width="53" height="84" rx="19" fill="#435582" stroke="#435582" stroke-width="2px" style="animation-duration: 2s;"></rect>
            <line class="line-mouse1 stroke2" x1="28" y1="11" x2="28" y2="26" stroke="#fff" stroke-width="2px" style="animation-duration: 2s;"></line>
          </svg>
          <p>Vælg venligst et selskab for at indtaste investeringsprofiler</p>
        </div>
        <div *ngIf="investmentProfileDataForms.controls.length > 0" class="investment-profiles-grid">
          <div class="annual-premium">Samlet indbetaling (årlig)</div>
          <div class="aum">Samlet opsparing</div>
          <div class="percent-of-depository">Investeringsomk. % af depotet</div>
        </div>
        <form [formGroup]="currentCosts">
          <div formArrayName="investmentCosts" class="margin-bottom" *ngFor="let data of investmentProfileDataForms.controls; let i = index">
            <div class="investment-profiles-grid" [formGroupName]="i">
              <div class="profile-header">{{data.value.header}}</div>
              <nb-select class="choose-investment-profile" placeholder="Vælg" *ngIf="displayChooseInvestmentProfile"
                (selectedChange)="setInvestmentProfileNameData($event, i)" shape="semi-round" formControlName="chooseProfile"
                [status]="setStatus(ControlType.ChooseProfile, i)">
                <nb-option *ngFor="let investmentExpensesHeader of investmentExpensesHeaders"
                  value={{investmentExpensesHeader.id}} [disabled]="yearCounts[investmentExpensesHeader.label] === 0">
                  {{investmentExpensesHeader.label}}
                </nb-option>
              </nb-select>
              <input
                [status]="setStatus(ControlType.AnnualPremium, i)"
                type="number" nbInput
                shape="semi-round"
                class="annual-premium"
                formControlName="annualPremium">
              <input
                [status]="setStatus(ControlType.Aum, i)"
                type="number" nbInput
                shape="semi-round"
                class="aum"
                formControlName="aum">
              <input
                [status]="setStatus(ControlType.PercentOfDepository, i)"
                type="number" nbInput
                shape="semi-round"
                class="percent-of-depository"
                formControlName="percentOfDepository">
            </div>
          </div>
        </form>
      </nb-card-body>
      <nb-card-footer *ngIf="showWarningFlipper" >
        <nb-icon
          icon="alert-triangle" status="warning"
          [options]="iconOptions"
          class="flip-icon"
          (click)="flipInformation()"></nb-icon>
      </nb-card-footer>
    </nb-card>
  </nb-card-front>

    <nb-card-back>
      <nb-card accent="warning" id="informationBack">
        <nb-card-header>Uregelmæssigheder</nb-card-header>
        <nb-card-body>
          <nb-list>
            <nb-list-item *ngFor="let warningHeader of warningHeaders; let i = index" style="display: flex; flex-direction: column;">
              <b>{{warningHeader}}</b>
              <ul *ngIf="warningData[i] && warningData[i].length > 0" >
                <li *ngFor="let item of warningData[i]">{{item}}</li>
              </ul>
            </nb-list-item>
          </nb-list>
        </nb-card-body>
        <nb-card-footer *ngIf="showWarningFlipper">
          <nb-icon
            icon="alert-triangle" status="warning"
            [options]="iconOptions"
            class="flip-icon"
            (click)="flipInformation()"></nb-icon>
        </nb-card-footer>
      </nb-card>
    </nb-card-back>
  </nb-flip-card>

  <nb-card class="healthInsurance">
    <nb-card-header>Sundhedsforsikring</nb-card-header>
    <nb-card-body>
      <div class="row-flex" style="margin-bottom: 2rem;" [formGroup]="employerData">
        <div>Sundhedsforsikring</div>
        <nb-radio-group name="healthInsurance" [status]="setStatus(ControlType.IsHealthInsurancePresent)" style="display: flex;" formControlName="isHealthInsurancePresent">
          <nb-radio name="healthInsurance" [value]=true>Ja</nb-radio>
          <nb-radio name="healthInsurance" [value]=false>Nej</nb-radio>
        </nb-radio-group>
      </div>

      <div class="row-flex">
        <app-health-group-labels style="grid-column: 1;"></app-health-group-labels>
        <app-health-insurance-fields style="grid-column: 2;" [isHealthInsuranceDisabled]="!isHealthInsurancePresent"></app-health-insurance-fields>
      </div>
    </nb-card-body>
  </nb-card>

  <nb-card class="current-coverages">
    <nb-card-header>Nuværende dækninger</nb-card-header>
    <nb-card-body>
        <form [formGroup]="employerData">
          <div *ngFor="let item of employerDataDisplayed" class="row-flex margin-bottom">
            <div>{{item.label}}</div>
            <input
              [status]="setStatus(item.controlType)"
              type="number" nbInput shape="semi-round"
              placeholder={{item.placeholder}}
              formControlName={{item.formControlName}}/>
          </div>
        </form>
    </nb-card-body>
  </nb-card>

  <nb-card class="current-costs" style="min-width: 510px; overflow: auto; max-height: 120vh;">
    <nb-card-header>Nuværende priser (årligt)</nb-card-header>
    <form [formGroup]="currentCosts">
      <div style="display: flex; flex-direction: column;">
        <div style="display: flex; flex-direction: row;">
          <nb-card-body style="padding-right: 0; padding-bottom: 0;">
            <div *ngIf="displayChooseCostGroups" class="single-label">Prisgruppe</div>
            <app-cost-group-labels></app-cost-group-labels>
          </nb-card-body>
          <nb-card-body class="custom-body">
            <nb-select placeholder="Vælg gruppe" formControlName="costGroupId" #costGroupsDropdown *ngIf="displayChooseCostGroups" shape="semi-round" style="width: 215px; margin-bottom: 10px;">
              <nb-option *ngFor="let costGroupsHeader of costGroupsHeaders" [value]="costGroupsHeader.id">{{costGroupsHeader.label}}</nb-option>
            </nb-select>

            <app-cost-group-fields></app-cost-group-fields>
          </nb-card-body>
        </div>
      </div>
    </form>
  </nb-card>
</div>
