import { ChangeDetectorRef, Component } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { FileSaverService } from "ngx-filesaver";
import { NGXLogger } from "ngx-logger";

import { AppConfigService } from "../../../../app-config.service";
import { EmployerFileParserService } from "../../../../services/company-file-parser/employer-file-parser.service";
import { CostGroupsService } from "../../../../services/cost-groups/cost-groups.service";
import { FileTransfererService } from "../../../../services/file-transferrer/file-transferer.service";
import { InvestmentExpensesService } from "../../../../services/investment-expenses/investment-expenses.service";
import { MonitoringService } from "../../../../services/monitoring/monitoring.service";
import { YieldYearsService } from "../../../../services/yield-years/yield-years.service";
import { Step1Component } from "../../step1.component";

@Component({
  selector: "app-step-1-analyzer",
  templateUrl: "./step-1-analyzer.component.html",
  styleUrls: ["./step-1-analyzer.component.scss"],
})
export class Step1AnalyzerComponent extends Step1Component {
  constructor(
    appConfigService: AppConfigService,
    ref: ChangeDetectorRef,
    costGroupsService: CostGroupsService,
    employerFileParser: EmployerFileParserService,
    fileSaver: FileSaverService,
    fileTransferer: FileTransfererService,
    formBuilder: UntypedFormBuilder,
    investmentExpensesService: InvestmentExpensesService,
    monitoring: MonitoringService,
    dialogService: NbDialogService,
    toastrService: NbToastrService,
    logger: NGXLogger,
    yieldYearsService: YieldYearsService
  ) {
    super(
      appConfigService,
      ref,
      costGroupsService,
      employerFileParser,
      fileSaver,
      fileTransferer,
      formBuilder,
      investmentExpensesService,
      monitoring,
      dialogService,
      toastrService,
      logger,
      yieldYearsService
    );
  }
}
