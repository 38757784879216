import { Injectable } from "@angular/core";
import { gql } from "@apollo/client/core";

import { CompanyFormat } from "./companyFormat";
import { InsuranceConditionsFormat } from "./insuranceConditionsFormat";
import { ApolloInstanceService } from "../apollo/apollo-instance.service";
import { AppConfigService } from "../../app-config.service";
import { CostGroupsService } from "../cost-groups/cost-groups.service";
import { IdObject } from "../../interfaces/id-object";

export type InsuranceKind = "criticalIllness" | "healthInsurance" | "lossOfEarningCapacity";

@Injectable({
  providedIn: "root",
})
export class InsuranceConditionsService {
  private healthInsuranceCompanies?: { id: string; label: string }[];
  private pensionCompanies?: { label: string; id: string }[];
  private costGroupsByCompany: { [p: string]: IdObject[] } = {};
  private healthInsuranceCostGroupsByCompany: { [p: string]: IdObject[] } = {};

  constructor(
    private clientService: ApolloInstanceService,
    private configuration: AppConfigService,
    private costGroupsService: CostGroupsService
  ) {
    void this.costGroupsService
      .fetchCostGroupsByCompany("healthInsurance", this.getHealthInsuranceCompanies())
      .then(
        (costGroupsByCompany) => (this.healthInsuranceCostGroupsByCompany = costGroupsByCompany)
      );
    void this.costGroupsService
      .fetchCostGroupsByCompany("lossOfEarningCapacity", this.getPensionCompanies())
      .then((costGroupsByCompany) => (this.costGroupsByCompany = costGroupsByCompany));
  }

  private setConditionMutation = gql`
    mutation ConditionsMutation($conditions: HonestusConditionsInput!) {
      honestusSetConditions(conditions: $conditions) {
        status
        errors
      }
    }
  `;

  private getConditionQuery(conditionKind: string) {
    return gql(
      `
      query ConditionsQuery {
        honestusConditions {
          __typename
          ...on HonestusConditions {
            ${conditionKind} {
              columnHeaders {
                companyId
                costGroupId
              }
              rows {
                rowHeader
                fields { text rating }
              }
            }
          }
          ...on HonestusError {
            status
            errors
          }
        }
      }`
    );
  }

  async getData(insuranceKind: InsuranceKind): Promise<InsuranceConditionsFormat> {
    const query = this.getConditionQuery(insuranceKind);
    const apollo = await this.clientService.getInstance();
    const result = await apollo.query({ query, fetchPolicy: "network-only" });
    if (result.errors) {
      throw new Error("Server request failed: " + result.errors.join("\n"));
    }
    const resultData = (result.data as { honestusConditions: HonestusConditions })
      .honestusConditions;
    if (resultData.__typename !== "HonestusConditions") {
      throw new Error(`GraphQL request failed: ${resultData.errorMessage || "(message missing)"}`);
    }
    const matrix = resultData[insuranceKind] as HonestusConditionsMatrix;
    const conditions = new InsuranceConditionsFormat();
    conditions.conditions = matrix.rows.map((row) => row.rowHeader);
    conditions.companies = this.transposeToCompanyFormat(
      insuranceKind,
      matrix.columnHeaders,
      matrix.rows
    );

    return conditions;
  }

  async setData(
    insuranceKind: InsuranceKind,
    conditionsFormat: InsuranceConditionsFormat
  ): Promise<void> {
    const mutation = this.setConditionMutation;
    const apollo = await this.clientService.getInstance();
    const conditionsMatrix = this.transposeToConditionsMatrix(conditionsFormat);
    const variables = {
      conditions: { [insuranceKind]: conditionsMatrix },
    };
    const result = await apollo.mutate({ mutation, variables });
    if (result.errors) {
      throw new Error("Server request failed: " + result.errors.join("\n"));
    }
    const resultData = (result.data as { honestusSetConditions: HonestusSetResult })
      .honestusSetConditions;
    if (resultData.status !== "OK") {
      throw new Error(
        `GraphQL request failed: ${resultData.status} ${resultData.errors.join(" / ")}.`
      );
    }
  }

  getHealthInsuranceCompanies(): { id: string; label: string }[] {
    if (!this.healthInsuranceCompanies) {
      this.healthInsuranceCompanies = this.configuration
        .getConfig()
        .common.healthInsuranceCompanies.map((c) => ({ id: c.id.toLowerCase(), label: c.label }));
    }
    return this.healthInsuranceCompanies;
  }

  getPensionCompanies(): { id: string; label: string }[] {
    if (!this.pensionCompanies) {
      this.pensionCompanies = this.configuration
        .getConfig()
        .common.pensionCompanies.map((c) => ({ id: c.id.toLowerCase(), label: c.label }));
    }
    return this.pensionCompanies;
  }

  transposeToCompanyFormat(
    insuranceKind: InsuranceKind,
    headers: HonestusDataHeader[],
    rows: HonestusConditionRow[]
  ): CompanyFormat[] {
    const columnCount = headers.length;
    const columns = new Array<CompanyFormat>(columnCount);
    const getCostGroupName =
      insuranceKind === "healthInsurance"
        ? (companyId: string, costGroupId: string) =>
            this.healthInsuranceCostGroupsByCompany[companyId]?.find((g) => g.id === costGroupId)
              ?.label
        : (companyId: string, costGroupId: string) =>
            this.costGroupsByCompany[companyId]?.find((g) => g.id === costGroupId)?.label;
    for (let i = 0; i < columnCount; i++) {
      const columnData = rows.map((row) => row.fields[i]);
      const companyFormat = new CompanyFormat();
      const header = headers[i];
      const companyId = header.companyId;
      companyFormat.companyId = companyId;
      companyFormat.companyName =
        this.getHealthInsuranceCompanies().find((company) => company.id === companyId)?.label ??
        "Ukendt leverandør";
      const costGroupId = header.costGroupId;
      companyFormat.costGroupId = costGroupId;
      companyFormat.costGroupName = getCostGroupName(companyId, costGroupId) ?? "Ukendt gruppe";
      companyFormat.coverages = columnData.map((field) => field?.text ?? null);
      companyFormat.ratings = columnData.map((field) => field?.rating ?? null);
      columns[i] = companyFormat;
    }
    return columns;
  }

  private transposeToConditionsMatrix(
    conditionsFormat: InsuranceConditionsFormat
  ): HonestusConditionsMatrix {
    const companies = conditionsFormat.companies;
    const columnHeaders = companies.map((company) => ({
      companyId: company.companyId,
      costGroupId: company.costGroupId,
    }));
    const rowCount = conditionsFormat.conditions.length;
    const rows = new Array<HonestusConditionRow>(rowCount);
    for (let i = 0; i < rowCount; i++) {
      const rowData = companies.map((company) => {
        const coverage = company.coverages[i];
        const rating = company.ratings[i];
        return coverage && rating ? { text: coverage, rating } : null;
      });
      rows[i] = { rowHeader: conditionsFormat.conditions[i], fields: rowData };
    }
    return {
      columnHeaders,
      rows,
    };
  }
}

interface HonestusConditions {
  __typename: string;
  errorMessage?: string;
  criticalIllness?: HonestusConditionsMatrix;
  healthInsurance?: HonestusConditionsMatrix;
  lossOfEarningCapacity?: HonestusConditionsMatrix;
}

interface HonestusConditionsMatrix {
  columnHeaders: HonestusDataHeader[];
  rows: HonestusConditionRow[];
}

interface HonestusDataHeader {
  companyId: string;
  costGroupId: string;
}

interface HonestusConditionRow {
  fields: (HonestusCondition | null)[];
  rowHeader: string;
}

interface HonestusCondition {
  rating: number;
  text: string;
}

interface HonestusSetResult {
  errors: string[];
  status: HonestusSetStatus;
}

type HonestusSetStatus = "OK" | "PERMISSIONDENIED";
