<div class="row-flex margin box-height">
  <div>Investeringsomk.</div>
  <div class="sublabel">% af depotet</div>
</div>

<div *ngFor="let year of yieldYears; let i = index" class="row-flex margin box-height">
  <div *ngIf="i == 0">Afkast</div>
  <div *ngIf="i != 0"></div>
  <div class="sublabel">{{year}}</div>
</div>
