<nb-card style="min-height: 500px; min-width: 400px;">
  <nb-card-header>
    <div class="row" style="justify-content: space-between;">
      <h5>Effektuer</h5>
      <nb-icon icon="close" class="close" (click)="close()"></nb-icon>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="form" style="display: flex; flex-direction: column;">
      <div style="grid-row: 1; display: flex; align-items: center;" class="margin-bottom">
        <p style="grid-column: 1; padding-right: 87px; ">Ikrafttrædelse</p>
        <div style="grid-column: 2; width: 300px;">
          <p-calendar
            [showWeek]="true"
            [showButtonBar]="true"
            [showIcon]="true"
            formControlName="valueDate"
            dateFormat="dd/mm-yy"
          ></p-calendar>
        </div>
      </div>

      <div style="grid-row: 2; display: grid;" class="left-align margin-bottom">
        <p style="grid-column: 1; padding-right: 57px;">Pension</p>
        <nb-select style="grid-column: 2; max-width: 200; width: 300px;" shape="semi-round" formControlName="pensionCompanyId">
          <nb-option *ngFor="let company of pensionCompanies"  [value]="company.id">
            {{ company.label }}
          </nb-option>
        </nb-select>
      </div>

      <div style="grid-row: 3; display: grid;" class="left-align margin-bottom">
        <p style="grid-column: 1; padding-right: 71px;">Risiko</p>
        <nb-select style="grid-column: 2; max-width: 200; width: 300px" shape="semi-round" formControlName="riskCompanyId">
          <nb-option *ngFor="let company of pensionCompanies"  [value]="company.id">
            {{ company.label }}
          </nb-option>
        </nb-select>
      </div>

      <div style="grid-row: 4; display: grid;" class="left-align margin-bottom">
        <p style="grid-column: 1; padding-right: 49px; ">Sundhed</p>
        <nb-select style="grid-column: 2; max-width: 200; width:300px" shape="semi-round" formControlName="healthInsuranceCompanyId">
          <nb-option *ngFor="let company of healthInsuranceCompanies"  [value]="company.id">
            {{ company.label }}
          </nb-option>
        </nb-select>
      </div>

      <div style="grid-row: 5; display: grid;" class="left-align margin-bottom">
        <p style="grid-column: 1; padding-right: 70px;">Årsag</p>
        <textarea style="grid-column: 2; height: 139px; width: 300px; min-width: 20rem; max-width: 20rem; max-height: 60vh; min-height: 35.25px;" shape="semi-round" nbInput formControlName="reason"></textarea>
      </div>

    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" (click)="save(form.value)">Gem</button>
  </nb-card-footer>
</nb-card>
