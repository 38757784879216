import { AbstractControl, UntypedFormBuilder, ValidatorFn } from "@angular/forms";
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { NbComponentStatus } from "@nebular/theme";

import { HealthInsuranceCostGroupsService } from "../../services/health-insurance-cost-groups/health-insurance-cost-groups.service";
import { InsuranceConditionsService } from "../../services/insurance-conditions/insurance-conditions.service";
import { HealthInsuranceCostGroupData } from "../../interfaces/health-insurance-cost-group-data";
import { IdObject } from "../../interfaces/id-object";

export interface HealthInsuranceValue {
  cost: number;
  company: string;
  costGroupId: string;
}

@Component({
  selector: "app-health-insurance-fields",
  templateUrl: "./health-insurance-fields.component.html",
  styleUrls: ["./health-insurance-fields.component.scss"],
})
export class HealthInsuranceFieldsComponent implements OnInit, OnChanges {
  @Input() isHealthInsuranceDisabled!: boolean;
  healthInsuranceCostGroups: HealthInsuranceCostGroupData[] = [];

  healthInsuranceCompanies: IdObject[] = [];

  private disabledControl = { value: null, disabled: true };
  healthInsurance = this.formBuilder.group({
    company: [this.disabledControl, this.healthInsuranceValidator()],
    costGroupId: [this.disabledControl, this.healthInsuranceValidator()],
    cost: [this.disabledControl, this.healthInsuranceValidator()],
  });

  constructor(
    private ref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private healthInsuranceCostGroupService: HealthInsuranceCostGroupsService,
    private insuranceConditionsService: InsuranceConditionsService
  ) {
    this.setHealthInsuranceOptions();
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }

  ngOnChanges(): void {
    if (this.isHealthInsuranceDisabled) {
      this.healthInsurance.disable();
    } else {
      this.healthInsurance.enable();
    }
  }

  async setHealthInsurance(data: HealthInsuranceValue | undefined): Promise<void> {
    if (!data) return;
    await this.setCostGroups(data.company);
    this.healthInsurance.patchValue(data);
  }

  setHealthInsuranceOptions(): void {
    this.healthInsuranceCompanies = this.insuranceConditionsService.getHealthInsuranceCompanies();
  }

  setHealthInsuranceCostData(data: HealthInsuranceCostGroupData): void {
    this.healthInsurance.patchValue({
      costGroupId: data.costGroupId,
      cost: data.cost,
    });
  }

  clearHealthInsuranceCostData(): void {
    this.healthInsurance.patchValue({
      costGroupId: null,
      cost: null,
    });
  }

  async setCostGroups(newCompanyId: string): Promise<void> {
    const costGroups = await this.healthInsuranceCostGroupService.getCostGroups(newCompanyId);
    this.healthInsuranceCostGroups = costGroups;
  }

  async onHealthInsuranceCompanyChanging(event: string): Promise<void> {
    await this.setCostGroups(event);

    if (this.healthInsuranceCostGroups.length === 1) {
      setTimeout(() => {
        this.healthInsurance.patchValue({
          costGroupId: this.healthInsuranceCostGroups[0].costGroupId,
          cost: this.healthInsuranceCostGroups[0].cost,
        });
        this.ref.detectChanges();
      });
    }
    this.clearHealthInsuranceCostData();
  }

  onHealthInsuranceCostGroupChanging(newGroupId: string): void {
    const group = this.healthInsuranceCostGroups.find((g) => g.costGroupId === newGroupId);
    if (group) {
      this.setHealthInsuranceCostData(group);
    }
  }

  setStatus(formControlName: string): NbComponentStatus {
    const isControlValid = this.healthInsurance.get(formControlName)?.valid;
    return isControlValid ? "info" : "danger";
  }

  /**
   * Custom validator function.
   *
   * @checks if healthInsurance is displayed, the control must be filled out.
   * @returns null, if the input field is valid.
   * @returns key value pair, where the value is true, then the input field is invalid.
   */
  private healthInsuranceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!this.isHealthInsuranceDisabled && control.value === null) {
        return { mustNotBeNull: true };
      }
      return null;
    };
  }
}
