<form [formGroup]="investmentExpenses">
  <div class="columns">
    <input
      nbTooltip={{expenseTooltip}} nbTooltipPlacement="right" nbTooltipStatus="info"
      [status]="setExpenseStatus()"
      type="number" nbInput shape="semi-round"
      step="0.01" class="margin"
      formControlName="expense">
    <div formArrayName="yields" *ngFor="let yield of yieldsForms.controls; let i = index">
      <input
        [status]="setYieldStatus(i)"
        type="number" nbInput shape="semi-round"
        class="margin" [formControlName]="i">
    </div>
  </div>
</form>
