import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { NbComponentStatus, NbToastrService } from "@nebular/theme";

import { TypeOfUserMessage } from "../company-data/table-data-editor";
import { YieldYearsService } from "../../services/yield-years/yield-years.service";

@Component({
  selector: "app-yield-years",
  templateUrl: "./yield-years.component.html",
  styleUrls: ["./yield-years.component.scss"],
})
export class YieldYearsComponent implements OnInit {
  displayErrorMessage = false;
  errorMessage = "";
  years: number[] = [];

  isLoading = true;
  isSaving = false;

  yieldYears = this.formBuilder.array([]);
  yearsGroup = this.formBuilder.group({
    yieldYears: this.yieldYears,
  });

  validators: Validators = [Validators.required, Validators.pattern("[0-9]{4}")];
  yearInputTooltip = "Tallet skal være et årstal";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastService: NbToastrService,
    private yieldYearsService: YieldYearsService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.fetchAndSetYieldYears();
    } catch (Error) {
      this.displayErrorMessage = true;
      this.setUserMessage(TypeOfUserMessage.GetDataError);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchAndSetYieldYears(): Promise<void> {
    this.years = await this.yieldYearsService.getYieldYears();
    this.setInitialYieldControls();
  }

  setInitialYieldControls(): void {
    if (this.years.length !== 0) {
      this.years.forEach(() => {
        this.yieldYears.push(this.formBuilder.control(null, this.validators));
      });
      this.setYears();
    } else {
      this.addYearField();
    }
  }

  setYears(): void {
    this.yieldYears.patchValue(this.years);
  }

  async saveYieldYears(): Promise<void> {
    this.isSaving = true;
    try {
      await this.yieldYearsService.saveYieldYears(this.yieldYears.value);
      this.setUserMessage(TypeOfUserMessage.ConfirmSave);
    } catch (Error) {
      this.setUserMessage(TypeOfUserMessage.SaveError);
    } finally {
      this.isSaving = false;
    }
  }

  addYearField(): void {
    // Insert the new form control at index 0 in the form array
    this.yieldYears.insert(0, this.formBuilder.control(null, this.validators));
  }

  deleteFirstYearField(): void {
    this.yieldYears.removeAt(0);
  }

  private setUserMessage(type: TypeOfUserMessage) {
    switch (type) {
      case TypeOfUserMessage.ConfirmSave:
        this.toastService.success("Data er gemt.");
        break;
      case TypeOfUserMessage.GetDataError:
        this.errorMessage = "Kunne ikke hente data, prøv igen eller kontakt Mancofi.";
        break;
      case TypeOfUserMessage.SaveError:
        this.toastService.danger("Forsøg på at gemme data fejlede.");
        break;
    }
  }

  get disableDeleteButton(): boolean {
    if (this.yieldYears.length === 1) {
      return true;
    }
    return false;
  }

  get disableAddButton(): boolean {
    if (this.yieldYears.length === 10) {
      return true;
    }
    return false;
  }

  getControlAtIndex(i: number): UntypedFormControl {
    return this.yieldYears.controls[i] as UntypedFormControl;
  }

  setStatus(i: number): NbComponentStatus {
    return this.getControlAtIndex(i).valid ? "info" : "danger";
  }
}
