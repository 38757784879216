import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AnalysisAnalyzerComponent } from "./pages/analysis/analysis-analyzer/analysis-analyzer.component";
import { AnalysisHonestusComponent } from "./pages/analysis/analysis-honestus/analysis-honestus.component";
import { InvestmentExpensesComponent } from "./pages/company-data/investment-expenses/investment-expenses.component";
import { CostGroupsComponent } from "./pages/company-data/cost-groups/cost-groups.component";
import { CustomerComponent } from "./pages/customer/customer.component";
import { HealthInsuranceCostsHonestusComponent } from "./pages/health-insurance/health-insurance-costs-honestus/health-insurance-costs-honestus.component";
import { HealthInsuranceCostAnalyzerComponent } from "./pages/health-insurance/health-insurance-cost-analyzer/health-insurance-cost-analyzer.component";
import { InsuranceConditionsComponent } from "./pages/insurance-conditions/insurance-conditions.component";
import { LogoutComponent } from "./pages/logout/logout.component";
import { RecommendationsComponent } from "./pages/recommendations/recommendations.component";
import { YieldYearsComponent } from "./pages/yield-years/yield-years.component";
import { AuthGuard } from "./services/auth/auth.guard";
import { RoleGuardService as RoleGuard } from "./services/role-guard/role-guard.service";
import { Company } from "./services/auth/auth.service";
import { BrowserUtils } from "@azure/msal-browser";

const routes: Routes = [
  { path: "", redirectTo: "analysis", pathMatch: "full" },
  {
    path: "analysis",
    component: AnalysisHonestusComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: Company.Honestus },
  },
  {
    path: "analysis-a",
    component: AnalysisAnalyzerComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: Company.Analyzer },
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: "pensionCompanies/:companyId/investmentExpenses",
    component: InvestmentExpensesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "pensionCompanies/:companyId/costGroups",
    component: CostGroupsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "insuranceConditions/:insurance",
    component: InsuranceConditionsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: Company.Honestus },
  },
  { path: "recommendations", component: RecommendationsComponent, canActivate: [AuthGuard] },
  { path: "yieldYears", component: YieldYearsComponent, canActivate: [AuthGuard] },
  {
    path: "healthInsuranceCosts",
    component: HealthInsuranceCostsHonestusComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: Company.Honestus },
  },
  {
    path: ":companyId/healthInsuranceCost",
    component: HealthInsuranceCostAnalyzerComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: Company.Analyzer },
  },
  { path: "customer", component: CustomerComponent, canActivate: [AuthGuard] },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? "enabledNonBlocking" : "disabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
