<nb-card status="danger" style="max-width: 60vw; min-width: 330px; max-height: 80vh;">
  <nb-card-header>Fejl</nb-card-header>

  <nb-card-body *ngIf="fileParseError?.unexpectedNumberPages" >
    <p>
      En fil fra <b>{{fileParseError?.companyName}}</b> forventes at have <b>{{fileParseError?.unexpectedNumberPages?.expected}}</b> ark.
    </p>
    <p>
      Kunne kun finde <b>{{fileParseError?.unexpectedNumberPages?.actual}}</b> ark.
    </p>
  </nb-card-body>

  <nb-card-body *ngIf="missingCoveragesExists">
    <p style="font-size: large;" *ngIf="fileParseError?.missingColumnHeadersPage1 || fileParseError?.missingColumnHeadersRow">
      En fil fra <b>{{fileParseError?.companyName}}</b> forventes yderligere overskrift for <i>{{missingCoverageNamesHeader}}</i>:
    </p>

    <p style="font-size: large;" *ngIf="fileParseError?.missingColumnHeadersPage2" >
      På side 2 forventes en fil fra <b>{{fileParseError?.companyName}}</b> yderligere overskrift for <i>{{missingCoverageNamesHeader}}</i>
    </p>

    <p style="font-size: large;" *ngIf="missingCoverages" >
      Mangler at finde overskrifter for <i>{{missingCoverageNamesHeader}}</i>.
    </p>


    <div *ngIf="coverageHeaderGroups.length < 1">
      Desværre ingen fundne forslag fra filen.
    </div>

    <div *ngIf="coverageHeaderGroups.length === 1">
      <ng-container *ngTemplateOutlet="suggestion; context:{ group: coverageHeaderGroups[0], index: 0 }"></ng-container>
    </div>

    <nb-stepper #suggestionStepper [linear]="false" *ngIf="coverageHeaderGroups.length > 1" >
      <nb-step *ngFor="let group of coverageHeaderGroups; let index = index" [label]="headerToCoverageName[group[0]]" [completed]="false" >
        <ng-container *ngTemplateOutlet="suggestion; context:{ group, index }"></ng-container>

        <div style="display: flex; justify-content: space-between; margin-top: 20px;">
          <button [disabled]="index === 0" nbButton nbStepperPrevious status="info">Tilbage</button>
          <button nbButton *ngIf="missingCoverages" (click)="setToZero(group, index)"
          [class.selected-honestus]="(isSelected[index] | json) === '{}' && associatedCompany === 'honestus'"
          [class.not-selected-honestus]="(isSelected[index] | json) !== '{}' && associatedCompany === 'honestus'"
          [class.selected-analyzer]="(isSelected[index] | json) === '{}' && associatedCompany === 'analyzer'"
          [class.not-selected-analyzer]="(isSelected[index] | json) !== '{}' && associatedCompany === 'analyzer'">
            Sæt til 0
          </button>
          <button [disabled]="index === coverageHeaderGroups.length - 1" nbButton nbStepperNext status="info">Næste</button>
        </div>
      </nb-step>
    </nb-stepper>

    <ng-template #suggestion let-group="group" let-index="index">
      <div style="display: flex; margin-bottom: 1%;">
        <p class="suggestion-header">
          Forventet en af
        </p>

        <div class="header-list">
          <nb-tag-list style="margin-bottom: 2%;">
            <nb-tag appearance="filled" *ngFor="let header of group" [text]="header" status="info"></nb-tag>
          </nb-tag-list>
        </div>
      </div>

      <p class="suggestion-header">Fandt nedenstående overskrifter og eksempler på værdier i filen.</p>

      <p-table [value]="tableData[group[0]]" [columns]="columnHeaders[group[0]]" styleClass="p-datatable-gridlines p-datatable-resizable-fit"
       [scrollable]="true" scrollHeight="flex" [tableStyle]="{'min-width': '300px'}">
        <ng-template pTemplate="header" let-headers>
          <tr>
            <th *ngFor="let header of headers" (click)="addToAliases(header, index, group)"
              style="cursor: pointer"
              class="center-table-text"
              [class.selected-honestus]="isSelected[index][header] && associatedCompany === 'honestus'"
              [class.not-selected-honestus]="!isSelected[index][header] && associatedCompany === 'honestus'"
              [class.selected-analyzer]="isSelected[index][header] && associatedCompany === 'analyzer'"
              [class.not-selected-analyzer]="!isSelected[index][header] && associatedCompany === 'analyzer'" >
              {{ header }}
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-columns>
          <tr>
            <td *ngFor="let row of columns" class="center-table-text example-value-cells">
              <div>{{ row }}</div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="1000" class="center-table-text example-value-cells">
                <div>Ingen fundne eksempel værdier fra filen.</div>
              </td>
            </tr>
          </ng-template>
      </p-table>
    </ng-template>
  </nb-card-body>

  <nb-card-body *ngIf="fileParseError?.errorMessage" >
    <div>{{ fileParseError?.errorMessage }}</div>
  </nb-card-body>

  <nb-card-body *ngIf="errorMessage" >
    <div>{{ errorMessage }}</div>
  </nb-card-body>

  <nb-card-footer>
    <button (click)="close()" nbButton status="primary" shape="semi-round" style="float: right;">
      {{ doDisplayReloadFileButton ? 'Indlæs fil igen' : 'OK' }}
    </button>
  </nb-card-footer>
</nb-card>
