import { FetchResult, gql } from "@apollo/client/core";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";

import { ApolloInstanceService } from "../apollo/apollo-instance.service";
import { CostGroupData } from "../../interfaces/cost-group-data";
import { InsuranceKind } from "../insurance-conditions/insurance-conditions.service";
import { HealthInsuranceCostGroupsService } from "../health-insurance-cost-groups/health-insurance-cost-groups.service";
import { IdObject } from "../../interfaces/id-object";

@Injectable({
  providedIn: "root",
})
export class CostGroupsService {
  constructor(
    private apollo: ApolloInstanceService,
    private healthInsuranceCostGroupsService: HealthInsuranceCostGroupsService,
    private logger: NGXLogger
  ) {}

  async getCostGroups(pensionCompanyId: string): Promise<CostGroupData[]> {
    const apollo = await this.apollo.getInstance();
    const query = gql`
      query {
        costGroups (
          pensionCompanyId: "${pensionCompanyId}"
          ) {
            costGroupId
            costGroupName
            priority
            lossOfEarningCapacity
            resourceCourseHandoutDkk
            resourceCourseHandoutPercent
            disabilityLumpSum
            waiverOfPremium
            criticalIllness
            death
            orphanPension
            brokerFee
            ongoingCompensation
            brokerFeeOfAuM
            establishmentFee
            establishmentFeeDiscount
            administrationFee
            administrationFeeLimitOfPremium2
            administrationFeeLimitOfPremium3
            administrationFeeOfPremium1
            administrationFeeOfPremium2
            administrationFeeOfPremium3
            administrationFeeLimitOfAuM2
            administrationFeeLimitOfAuM3
            administrationFeeOfAuM1
            administrationFeeOfAuM2
            administrationFeeOfAuM3
          }
      }`;

    const result = await apollo.query({ query, fetchPolicy: "network-only" });
    const unSortedCostGroups = result.data.costGroups;

    return this.sortByPriority(unSortedCostGroups);
  }

  async saveCostGroups(
    pensionCompanyId: string,
    mutationData: CostGroupData[]
  ): Promise<FetchResult<any>> {
    const apollo = await this.apollo.getInstance();
    const dataString = this.mutationDataToString(mutationData);
    const mutation = gql`
      mutation {
        createUpdateAndDeleteCostGroups(
          pensionCompanyId: "${pensionCompanyId}",
          costGroups: [${dataString}]
        )
      }`;

    return await apollo.mutate({ mutation });
  }

  async fetchCostGroupsByCompany(
    insuranceKind: InsuranceKind,
    companies: IdObject[]
  ): Promise<{ [key: string]: IdObject[] }> {
    let getCostGroups: (id: string) => Promise<IdObject[]>;
    switch (insuranceKind) {
      case "criticalIllness":
      case "lossOfEarningCapacity":
        getCostGroups = (id: string) =>
          this.getCostGroups(id).then((groups) =>
            groups
              .filter((group) => group.costGroupId)
              .map((group) => ({ id: group.costGroupId as string, label: group.costGroupName }))
          );
        break;
      case "healthInsurance":
        getCostGroups = (id: string) =>
          this.healthInsuranceCostGroupsService
            .getCostGroups(id)
            .then((groups) =>
              groups.map((group) => ({ id: group.costGroupId as string, label: group.name }))
            );
        break;
      default:
        this.logger.error("Unknown insurance kind '{insuranceKind}'", insuranceKind);
        return {};
    }
    const result: { [key: string]: IdObject[] } = {};
    for (const company of companies) {
      const id = company.id.toLowerCase();
      result[id] = await getCostGroups(id);
    }
    return result;
  }

  get exstraStepsInStaircase(): number {
    return 2;
  }

  private mutationDataToString(dataObjects: CostGroupData[]): string {
    let dataString = "";
    dataObjects.map((data) => {
      // We need to build the costGroupId string here since there needs to be double quotes in the string if the id is not null.
      let costGroupId: string;
      data.costGroupId ? (costGroupId = `"${data.costGroupId}"`) : (costGroupId = "null");
      let costGroupName: string;
      data.costGroupName ? (costGroupName = `"${data.costGroupName}"`) : (costGroupName = "null");

      dataString += `{
        costGroupId: ${costGroupId},
        priority: ${data.priority}
        costGroupName: ${costGroupName}
        lossOfEarningCapacity: ${data.lossOfEarningCapacity}
        resourceCourseHandoutDkk: ${data.resourceCourseHandoutDkk}
        resourceCourseHandoutPercent: ${data.resourceCourseHandoutPercent}
        disabilityLumpSum: ${data.disabilityLumpSum}
        waiverOfPremium: ${data.waiverOfPremium}
        criticalIllness: ${data.criticalIllness}
        death: ${data.death}
        orphanPension: ${data.orphanPension}
        brokerFee: ${data.brokerFee}
        ongoingCompensation: ${data.ongoingCompensation}
        brokerFeeOfAuM: ${data.brokerFeeOfAuM}
        establishmentFee: ${data.establishmentFee}
        establishmentFeeDiscount: ${data.establishmentFeeDiscount}
        administrationFee: ${data.administrationFee}
        administrationFeeLimitOfPremium2: ${data.administrationFeeLimitOfPremium2}
        administrationFeeLimitOfPremium3: ${data.administrationFeeLimitOfPremium3}
        administrationFeeOfPremium1: ${data.administrationFeeOfPremium1}
        administrationFeeOfPremium2: ${data.administrationFeeOfPremium2}
        administrationFeeOfPremium3: ${data.administrationFeeOfPremium3}
        administrationFeeLimitOfAuM2: ${data.administrationFeeLimitOfAuM2}
        administrationFeeLimitOfAuM3: ${data.administrationFeeLimitOfAuM3}
        administrationFeeOfAuM1: ${data.administrationFeeOfAuM1}
        administrationFeeOfAuM2: ${data.administrationFeeOfAuM2}
        administrationFeeOfAuM3: ${data.administrationFeeOfAuM3}
      }`;
    });

    this.logger.debug("mutationDataToString returned {dataString}", dataString);

    return dataString;
  }

  private sortByPriority(unSortedArray: CostGroupData[]): CostGroupData[] {
    return [...unSortedArray].sort(
      (obj1: CostGroupData, obj2: CostGroupData) => obj1.priority - obj2.priority
    );
  }
}
