<nb-stepper #stepper [linear]="true">
  <nb-step [stepControl]="getStep(Step.One)" label="Nuværende">
    <form class="step-container">
      <app-step-1-analyzer [isHealthInsurancePresent]="isHealthInsurancePresent"
                  (yearCountChanged)="yearCountChanged()"></app-step-1-analyzer>
      <button [disabled]="!getStep(Step.One).valid" nbButton nbStepperNext status="primary" size="giant" class="next-btn" >Næste 🡆</button>
    </form>
  </nb-step>

  <nb-step [stepControl]="getStep(Step.Two)" label="Sammenligning">
    <form class="step-container">
      <app-step-2-analyzer
        [step1Value]="getStep(Step.One).value.currentCosts?.investmentCosts"
        [isHealthInsurancePresent]="isHealthInsurancePresent"
        [years]="years"
        (yearCountChanged)="yearCountChanged()"></app-step-2-analyzer>
      <div class="previous-next-div">
        <button nbButton nbStepperPrevious status="primary" size="giant">🡄 Tilbage</button>
        <button [disabled]="!getStep(Step.Two).valid" nbButton nbStepperNext status="primary" size="giant">Næste 🡆</button>
      </div>
    </form>
  </nb-step>

  <nb-step [stepControl]="getStep(Step.Three)" label="Rapport">
    <form class="step-container">
      <app-step-3-analyzer
        [pensionCompanies]="companiesToCompareValues"
        [coverages]="getStep(Step.One).get('information')?.value"
        [yearCount]="yearCount"
        [saveAnalysisName]="employerName"></app-step-3-analyzer>
      <div class="previous-next-div">
        <button nbButton nbStepperPrevious status="primary" size="giant">🡄 Tilbage</button>
        <button
          nbButton [disabled]="!analysisForm.valid || isGeneratingReport"
          status="primary" size="giant"
          (click)="downloadReports()"
          [nbSpinner]="isGeneratingReport" nbSpinnerSize="giant"
          nbSpinnerStatus="control">Dan Rapport ⭳</button>
      </div>
    </form>
  </nb-step>
</nb-stepper>
