<form [formGroup]="healthInsurance" class="columns">
  <nb-select
    (selectedChange)="onHealthInsuranceCompanyChanging($event)"
    placeholder="Vælg selskab" formControlName="company"
    #healthInsuranceSelector shape="semi-round"
    style="width: 215px; margin-bottom: 10px;"
    [status]="setStatus('company')">
    <nb-option *ngFor="let company of healthInsuranceCompanies" [value]="company.id">{{company.label}}</nb-option>
  </nb-select>
  <nb-select
    (selectedChange)="onHealthInsuranceCostGroupChanging($event)"
    placeholder="Vælg prisgruppe" formControlName="costGroupId"
    shape="semi-round" style="width: 215px; margin-bottom: 10px;"
    [status]="setStatus('costGroupId')">
    <nb-option *ngFor="let group of healthInsuranceCostGroups" [value]="group.costGroupId">{{group.name}}</nb-option>
  </nb-select>
  <input
    placeholder="Kr."
    type="number" nbInput shape="semi-round"
    class="box" formControlName="cost"
    [status]="setStatus('cost')"/>
</form>
