import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DateTimeFormatter, LocalDate } from "@js-joda/core";
import { SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { NbDialogService } from "@nebular/theme";

import { AppConfigService } from "./../../app-config.service";
import { AuthService } from "./../../services/auth/auth.service";
import { CustomerService } from "./../../services/customer/customer.service";
import { Analysis, AnalysisTableEntry } from "./../../services/customer/analysis";
import { ConfirmPopupComponent } from "../../templates/confirm-popup/confirm-popup.component";
import { WinningOfferComponent } from "./winning-offer/winning-offer.component";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnInit {
  analysisEntries: AnalysisTableEntry[] | undefined;
  paginatorOptions = [10, 25, 50];
  sortableColumn: { displayHeader: string; columnName: string }[] = [
    { displayHeader: "Navn", columnName: "name" },
    { displayHeader: "Dato gemt", columnName: "saveDate" },
    { displayHeader: "Bestandsliste filnavn", columnName: "employeeFileName" },
    { displayHeader: "Antal medarbejdere", columnName: "companyEmployeeCount" },
    { displayHeader: "Effektueret", columnName: "hasWinner" },
    { displayHeader: "Vindende pensionsselskab", columnName: "winningPensionCompany" },
  ];
  globalFilterFields = this.sortableColumn.map((c) => c.columnName);

  selectedEntry: AnalysisTableEntry | undefined;

  private danishDateFormat = "dd/MM-yyyy";
  private danishDateFormatter = DateTimeFormatter.ofPattern(this.danishDateFormat);
  private landingPage: string;

  constructor(
    private authService: AuthService,
    private appConfigService: AppConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private customerService: CustomerService,
    private dialogService: NbDialogService,
    private router: Router
  ) {
    this.landingPage = this.authService.getAssociatedCompany().landingPage;
  }

  async ngOnInit(): Promise<void> {
    const analyses = await this.customerService.getAnalyses();
    this.analysisEntries = analyses.map((a) => this.setAnalysisTableEntry(a));
    this.changeDetectorRef.detectChanges();
  }

  clear(table: Table, searchBox: HTMLInputElement): void {
    table.clear();
    searchBox.value = "";
  }

  onRowClick(analysisTableEntry: AnalysisTableEntry): void {
    this.selectedEntry = analysisTableEntry;
  }

  openWinnerWindow(analysisTableEntry: AnalysisTableEntry): void {
    this.dialogService
      .open(WinningOfferComponent, {
        context: {
          analysisTableEntry: analysisTableEntry,
        },
      })
      .onClose.subscribe((entry: AnalysisTableEntry) => {
        if (!this.analysisEntries || !entry) return;
        const i = this.analysisEntries.findIndex((a) => a.id === entry.id);
        if (i > -1) this.analysisEntries[i] = this.setAnalysisTableEntry(entry);
        this.changeDetectorRef.detectChanges();
      });
  }

  async delete(entry: AnalysisTableEntry): Promise<void> {
    this.dialogService.open(ConfirmPopupComponent).onClose.subscribe((doDelete) => {
      if (!doDelete) return;
      this.customerService.deleteAnalysis(entry.id);
      const i = this.analysisEntries?.findIndex((a) => a.id === entry.id);
      const as = this.analysisEntries?.filter((_, index) => i !== index);
      this.analysisEntries = as;
      this.changeDetectorRef.detectChanges();
    });
  }

  restoreAnalysis(args: string | undefined): void {
    if (!args) return;
    const a = JSON.parse(args);
    this.router.navigateByUrl(this.landingPage, { state: a });
  }

  search(table: Table, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) table.filterGlobal(inputElement.value, "contains");
  }

  customSort(event: SortEvent): void {
    if (!event) throw new Error("No event!");

    event.data?.sort((data1, data2) => {
      if (event.field) {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (!value1 && value2) result = -1;
        else if (value1 && !value2) result = 1;
        else if (!value1 && !value2) result = 0;
        else if (typeof value1 === "string" && typeof value2 === "string")
          result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

        if (event.order) {
          return event.order * result;
        }
      }
      throw new Error(`Sorting error, event.field is ${event.field}`);
    });
  }

  private parseLocalDate(date: string): LocalDate {
    try {
      return LocalDate.parse(date, this.danishDateFormatter);
    } catch (e) {
      return LocalDate.parse(date);
    }
  }

  private setAnalysisTableEntry(analysis: Analysis | AnalysisTableEntry): AnalysisTableEntry {
    const pensionCompanies = this.appConfigService
      .getConfig()
      .common.pensionCompanies.map((p) => ({ id: p.id.toLowerCase(), label: p.label }));
    const findPensionCompanyLabel = (a: Analysis | AnalysisTableEntry) =>
      pensionCompanies.find((p) => p.id === a.winningOffer?.pensionCompanyId)?.label ?? "";
    return {
      name: analysis.name,
      employeeFileName: analysis.employeeFileName ?? "Ingen bestandsliste",
      companyEmployeeCount: analysis.companyEmployeeCount,
      arguments: analysis.arguments,
      id: analysis.id,
      saveDate: this.parseLocalDate(analysis.saveDate).format(this.danishDateFormatter),
      winningOffer: analysis.winningOffer,
      hasWinner: analysis.winningOffer ? "Ja" : "Nej",
      winningPensionCompany: analysis.winningOffer?.pensionCompanyId
        ? findPensionCompanyLabel(analysis)
        : "",
    };
  }
}
