import { Component, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "app-confirm-popup",
  templateUrl: "./confirm-popup.component.html",
  styleUrls: ["./confirm-popup.component.scss"],
})
export class ConfirmPopupComponent implements OnInit {
  constructor(private dialogRef: NbDialogRef<ConfirmPopupComponent>) {}

  ngOnInit(): void {
    // Nothing to do on init yet...
  }

  close(doDelete: boolean): void {
    this.dialogRef.close(doDelete);
  }
}
