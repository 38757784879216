import { Component, OnInit } from "@angular/core";

import { AppConfigService } from "../../../app-config.service";
import { IdObject } from "../../../interfaces/id-object";

@Component({
  selector: "app-health-insurance-costs-honestus",
  templateUrl: "./health-insurance-costs-honestus.component.html",
  styleUrls: ["./health-insurance-costs-honestus.component.scss"],
})
export class HealthInsuranceCostsHonestusComponent implements OnInit {
  companies: IdObject[];

  constructor(private appConfig: AppConfigService) {
    this.companies = this.appConfig.getConfig().common.healthInsuranceCompanies;
  }

  ngOnInit(): void {
    // Nothing to do on init yet
  }
}
