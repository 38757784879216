<div *ngIf="isLoading" class="full-screen center-and-middle">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!isLoading" class="grid-wrapper" style="overflow: auto;">
  <form [formGroup]="page" (ngSubmit)="onSubmit()">
  <div style="margin-left: 70px;">
    <button type="button" [disabled]="companiesForms.length < 2" nbButton size="medium" shape="round" status="info" style="margin-right: 20px; margin-bottom: 20px;" (click)="deleteLastCompany()">
      <nb-icon icon="minus-outline"></nb-icon>
    </button>
    <button type="button" nbButton size="medium" shape="round" status="info" style="margin-bottom: 20px;" (click)="addCompany(undefined)">
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
  </div>

  <div class="flex-row">
    <div class="fit-content" style="display: flex; flex-direction: column; margin-right: 20px;">
      <button type="button" [disabled]="conditionsForms.length < 2" nbButton size="medium" shape="round" status="info" style="margin-bottom: 20px;" (click)="deleteLastCondition()">
        <nb-icon icon="minus-outline"></nb-icon>
      </button>
      <button type="button" nbButton size="medium" shape="round" status="info" (click)="addCondition()">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
    </div>

    <nb-card class="fit-content" style="min-width: 550px;">
      <nb-card-header>
        <h3>{{ header }}</h3>
      </nb-card-header>

      <div class="flex-row">
        <nb-card-body class="fit-content" style="margin-top: 94.23px; padding-right: 10px;">
          <form formGroupName="columns">
            <div formArrayName="conditions">
              <div class="row-space-between" *ngFor="let condition of conditionsForms.controls; let i = index">
                <input
                  [status]="setStatus(Row.Condition, i)"
                  type="text" placeholder="Betingelse"
                  nbInput shape="semi-round"
                  [formControlName]="i" >
              </div>
            </div>
          </form>
        </nb-card-body>

        <nb-card-body style="padding-left: 10px;">
          <form formGroupName="columns">
            <div formArrayName="companies" class="flex-row">
              <div *ngFor="let companyControl of companiesForms.controls; let i = index" [formGroupName]="i" style="min-width: 295px; max-width: 295px;" class="column-space-between">
                  <div class="row-space-between">
                    <nb-select formControlName="companyId" (selectedChange)="onCompanyIdChange($event, i)" shape="semi-round" class="fill-box" style="margin-bottom: 1px;" placeholder="Selskab" [status]="setStatusCompanyId(i)">
                      <nb-option *ngFor="let company of companies" value="{{company.id}}">{{company.label}}</nb-option>
                    </nb-select>
                  </div>
                <div class="row-space-between">
                  <nb-select formControlName="costGroupId" shape="semi-round" class="fill-box" style="margin-bottom: 1px;" placeholder="Prisgruppe">
                    <nb-option *ngFor="let costGroup of costGroups[i]" value="{{costGroup.id}}">{{costGroup.label}}</nb-option>
                  </nb-select>
                </div>

                  <div *ngFor="let coverage of coveragesForms[i].controls; let j = index" class="flex-row coverage-rating-grid">
                    <div formArrayName="coverages" class="row-space-between" style="margin-right: 5%;">
                      <input
                        [status]="setStatus(Row.Coverage, j, i)"
                        type="text" placeholder="Dækning"
                        nbInput shape="semi-round" class="fill-box"
                        [formControlName]="j">
                    </div>
                    <div formArrayName="ratings" class="row-space-between">
                      <input
                        nbTooltip={{ratingTooltip}} nbTooltipPlacement="right" nbTooltipStatus="info"
                        [status]="setStatus(Row.Rating, j, i)"
                        type="number" placeholder="Rating"
                        nbInput shape="semi-round" class="fill-box"
                        [formControlName]="j">
                    </div>
                  </div>
              </div>
            </div>
          </form>
        </nb-card-body>
      </div>
    </nb-card>
  </div>

  <button
    [disabled]="!columns.valid" type="submit" nbButton
    status="primary" size="giant"
    [nbSpinner]="isSaving" nbSpinnerSize="giant" nbSpinnerStatus="control"
    [disabled]="isSaving">Gem</button>
  </form>
</div>
