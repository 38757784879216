import { Component, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

import { AuthService } from "./../../services/auth/auth.service";
import { Alias } from "../../services/company-file-parser/alias";
import { CoverageAlias } from "./../../services/company-file-parser/coverage-alias";
import { FileParseError } from "./../../services/company-file-parser/file-parse-error/file-parse-error";
import { MissingColumnHeadersPage1 } from "./../../services/company-file-parser/file-parse-error/missing-column-headers-page-1";
import { MissingColumnHeadersPage2 } from "./../../services/company-file-parser/file-parse-error/missing-column-headers-page-2";
import { MissingCoverages } from "./../../services/company-file-parser/missing-coverages";
import { SuggestionColumn } from "./../../services/company-file-parser/suggestion-column";

@Component({
  selector: "app-error-pop-up",
  templateUrl: "./error-pop-up.component.html",
  styleUrls: ["./error-pop-up.component.scss"],
})
export class ErrorPopUpComponent implements OnInit {
  fileParseError: FileParseError | undefined;
  errorMessage?: string;
  coverageHeaderGroups: string[][] = [];
  missingCoverages: MissingCoverages | undefined;
  suggestionsMap: { [key: string]: SuggestionColumn[] } = {};
  headerToCoverageName: { [key: string]: string } = {};
  missingCoverageNamesHeader: string | undefined;

  aliases?: Alias[];
  private aliasMap!: { [key: string]: CoverageAlias };
  isSelected: { [i: number]: { [key: string]: boolean } } = {};
  associatedCompany: string;
  doDisplayReloadFileButton: boolean | undefined;
  missingCoveragesExists: boolean | undefined;

  constructor(
    private authService: AuthService,
    private dialogRef: NbDialogRef<ErrorPopUpComponent>
  ) {
    this.associatedCompany = this.authService.getAssociatedCompany().name;
  }
  columnHeaders: { [key: string]: string[] } = {};
  tableData: { [key: string]: string[][] } = {};

  ngOnInit(): void {
    this.fileParseError?.suggestions.forEach((s) => {
      this.suggestionsMap[s.expectedHeaderName] = s.suggestions;
    });

    this.aliasMap = this.createAliasMap(this.aliases);

    if (this.fileParseError?.missingColumnHeadersPage1) {
      this.addKeysToMap(this.fileParseError?.missingColumnHeadersPage1.ageNames, "Alder");
      this.addKeysToMap(this.fileParseError?.missingColumnHeadersPage1.aumNames, "Opsparing");
      this.addKeysToMap(this.fileParseError?.missingColumnHeadersPage1.premiumNames, "Præmie");
      this.addKeysToMap(this.fileParseError?.missingColumnHeadersPage1.salaryNames, "Løn");
      this.addKeysToMap(
        this.fileParseError?.missingColumnHeadersPage1.investmentProfileNames,
        "Investeringsprofil"
      );
      this.setCoverageHeaderGroups(this.fileParseError.missingColumnHeadersPage1);
    }

    if (this.fileParseError?.missingColumnHeadersPage2) {
      this.addKeysToMap(this.fileParseError?.missingColumnHeadersPage2.amountNames, "Depot");
      this.addKeysToMap(this.fileParseError?.missingColumnHeadersPage2.categoryNames, "Kategori");
      this.addKeysToMap(
        this.fileParseError?.missingColumnHeadersPage2.uidOfEmployeeNames,
        "Person ID"
      );
      this.setCoverageHeaderGroups(this.fileParseError.missingColumnHeadersPage2);
    }

    if (this.fileParseError?.missingColumnHeadersRow) {
      this.addKeysToMap(this.fileParseError?.missingColumnHeadersRow.amountNames, "Depot");
      this.addKeysToMap(this.fileParseError?.missingColumnHeadersRow.categoryNames, "Kategori");
      this.addKeysToMap(
        this.fileParseError?.missingColumnHeadersRow.investmentProfileNames,
        "Investeringsprofil"
      );
      this.addKeysToMap(
        this.fileParseError?.missingColumnHeadersRow.uidOfEmployeeNames,
        "Person ID"
      );
      this.setCoverageHeaderGroups(this.fileParseError.missingColumnHeadersRow);
    }

    if (this.missingCoverages) {
      this.setCoverageHeaderGroups(this.missingCoverages);
      this.addKeysToMap(this.missingCoverages.criticalSicknessNames, "Kritisk Sygdom");
      this.addKeysToMap(this.missingCoverages.deathPctOfSalaryNames, "Død");
      this.addKeysToMap(this.missingCoverages.lossOfEarningCapacityNames, "TAE");
    }

    this.missingCoverageNamesHeader = this.computeMissingCoveragesHeader();

    this.coverageHeaderGroups.forEach((c, i) => {
      const columns = this.suggestionsMap[c[0]];
      if (columns === undefined) return;
      this.isSelected[i] = {};
      const valueMatrix = columns.map((c) => c.exampleValues);
      this.columnHeaders[c[0]] = columns.map((s) => s.suggestedHeaderName);
      this.tableData[c[0]] = this.transpose<string>(valueMatrix);
    });

    const error = this.fileParseError;
    this.missingCoveragesExists =
      error?.missingColumnHeadersPage1 ||
      error?.missingColumnHeadersPage2 ||
      error?.missingColumnHeadersRow ||
      this.missingCoverages
        ? true
        : false;

    this.doDisplayReloadFileButton =
      this.missingCoveragesExists && this.coverageHeaderGroups.length > 0;
  }

  close(): void {
    this.dialogRef.close(this.aliasMapToLst(this.aliasMap));
  }

  addToAliases(suggestion: string, i: number, coverageHeaders: string[]): void {
    coverageHeaders.forEach(
      (c: string) => (this.aliasMap[c] = { suggestion: suggestion, setZero: false })
    );
    this.isSelected[i] = {};
    this.isSelected[i][suggestion] = true;
  }

  setToZero(coverageHeaders: string[], i: number): void {
    coverageHeaders.forEach(
      (c: string) => (this.aliasMap[c] = { suggestion: null, setZero: true })
    );
    this.isSelected[i] = {};
  }

  private setCoverageHeaderGroups(
    headerObj: MissingColumnHeadersPage1 | MissingColumnHeadersPage2 | MissingCoverages
  ): void {
    for (const [prop, header] of Object.entries(headerObj)) {
      if (prop === "__typename") continue;

      if (Array.isArray(header)) {
        const expectedHeaders = header as string[];
        if (expectedHeaders.length > 0) this.coverageHeaderGroups.push(expectedHeaders);
      } else if (header !== null) {
        this.coverageHeaderGroups.push([header]);
      }
    }
  }

  private createAliasMap(aliases?: Alias[]): { [key: string]: CoverageAlias } {
    const aliasMap: { [key: string]: CoverageAlias } = {};
    aliases?.forEach((a) => (aliasMap[a.key] = a.coverageAlias));
    return aliasMap;
  }

  private aliasMapToLst(aliasMap: { [key: string]: CoverageAlias }): Alias[] {
    return Object.entries(aliasMap).map((kv) => ({
      key: kv[0],
      coverageAlias: {
        suggestion: kv[1]?.suggestion,
        setZero: kv[1].setZero,
      },
    }));
  }

  private addKeysToMap(keys: string[], value: string): void {
    keys.forEach((k) => (this.headerToCoverageName[k] = value));
  }

  private computeMissingCoveragesHeader(): string {
    const noDuplicateCoverages = new Set(Object.values(this.headerToCoverageName));
    return [...noDuplicateCoverages].join(", ");
  }

  private transpose<TMatrix>(matrix: TMatrix[][]): TMatrix[][] {
    return matrix[0].map((_, i) => matrix.map((row) => row[i]));
  }
}
