import { Component } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";

import { AppConfigService } from "../../../../app-config.service";
import { CostGroupsService } from "../../../../services/cost-groups/cost-groups.service";
import { InvestmentExpensesService } from "../../../../services/investment-expenses/investment-expenses.service";
import { Step2Component } from "../../step2.component";

@Component({
  selector: "app-step-2-analyzer",
  templateUrl: "./step-2-analyzer.component.html",
  styleUrls: ["./step-2-analyzer.component.scss"],
})
export class Step2AnalyzerComponent extends Step2Component {
  constructor(
    appConfigService: AppConfigService,
    costGroupsService: CostGroupsService,
    formBuilder: UntypedFormBuilder,
    investmentExpensesService: InvestmentExpensesService
  ) {
    super(appConfigService, costGroupsService, formBuilder, investmentExpensesService);
  }
}
